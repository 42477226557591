//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Components
import DifferenceChecker from 'pages/Report/components/DifferenceChecker';

//Design System
import {
  Row,
  Column,
  FlexBox,
  Image,
  H6,
  H7,
  T1,
  B2,
} from 'styles/DesignSystem';

//Assets
import profileDefault from 'assets/icons/application-header-profile.svg';
import statusBest from 'assets/images/report-status/best.png';
import statusGood from 'assets/images/report-status/good.png';
import statusSoso from 'assets/images/report-status/soso.png';
import statusBad from 'assets/images/report-status/bad.png';
import statusWorst from 'assets/images/report-status/worst.png';

const MyRankingScoreConvertor = function(todayData){
  let myRanking;

  if(!todayData?.comparison_ranking?.length){ return null; }

  for (let element of todayData.comparison_ranking) {
    if (todayData.ig_user_id === element.ig_user_id) {
      myRanking = element;
    }
  }

  if (myRanking?.audience_country) {
    let countryRawData = Object.entries(myRanking.audience_country);
    let countryTotal = 0;
    for (let ii = 0; ii < countryRawData.length; ii++) {
      countryTotal += countryRawData[ii][1];
    }
    return Math.round((myRanking.reach_week + myRanking.reach) * (1 + (myRanking.audience_country?.KR ?? 0) / countryTotal / 2));
  } else {
    return null;
  }
};

const TodayReportStatus = function(todayData, yesterdayData){
  const todayScore = MyRankingScoreConvertor(todayData);
  const yesterdayScore = MyRankingScoreConvertor(yesterdayData);

  if(todayScore>0 && yesterdayScore>0){

    const scoreRatio = (todayScore - yesterdayScore) / yesterdayScore;

    if(scoreRatio>0.1){
      return 'best';
    } else if(scoreRatio>0.05){
      return 'good';
    } else if(scoreRatio> -0.05){
      return 'soso';
    } else if(scoreRatio> -0.1){
      return 'bad';
    } else if(scoreRatio<= -0.1){
      return 'worst';
    }

  } else {
    return 'new';
  }
};

const statusMessageImage = {
  "best": { 
    "message": '오늘 리포트는 꽃이 폈어요', 
    "image": statusBest
  },
  "good": { 
    "message": '오늘 리포트에 새 잎이 났어요', 
    "image": statusGood
  },
  "soso": { 
    "message": '오늘 리포트에는 관심이 필요해요', 
    "image": statusSoso
  },
  "bad": { 
    "message": '오늘 리포트에는 햇빛이 필요해요', 
    "image": statusBad
  },
  "worst": { 
    "message": '내일은 더 성장할 수 있을거에요', 
    "image": statusWorst
  },
  "new": { 
    "message": '리포트에서 싹이 나려고 해요', 
    "image": statusWorst
  },
}

const handleImgError = (error) => {
  error.target.src = profileDefault;
}

const handleProfileOnclick = function (user_name) {
  window.open(`https://www.instagram.com/${user_name}`, '_blank');
}

function InstagramProfile({ userData, todayData, yesterdayData, year, month, day }) {

  return (
    <ProfileDiv>

      <ProfileColumn>
        <Row>
          <ProfileImage src={userData.profile_picture} onError={handleImgError} onClick={()=>{handleProfileOnclick(todayData.user_name);}} />
          <UserName onClick={()=>{handleProfileOnclick(todayData.user_name);}}>@{todayData.user_name}</UserName>
          <FlexBox />
          <ProfileScore>
            {
              MyRankingScoreConvertor(todayData)
                ? (<>
                  <CountUp
                    duration={0.7}
                    end={MyRankingScoreConvertor(todayData)}
                    separator=','
                  />점
                </>)
                : (<>
                  데이터 부족
                </>)
            }
          </ProfileScore>
        </Row>
        <FlexSpace />
        <AnalysisPoint>분석 시점 | {year}년 {month}월 {day}일 16:00 까지</AnalysisPoint>
      </ProfileColumn>

      <HorizontalLine />
      <FlexBox />

      <ProfileRow>
        <StatusColumn>
          <Row>
            <ProfileRanking>
              <CountUp end={todayData.today_ranking} separator=',' duration={0.7} />위
            </ProfileRanking>
            <DifferenceChecker
              data={yesterdayData ? (yesterdayData.today_ranking - todayData.today_ranking) : null}
              left={8}
            />
          </Row>
          <H7 bold top={8}>{statusMessageImage[TodayReportStatus(todayData, yesterdayData)].message}</H7>
        </StatusColumn>
        <FlexBox />
        <StatusImage src={statusMessageImage[TodayReportStatus(todayData, yesterdayData)].image} />
      </ProfileRow>

    </ProfileDiv>
  );

}

export default InstagramProfile;

const ProfileDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 117px;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 24px 50px 30px 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 590px){
    min-width: 320px;
    height: 180px;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px 0px;
  }
`

const ProfileColumn = styled(Column)`
  flex: 4;

  @media (max-width: 590px){
    flex: none;
    padding: 0 15px;
  }
`

const ProfileRow = styled(Row)`
  flex: 4;
  position: relative;

  @media (max-width: 590px){
    position: static;
    flex: none;
    height: 78px;
    padding: 0 15px 6px 15px;
    align-items: flex-end;
  }
`

const StatusColumn = styled(Column)`
  @media (max-width: 590px){
    margin-bottom: 8px;
  }
`

const FlexSpace = styled.div`
  flex: 1;

  @media (max-width: 590px){
    display: none;
  }
`

const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transform: translate(50, 50);
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;

  @media (max-width: 590px){
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`

const UserName = styled(H7)`
  font-weight: 700;
  cursor: pointer;

  @media (max-width: 590px){ //T2
    font-size: 16px;
    line-height: 20px;
  }
`

const ProfileScore = styled(H6)`
  font-weight: 700;
  color: #4480F7;

  @media (max-width: 590px){ //T2
    font-size: 16px;
    line-height: 20px;
  }
`

const AnalysisPoint = styled(B2)`
  color: #AAAAAA;
  margin-left: 51px;

  @media (max-width: 590px){
    display: none;
  }
`

const HorizontalLine = styled.div`
  display: none;

  @media (max-width: 590px){
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background: #F6F6F6;
  }
`

const ProfileRanking = styled(T1)`
  font-weight: 700;

  @media (max-width: 590px){ //T1
    font-size: 18px;
    line-height: 24px;
  }
`

const StatusImage = styled(Image)`
  width: 45px;
  border: 0;
  position: absolute;
  right: 0;
  bottom: -4px;

  @media (max-width: 590px){
    position: static;
    width: 47px;
  }
`