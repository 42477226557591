//React
import { useState, useEffect } from "react";
import styled from "styled-components";

//GA
import ReactGA from "react-ga4";

//Assets
import headerLogo from "assets/icons/creatorly-logo2.png";
import headerHelp from "assets/icons/application-header-help.svg";
import menuAnalysis from "assets/icons/application-menu-analysis.svg";
import menuCommunity from "assets/icons/application-menu-community.svg";
import menuCampaign from "assets/icons/application-menu-campaign.svg";
import menuInquiry from "assets/icons/application-menu-inquiry.svg";
import menuRegister from "assets/icons/report-rocket.svg";

//Components
import { FlexBox, T2 } from "styles/DesignSystem";

function KakaoBrowserChecker() {
  const agent = navigator.userAgent.toUpperCase();
  return agent.indexOf("KAKAOTALK") >= 0;
}

function ApplicationLayout({ children, state }) {
  const [whetherKakao, setWhetherKakao] = useState();

  useEffect(() => {
    setWhetherKakao(KakaoBrowserChecker());
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <LogoIcon src={headerLogo} />
        <FlexBox />
        <HelpIcon
          src={headerHelp}
          onClick={function () {
            ReactGA.event({
              category: "meercat",
              action: "Inquiry",
            });
            window.ChannelIO("showMessenger");
          }}
        />
      </HeaderWrapper>

      <DesktopSidebarWrapper>
        <SidebarRow
          whetherSellected={state === "report"}
          onClick={function () {
            if (state !== "report") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/report`;
            }
          }}
        >
          <SidebarIcon
            src={menuAnalysis}
            whetherSellected={state === "report"}
          />
          <MenuText whetherSellected={state === "report"}>분석 리포트</MenuText>
        </SidebarRow>

        {/* <SidebarRow
          whetherSellected={state === "community"}
          onClick={function () {
            if (state !== "community") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/community`;
            }
          }}
        >
          <SidebarIcon
            src={menuCommunity}
            whetherSellected={state === "community"}
          />
          <MenuText whetherSellected={state === "community"}>커뮤니티</MenuText>
        </SidebarRow> */}

        <SidebarRow
          whetherSellected={state === "campaign"}
          onClick={function () {
            if (state !== "campaign") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/campaign`;
            }
          }}
        >
          <SidebarIcon
            src={menuCampaign}
            whetherSellected={state === "campaign"}
          />
          <MenuText whetherSellected={state === "campaign"}>협찬 신청</MenuText>
        </SidebarRow>
        <SidebarRow
          whetherSellected={state === "register"}
          onClick={function () {
            const userData = JSON.parse(
              window.sessionStorage.getItem("userData")
            );

            if (userData) {
              if (userData.instagramUserData || userData.youtubeUserData) {
                if (state !== "register") {
                  window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/settings`;
                }
              } else {
                if (state !== "register") {
                  window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/register`;
                }
              }
            } else {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/login`;
            }
          }}
        >
          <SidebarIcon
            src={menuRegister}
            whetherSellected={state === "register"}
          />
          <MenuText whetherSellected={state === "register"}>계정 설정</MenuText>
        </SidebarRow>
      </DesktopSidebarWrapper>

      <ContentWrapper>{children}</ContentWrapper>

      <BottomTapWrapper whetherKakao={whetherKakao}>
        <BottomColumn
          whetherSellected={state === "report"}
          onClick={function () {
            if (state !== "report") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/report`;
            }
          }}
        >
          <SidebarIcon
            src={menuAnalysis}
            whetherSellected={state === "report"}
          />
          <MenuText whetherSellected={state === "report"}>분석 리포트</MenuText>
        </BottomColumn>

        {/* <BottomColumn
          whetherSellected={state === "community"}
          onClick={function () {
            if (state !== "community") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/community`;
            }
          }}
        >
          <SidebarIcon
            src={menuCommunity}
            whetherSellected={state === "community"}
          />
          <MenuText whetherSellected={state === "community"}>커뮤니티</MenuText>
        </BottomColumn> */}

        <BottomColumn
          whetherSellected={state === "campaign"}
          onClick={function () {
            if (state !== "campaign") {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/campaign`;
            }
          }}
        >
          <SidebarIcon
            src={menuCampaign}
            whetherSellected={state === "campaign"}
          />
          <MenuText whetherSellected={state === "campaign"}>협찬 신청</MenuText>
        </BottomColumn>
        <BottomColumn
          whetherSellected={state === "register"}
          onClick={function () {
            const userData = JSON.parse(
              window.sessionStorage.getItem("userData")
            );

            if (userData) {
              if (userData.instagramUserData || userData.youtubeUserData) {
                if (state !== "register") {
                  window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/settings`;
                }
              } else {
                if (state !== "register") {
                  window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/app/register`;
                }
              }
            } else {
              window.location.href = `${process.env.REACT_APP_FRONTEND_HOST}/login`;
            }
          }}
        >
          <SidebarIcon
            src={menuRegister}
            whetherSellected={state === "register"}
          />
          <MenuText whetherSellected={state === "register"}>계정 설정</MenuText>
        </BottomColumn>

        <BottomColumn
          onClick={function () {
            ReactGA.event({
              category: "meercat",
              action: "Inquiry",
            });
            window.ChannelIO("showMessenger");
          }}
        >
          <SidebarIcon src={menuInquiry} />
          <MenuText>문의 하기</MenuText>
        </BottomColumn>
      </BottomTapWrapper>
    </Container>
  );
}

export default ApplicationLayout;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  @media (max-width: 590px) {
    position: static;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const HeaderWrapper = styled.header`
  width: 100%;
  height: 80px;
  padding: 0px 30px 0px 30px;
  border-bottom: 1px solid #f6f6f6;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 4;

  @media (max-width: 590px) {
    position: fixed;
    height: 50px;
    padding: 0px 20px;
  }
`;

const DesktopSidebarWrapper = styled.div`
  width: 200px;
  height: calc(100vh - 80px);
  padding: 29.5px 0 60.5px 0;
  border-right: 1px solid #f6f6f6;
  gap: 10px;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  position: absolute;
  top: 80px;
  left: 0px;

  @media (max-width: 590px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  width: calc(100vw - 200px);
  height: calc(100vh - 80px);
  overflow-y: auto;
  position: fixed;
  margin-top: 80px;
  margin-left: 200px;
  background: #f4f4f4;

  @media (max-width: 590px) {
    position: static;
    width: auto;
    height: auto;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const LogoIcon = styled.img`
  height: 28px;

  @media (max-width: 590px) {
    height: 24px;
  }
`;

const HelpIcon = styled.img`
  width: 140px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 590px) {
    display: none;
  }
`;

const SidebarRow = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.whetherSellected ? "#ECF2FE" : "")};

  &:hover {
    filter: ${(props) =>
      props.whetherSellected
        ? ""
        : "brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)"};
  }
`;

const SidebarIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  filter: ${(props) =>
    props.whetherSellected
      ? "brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)"
      : ""};

  @media (max-width: 590px) {
    width: 20px;
    height: 20px;
    margin: 0 0 2px 0;
  }
`;

const BottomTapWrapper = styled.header`
  display: none;

  @media (max-width: 590px) {
    width: 100%;
    height: ${(props) => (props.whetherKakao ? "75px" : "60px")};
    border-top: 1px solid #eeeeee;
    background: #ffffff;
    padding: 0px 10px;
    padding-bottom: ${(props) => (props.whetherKakao ? "15px" : "0")};
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    z-index: 4;
  }
`;

const BottomColumn = styled.div`
  width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: ${(props) =>
      props.whetherSellected
        ? ""
        : "brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)"};
  }
`;

const MenuText = styled(T2)`
  font-weight: ${(props) => (props.whetherSellected ? "700" : "400")};
  color: ${(props) => (props.whetherSellected ? "#4480F7" : "#555555")};

  @media (max-width: 590px) {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
  }
`;
