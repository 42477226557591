//React
import { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components';

//react-date-range
import { Calendar } from 'react-date-range'; 
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ko from 'date-fns/locale/ko';	     // 날짜 포맷 라이브러리 (한국어 기능 임포트)

//Design System
import {
  Row,
  Image,
  H6,
  B2,
  Column
} from 'styles/DesignSystem';

//Assets
import calendarIcon from 'assets/icons/report-calendar.svg';

const Under10append0 = function(num) {
  if(num<10){
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

const SameDateChecker = function(date1, date2) {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

function InstagramCalendar({data, setSellectedDateIndex, year, month, day, setYear, setMonth, setDay}) {

  const [whetherCalendarOpened, setWhetherCalendarOpened] = useState(false);
  const [date, setDate] = useState();

  const dateList = useMemo(function(){
    let dateList = [];

    for (let i = 0; i < data.length; i++) {
      const createdAt = new Date(data[i].created_at);
      dateList.push(new Date(`${createdAt.getFullYear()}/${createdAt.getMonth()+1}/${createdAt.getDate()}`));
    }

    return dateList
  }, [data])

  useEffect(() => {
    const today = dateList[dateList.length-1];
    setDate(today);
    setYear(today.getFullYear());
    setMonth(Under10append0(today.getMonth()+1));
    setDay(Under10append0(today.getDate()));
  }, []);

  const onChangeDate = useCallback((date) => {
    setDate(date);
    setWhetherCalendarOpened(false);

    for (let i = 0; i < dateList.length; i++) {
      if(SameDateChecker(date, dateList[i])){
        setSellectedDateIndex(i);
        const sellectedDate = dateList[i];
        setYear(sellectedDate.getFullYear());
        setMonth(Under10append0(sellectedDate.getMonth()+1));
        setDay(Under10append0(sellectedDate.getDate()));
        return null;
      }
    }

    alert('해당 날짜에는 데이터가 존재하지 않습니다.');

  }, [date]);

  return (
    <CalendarDiv>
      <Column>
        <Row style={{ cursor: 'pointer', minWidth: 145  }} onClick={() => { setWhetherCalendarOpened(!whetherCalendarOpened); }}>
          <H6 bold>{`${year}.${month}.${day}`}</H6>
          <Image src={calendarIcon} left={10} width={16} />
        </Row>
        <AnalysisPoint>분석 시점 | {year}년 {month}월 {day}일 16:00 까지</AnalysisPoint>
      </Column>


      { whetherCalendarOpened &&
        (<CalendarContainer>
          <Calendar
            locale={ko}
            months={1}
            minDate={dateList[0]}
            maxDate={dateList[dateList.length-1]} 
            date={date}	// sellectedDate
            onChange={onChangeDate}
            dateDisplayFormat={'yyyy.mm.dd'}
            style={{}}
          />
        </CalendarContainer>)
      }

    </CalendarDiv>
  );

}

export default InstagramCalendar;

const CalendarDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: max-content;
  background: transparent;

  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 12px;
`

const AnalysisPoint = styled(B2)`
  display: none;

  @media (max-width: 590px){
    display: block;
    color: #AAAAAA;
    margin-top: 8px;
  }
`

const CalendarContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 280px;

  top: 76px;
  left: 22px;
  border-radius: 10px;
  border: 1.5px solid #4480F7;
  background: #FFFFFF;
  overflow: hidden;

  @media (max-width: 590px){
    height: 280px;
    top: 110px;
    left: 20px;
  }

  @media (max-width: 374px){
    width: 320px;
  }
`