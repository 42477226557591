//React
import styled from "styled-components";

//Assets
import meercatRequireLink from "assets/images/meercat-report-require-link.png";
import logoInstagram from "assets/icons/logo-instagram.svg";

//DesignSystem
import { Row, Column, H6, T2, NextButton } from "styles/DesignSystem";
import { useNavigate } from "react-router-dom";

function RequireLinkAgain() {
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate("/intent");
  };

  return (
    <Container>
      <Row>
        <MeercatImage src={meercatRequireLink} />

        <Column>
          <H6 bold color="#111111">
            서비스 연동 끊김
          </H6>
          <T2 color="#777777" top={8}>
            SNS가 연동확인 후 서비스를 이용할 수 있어요
          </T2>
        </Column>
      </Row>

      <LinkButton active onClick={handleConnectClick}>
        <MediaIcon src={logoInstagram} />
        <T2>Instagram 연동하기</T2>
      </LinkButton>
    </Container>
  );
}

export default RequireLinkAgain;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media (max-width: 590px) {
    height: calc(100vh - 60px);
    padding-top: 0px;
    justify-content: center;
  }
`;

const MeercatImage = styled.img`
  width: 60px;
  margin-right: 20px;
`;

const LinkButton = styled(NextButton)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MediaIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;
