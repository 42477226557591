//React
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';

//CountUp
import CountUp from 'react-countup';

//Design System
import {
  Row,
  Column,
  FlexBox,
  H6,
  T1,
  T2,
  B1,
  B2,
} from 'styles/DesignSystem';

//Assets
import upIcon from 'assets/Application/Analysis/icons/difference-up-red.svg';
import downIcon from 'assets/Application/Analysis/icons/difference-down-blue.svg';

const DifferenceMarkerChecker = ({ data }) => {
  if (!data) {
    return null;
  } else if (data > 0) {
    return <DifferenceMarker src={upIcon} />;
  } else {
    return <DifferenceMarker src={downIcon} />;
  }
}

function YoutubeRevenueData({ revenueData, todayIndex }) {

  let todayRevenueData = {
    yesterdayRevenue: 0,
    todayRevenue: 0,
    todayAd: 0,
    todayCPM: 0,
    todayAdImpressions: 0,
    todayCommission: '0%',
    todayRed: 0,
    todayMembershipSuperchat: 0,
  };
  let filteredRevenue = revenueData.slice();

  if (revenueData.length) {

    for (let i = 0; i < todayIndex; i++) {
      filteredRevenue.pop();
    }
    const todayData = filteredRevenue[filteredRevenue.length - 1];

    todayRevenueData.yesterdayRevenue = parseInt(filteredRevenue[filteredRevenue.length - 2]?.estimated_revenue ?? 0);
    todayRevenueData.todayRevenue = parseInt(todayData.estimated_revenue);
    todayRevenueData.todayAd = parseInt(todayData.estimated_ad_revenue);
    todayRevenueData.todayCPM = parseInt(todayData.cpm);
    todayRevenueData.todayAdImpressions = parseInt(todayData.ad_impressions);
    if(todayData.estimated_ad_revenue>0){
      todayRevenueData.todayCommission = ((1 - (todayData.estimated_ad_revenue / (todayData.cpm/1000 * todayData.ad_impressions)))*100).toFixed(1)+'%';
    }
    todayRevenueData.todayRed = parseInt(todayData.estimated_red_partner_revenue);
    if(Math.abs(todayData.estimated_revenue - todayData.estimated_ad_revenue - todayData.estimated_red_partner_revenue) <= 1){
      todayRevenueData.todayMembershipSuperchat = 0;
    } else {
      todayRevenueData.todayMembershipSuperchat = parseInt(Math.abs(todayData.estimated_revenue - todayData.estimated_ad_revenue - todayData.estimated_red_partner_revenue));
    }
    
  }

  const doughnutData = {
    datasets: [
      {
        data: revenueData.length && [
          todayRevenueData.todayRed,
          todayRevenueData.todayMembershipSuperchat,
          todayRevenueData.todayAd,
        ],
        backgroundColor: ['#F35451', '#F9D65A', '#6865FF'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      }
    }
  };

  return (
    <RevenueDataDiv>

      <T1 bold>수익 데이터</T1>
      <B1 top={8} color='#AAAAAA'>해당 금액은 실제 지급액과 차이가 있을 수 있습니다</B1>

      <TodayRevenueWrapper>
        {
          revenueData.length && todayRevenueData.todayRevenue
            ? (<>
              <DoughnutDiv>
                <Doughnut data={doughnutData} options={doughnutOptions} />
              </DoughnutDiv>

              <TodayRevenueContainer>
                <B1 color='#AAAAAA' top={-10}>오늘의 수익</B1>
                <H6 bold top={5}><CountUp end={todayRevenueData.todayRevenue} separator=',' duration={0.7} />원</H6>
                <Row top={8}>
                  <B2 color='#666666'>전날대비</B2>
                  <DifferenceMarkerChecker data={parseInt(todayRevenueData.todayRevenue - todayRevenueData.yesterdayRevenue)} />
                  <DifferenceText data={parseInt(todayRevenueData.todayRevenue - todayRevenueData.yesterdayRevenue)}>
                    {Math.abs(parseInt(todayRevenueData.todayRevenue - todayRevenueData.yesterdayRevenue)).toLocaleString()}원
                  </DifferenceText>
                </Row>
              </TodayRevenueContainer>
            </>)
            : (<T2 color='#AAAAAA' style={{ width: '100%', height:'100%', display:'flex', justifyContent: 'center', alignItems:'center' }}>데이터 없음</T2>)
        }
      </TodayRevenueWrapper>

      <Row top={40}>
        <GenderColor color='#F35451' />
        <B1 static>유튜브 프리미엄 이용자 수익</B1>
        <FlexBox />
        <T2 bold><CountUp end={todayRevenueData.todayRed} separator=',' duration={0.7} />원</T2>
      </Row>
      <HorizontalLine margin='20px 0' />

      <Row>
        <GenderColor color='#F9D65A' />
        <B1 static>채널 멤버십 + 슈퍼챗 수익</B1>
        <FlexBox />
        <T2 bold><CountUp end={todayRevenueData.todayMembershipSuperchat} separator=',' duration={0.7} />원</T2>
      </Row>
      <HorizontalLine margin='20px 0' />

      <Row>
        <Column>
          <Row>
            <GenderColor color='#6865FF' />
            <B1 static>광고 수익</B1>
          </Row>
          <B2 color='#AAAAAA' top={5}>(CPM/1000 X 광고 노출수) - YouTube 수수료</B2>
        </Column>
        <FlexBox />
        <T2 bold><CountUp end={todayRevenueData.todayAd} separator=',' duration={0.7} />원</T2>
      </Row>

      <AdDetailDiv>
        <AdDetailRow>
          <Column>
            <B1 static>CPM</B1>
            <B2 color='#AAAAAA' top={5}>광고 노출 1,000회 당 수익</B2>
          </Column>
          <FlexBox />
          <B1 static>{todayRevenueData.todayCPM.toLocaleString() ?? '0'}원</B1>
        </AdDetailRow>
        <HorizontalLine margin='20px 0' />

        <AdDetailRow>
          <B1 static>광고 노출 수</B1>
          <FlexBox />
          <B1 static>{todayRevenueData.todayAdImpressions.toLocaleString() ?? '0'}회</B1>
        </AdDetailRow>
        <HorizontalLine margin='20px 0' />

        <AdDetailRow>
          <Column>
            <B1 static>YouTube 수수료 비율</B1>
            <StyledB2 >해당 비율은 당일 노출된 광고 유형에 따라 변동될 수 있습니다</StyledB2>
          </Column>
          <FlexBox />
          <B1 static>{todayRevenueData.todayCommission}</B1>
        </AdDetailRow>
      </AdDetailDiv>      

    </RevenueDataDiv>
  );

}

export default YoutubeRevenueData;

const RevenueDataDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }
`

const TodayRevenueWrapper = styled(Row)`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  height: 200px;

  @media (max-width: 590px){
    height: 100px;
    display: flex;
    justify-content: center;
    gap: 16%;
    margin-bottom: 0px;
  }
`

const DoughnutDiv = styled.div`
  width: 300px;
  height: 200px;
  padding: 24px 16% 24px 16%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    width: 100px;
    height: 100px;
    padding: 0;
  }
`

const TodayRevenueContainer = styled(Column)`
  width: calc(100% - 200px);
  height: 200px;
  justify-content: center;

  @media (max-width: 590px){
    width: auto;
    height: 100px;
  }
`

const DifferenceMarker = styled.img`
  width: 8px;
  margin-left: 5px;
  margin-right: 3px;
`

const DifferenceText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: ${(props) =>
    (props.data === undefined || props.data === null)
      ? '#AAAAAA'
      : props.data > 0
        ? '#F35451'
        : props.data < 0
          ? '#4480F7'
          : props.data === 0
          && '#2AAE81'
  };
`

const GenderColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${props => props.color};
`

const AdDetailDiv = styled.div`
  width: 100%;
  background: #FCFCFC;
  margin-top: 20px;
  padding: 15px 0 20px 0;
`

const AdDetailRow = styled(Row)`
  margin: 0 15px;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
  margin: ${(props) => props.margin};
`

const StyledB2 = styled(B2)`
  color: #AAAAAA;
  margin-top: 5px;
  @media (max-width: 430px){
    width: 180px;
  }
`