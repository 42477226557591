//React
import styled from "styled-components";

//Assets
import logo from "assets/images/landing/Footer/footerLogo.png";

function Footer() {
  const handleLinkOnclick = function (content) {
    if (content === "채용안내") {
      window.open("https://awakecorp.career.greetinghr.com/");
    } else if (content === "회사소개") {
      window.open("http://creator.ly/team");
    } else if (content === "서비스 이용약관") {
      window.open("http://creator.ly/policy");
    } else if (content === "개인정보 처리방침") {
      window.open("http://creator.ly/privacy");
    }
  };

  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <MainContentWrapper
          style={{ maxWidth: "100%", justifyContent: "center" }}
        >
          <MainContentWrapper style={{ border: "none", padding: "0px" }}>
            <MainContentText color={"white"} weight={700}>
              <img src={logo} style={{ height: "26px" }} />
            </MainContentText>
            <InformationWrapper style={{ padding: "0px" }}>
              <InformationTextSelect
                style={{ userSelect: "none", cursor: "pointer" }}
                color={"white"}
                opacity={1}
                onClick={function () {
                  handleLinkOnclick("서비스 이용약관");
                }}
              >
                서비스 이용약관
              </InformationTextSelect>
              <InformationTextSelect
                style={{ userSelect: "none", cursor: "pointer" }}
                color={"white"}
                opacity={1}
                onClick={function () {
                  handleLinkOnclick("개인정보 처리방침");
                }}
              >
                개인정보 처리방침
              </InformationTextSelect>
              <InformationTextSelect
                style={{ userSelect: "none", cursor: "pointer" }}
                color={"white"}
                opacity={1}
                onClick={function () {
                  handleLinkOnclick("회사소개");
                }}
              >
                회사소개
              </InformationTextSelect>
              <InformationTextSelect
                style={{ userSelect: "none", cursor: "pointer" }}
                color={"white"}
                opacity={1}
                onClick={function () {
                  handleLinkOnclick("채용안내");
                }}
              >
                채용안내
              </InformationTextSelect>
            </InformationWrapper>
          </MainContentWrapper>
        </MainContentWrapper>
        <InformationWrapper>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            대표&nbsp;&nbsp;<span style={{ opacity: "0.6" }}>김민준</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            개인정보관리책임자&nbsp;&nbsp;
            <span style={{ opacity: "0.6" }}>엄준호</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            TEL&nbsp;&nbsp;<span style={{ opacity: "0.6" }}>02-6953-0212</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            E-mail&nbsp;&nbsp;
            <span style={{ opacity: "0.6" }}>sos@creator.ly</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            사업자등록번호&nbsp;&nbsp;
            <span style={{ opacity: "0.6" }}>841-88-01897</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            통신사업판매업&nbsp;&nbsp;
            <span style={{ opacity: "0.6" }}>2020-서울강남-02980</span>
          </InformationText>
          <InformationText
            style={{ userSelect: "none", minWidth: "max-content" }}
            color={"white"}
            opacity={1}
            weight={500}
          >
            서울특별시 강남구 언주로 537, 에이비티타워 11F
          </InformationText>
        </InformationWrapper>
      </FooterContentWrapper>
    </FooterWrapper>
  );
}

export default Footer;

const Text = styled.div`
  font-size: ${(props) => props.size ?? "18px"};
  font-weight: ${(props) => props.weight ?? "18px"};
  color: ${(props) => props.color ?? "black"};
  line-height: ${(props) => props.lineHeight ?? "130%"};
  opacity: ${(props) => props.opacity ?? "1"};

  user-select: none;

  @media (max-width: 590px) {
    font-size: ${(props) =>
      props.mobileSize ? props.mobileSize : props.size ?? "18px"};
  }
`;

const FooterWrapper = styled.div`
  width: 100vw;
  height: max-content;

  background: #333333;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterContentWrapper = styled.div`
  width: 100%;

  padding-bottom: 20px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (max-width: 590px) {
    padding-bottom: 10px;
  }
`;

const MainContentWrapper = styled.div`
  width: 100%;
  max-width: 1050px;

  padding: 35px 0px 20px 0px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 1050px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MainContentText = styled(Text)`
  width: max-content;
  max-width: 1050px;

  font-size: 18px;

  @media (max-width: 590px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const InformationWrapper = styled.div`
  padding: 30px 0px 25px 0px;
  max-width: 1050px;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px 40px;

  @media (max-width: 1050px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 590px) {
    gap: 5px 26px;
  }
`;

const InformationText = styled(Text)`
  font-size: 15px;
  font-weight: 400;

  @media (max-width: 590px) {
    font-size: 12px;
  }
`;

const InformationTextSelect = styled(Text)`
  font-size: 15px;
  font-weight: 400;

  @media (max-width: 590px) {
    font-size: 12px;
  }

  @media (min-width: 590px) {
    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
`;
