//React
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";

//Assets
import headerLogo from "assets/icons/creatorly-logo2.png";
import { ReactComponent as ToggleOn } from "assets/images/landing/Header/toggle_active_icon.svg";

//Components
import { Row, H6, MainBlue } from "styles/DesignSystem";

function Header({ onStart }) {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderContent>
        <NavigationWrapper>
          <Row>
            <LogoIcon src={headerLogo} />
          </Row>

          {/* <MenuContentText size={'15px'} weight={500} color={'rgb(36, 41, 46, 1)'} onClick={function(){ window.open('https://sales.meercat.io', '_blank'); }}>비즈니스</MenuContentText>

                    <MenuContentText size={'15px'} weight={500} color={'rgb(36, 41, 46, 1)'} onClick={function(){ window.open('https://creator.ly', '_blank'); }}>Creatorly</MenuContentText>

                    <MenuContentText size={'15px'} weight={500} color={'rgb(36, 41, 46, 1)'} onClick={function(){ window.open('https://creator.ly/team', '_blank'); }}>Team</MenuContentText> */}
        </NavigationWrapper>
        <StartServiceWrapper>
          <JoinWrapper onClick={onStart}>카카오톡으로 시작하기</JoinWrapper>
        </StartServiceWrapper>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;

const Text = styled.div`
  font-size: ${(props) => props.size ?? "18px"};
  font-weight: ${(props) => props.weight ?? "18px"};
  color: ${(props) => props.color ?? "black"};
  line-height: ${(props) => props.lineHeight ?? "130%"};
  opacity: ${(props) => props.opacity ?? "1"};

  user-select: none;

  @media (max-width: 590px) {
    font-size: ${(props) =>
      props.mobileSize ? props.mobileSize : props.size ?? "18px"};
  }
`;

const Flexbox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify ?? "center"};
  align-items: ${(props) => props.align ?? "center"};
  gap: ${(props) => props.gap ?? "0"}px;
  flex-flow: ${(props) => props.flow ?? ""};
`;

const MenuContentText = styled(Text)`
  width: 120px;
  padding: 13px 0;

  border-radius: 10px;

  text-align: center;
  cursor: pointer;

  position: relative;

  &:hover {
    font-weight: 700;
    background: rgba(230, 230, 230, 0.3);
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const HeaderWrapper = styled(Flexbox)`
  width: 100%;
  height: 80px;

  background: white;
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.03);

  position: fixed;
  top: 0;

  @media (max-width: 700px) {
    height: 70px;
  }

  transition: all 0.1s linear;

  z-index: 3;
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: 1130px;
  height: 100%;

  padding: 0px 40px;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    padding: 0px 20px;
  }
`;

const NavigationWrapper = styled(Flexbox)`
  gap: 30px;
`;

const StartServiceWrapper = styled(Flexbox)`
  gap: 20px;
`;

const JoinWrapper = styled(Flexbox)`
  padding: 12px 24px;

  background: #4480f7;
  border: 1px solid rgb(68, 128, 247, 0.1);
  border-radius: 10px;

  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: white;

  cursor: pointer;

  &:active {
    transform: scale(0.99);
  }

  @media (max-width: 800px) {
    padding: 10px 20px;

    border-radius: 5px;

    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: white;
  }

  transition: all 0.2s linear;
`;

const LogoIcon = styled.img`
  height: 30px;

  @media (max-width: 590px) {
    height: 26px;
  }
`;
