import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";

//Hooks
import useMediaQuery from "hook/useMediaQuery";

//Components
import Header from "pages/Landing/components/Header";
import Footer from "pages/Landing/components/Footer";
import Section01 from "./components/Section01";
import Section02 from "./components/Section02";
import Section03 from "./components/Section03";
// import Section04 from "./components/Section04";
import Section05 from "./components/Section05";
import Marketing1 from "./Marketing1";
import Marketing2 from "./Marketing2";
import Marketing3 from "./Marketing3";

//Css
import { LandingDiv } from "./styles";
import { AMPLITUDE_EVENT } from "constants/amplitude";
import { SESSION_STORAGE } from "constants/keys";
import Marketing4 from "./Marketing4";

function Landing() {
  const navigate = useNavigate();
  const marketingCode = new URL(window.location.href).searchParams.get(
    "marketing_code"
  );
  const cnt_code = new URL(window.location.href).searchParams.get("cnt_code");

  const whetherDesktop = useMediaQuery(590);
  const whetherWindow1000 = useMediaQuery(1000);

  const onStart = async function () {
    if (marketingCode) {
      await amplitude.track(AMPLITUDE_EVENT.CLICK_START, {
        source: marketingCode,
        content: cnt_code ?? "",
      }).promise;
      navigate("/login");
      return;
    }

    window?.fbq?.("trackCustom", "StartTrial");
    await amplitude.track(AMPLITUDE_EVENT.CLICK_START, {
      source: "original",
      ccontent: "",
    }).promise;
    navigate("/login");
  };

  const rememberMarketingCode = function () {
    if (marketingCode) {
      window.sessionStorage.setItem(SESSION_STORAGE.MARKETING, marketingCode);
    } else {
      window.sessionStorage.removeItem(SESSION_STORAGE.MARKETING);
    }
  };

  useLayoutEffect(function () {
    rememberMarketingCode();
  }, []);

  if (marketingCode === "1") {
    return (
      <>
        <Marketing1 onStart={onStart} />
      </>
    );
  }

  if (marketingCode === "2") {
    return (
      <>
        <Marketing2 onStart={onStart} />
      </>
    );
  }

  if (marketingCode === "3") {
    return (
      <>
        <Marketing3 onStart={onStart} />
      </>
    );
  }

  if (marketingCode === "4") {
    return (
      <>
        <Marketing4 onStart={onStart} />
      </>
    );
  }

  return (
    <LandingDiv>
      <Header onStart={onStart} />
      <Section01 whetherDesktop={whetherDesktop} />
      <Section02
        whetherDesktop={whetherDesktop}
        whetherWindow1000={whetherWindow1000}
      />
      <Section03 whetherDesktop={whetherDesktop} />
      {/* <Section04 whetherDesktop={whetherDesktop}/> */}
      <Section05 whetherDesktop={whetherDesktop} />
      <Footer />
    </LandingDiv>
  );
}

export default Landing;
