//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

function YoutubeInflowKeyword({ data }) {

  const keywordSource = [];
  let keywordSourceSum = 0;
  if (data) {
    for (const key in data) {
      keywordSourceSum += data[key];
    }
    for (const key in data) {
      let keywordData = { name: key };
      keywordData.value = ((data[key] / keywordSourceSum) * 100).toFixed(1);
      keywordSource.push(keywordData);
    }
  }

  return (
    <InflowKeywordDiv>

      <Row>
        <T1 bold>검색어 유입 키워드</T1>
      </Row>

      {
        data
          ? (<InflowKeywordWrapper>
            {keywordSource.map((item, index) => (
              <Column key={index}>
                <Row>
                  <KeywordRanking>{index + 1}</KeywordRanking>
                  <T2 left={12}>#{item.name}</T2>
                  <FlexBox />
                  <T2 bold><CountUp end={item.value} duration={0.7} decimals={1} decimal='.' />%</T2>
                </Row>
                <HorizontalLine last={index === keywordSource.length - 1} />
              </Column>
            ))}
          </InflowKeywordWrapper>)
          : (<NoData>데이터 없음</NoData>)
      }

    </InflowKeywordDiv>
  );

}

export default YoutubeInflowKeyword;

const InflowKeywordDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 630px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: auto;
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }
`

const InflowKeywordWrapper = styled(Column)`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  gap: 20px;

  @media (max-width: 590px){
    margin-top: 30px;
  }
`

const KeywordRanking = styled(T2)`
  font-weight: bold;
  margin-left: 10px;
  width: 18px;
  text-align: center;

  @media (max-width: 590px){
    margin-left: 5px;
    width: 16px;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
  margin-top: 17px;
  display: ${(props) => props.last && 'none'};
`

const NoData = styled(T2)`
  width: 100%;
  height: 100%;
  color: #AAAAAA;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    height: 300px;
  }
`