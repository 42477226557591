//React
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'

//Css
import { 
  H5,
  T1,
  T2,
  MainBlue,
  ActionButton
} from 'styles/DesignSystem';

//Components
import { LinkReauth } from 'apis/Register';

function InstagramReauth() {

  const kid = new URL(window.location.href).searchParams.get('kid');
  const type = new URL(window.location.href).searchParams.get('type');

  const navigate = useNavigate();

  return (
    <Container>
      <ModalContainer>
        <StyledH5top20><MainBlue>인스타그램 재연동 가이드</MainBlue></StyledH5top20>

        <T1 top={60} bold color='#555555'>연동 과정에서 오류가 발생했다면?</T1>
        <T2 top={5} color='#555555'>가이드를 다시 확인하시고 재연동을 진행해 주세요</T2>
        <ActionButton top={30} onClick={function () { navigate(`/app/register/guide?kid=${kid}&type=${type}`); }}><T2>연동 가이드 다시보기</T2></ActionButton>

        <T1 top={60} bold color='#555555'>다른 페이스북 계정으로 로그인 됐다면?</T1>
        <T2 top={5} color='#555555'>페이스북 로그아웃 후, 재연동 해보세요</T2>
        <ActionButton top={30} onClick={function () { window.location.href = 'https://www.facebook.com/bookmarks/'; }}><T2>페이스북 로그아웃하기</T2></ActionButton>
        <ActionButton top={10} onClick={function () { LinkReauth(kid, type, navigate); }}><T2>재연동 시도하기</T2></ActionButton>

        <hr width={320} size={1} color='#E6E6E6' style={{margin:'40px 0'}} />
        
        <ActionButton onClick={function(){ window.ChannelIO('showMessenger'); }}><T2>데이터 분석팀에게 SOS</T2></ActionButton>
      </ModalContainer>
    </Container>
  );
}

export default InstagramReauth;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px){
    height: auto;
  }
`

const ModalContainer = styled.div`
  width: 416px;
  padding: 60px 0;
  background: #FCFCFC;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px){
    width: 100%;
    min-height: calc(100vh - 140px);
    border-radius: 0px;
    padding: 40px 0 120px;
  }
`

const StyledH5top20 = styled(H5)`
  @media (max-width: 590px){
    margin-top: 20px;
  }
`