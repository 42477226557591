import React from 'react'
import styled, { css } from 'styled-components'

function LoadingDiv({blue}) {
  return (
    <Wrapper>
        <Loader blue={blue} />
    </Wrapper>
  )
}

export default LoadingDiv

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`

const Loader = styled.section`
    font-size: 3px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);    

    ${({blue}) => blue &&
    css`
      -webkit-animation: load6 1.1s infinite ease;
      animation: load6 1.1s infinite ease;
    `
    }


  .loader {
    margin: 100px auto;
    font-size: 3px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }

  @-webkit-keyframes load6 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #4480F7, 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.5), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.7), 1.8em -1.8em 0 0em #4480F7, 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.5), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7), 2.5em 0em 0 0em #4480F7, 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5), 2.5em 0em 0 0em rgba(68, 128, 247, 0.7), 1.75em 1.75em 0 0em #4480F7, 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.5), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.7), 0em 2.5em 0 0em #4480F7, -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.5), 0em 2.5em 0 0em rgba(68, 128, 247, 0.7), -1.8em 1.8em 0 0em #4480F7, -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.5), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.7), -2.6em 0em 0 0em #4480F7, -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.5), -2.6em 0em 0 0em rgba(68, 128, 247, 0.7), -1.8em -1.8em 0 0em #4480F7;
    }
  }
  @keyframes load6 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #4480F7, 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.5), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.7), 1.8em -1.8em 0 0em #4480F7, 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.5), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7), 2.5em 0em 0 0em #4480F7, 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5), 2.5em 0em 0 0em rgba(68, 128, 247, 0.7), 1.75em 1.75em 0 0em #4480F7, 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.5), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.7), 0em 2.5em 0 0em #4480F7, -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.5), 0em 2.5em 0 0em rgba(68, 128, 247, 0.7), -1.8em 1.8em 0 0em #4480F7, -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.5), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.7), -2.6em 0em 0 0em #4480F7, -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.5), -2.6em 0em 0 0em rgba(68, 128, 247, 0.7), -1.8em -1.8em 0 0em #4480F7;
    }
  }
`