//React
import { useState } from 'react';
import styled from 'styled-components';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

//Components
import CustomRadio from 'pages/Report/components/CustomRadio';

//Design System
import {
  Row,
  FlexBox,
  T1,
} from 'styles/DesignSystem';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

function InstagramFollowerAge({ data }) {

  const radioValueList = ['남성', '여성']
  const [radioValue, setRadioValue] = useState(radioValueList[0]);

  let maleCount = [0, 0, 0, 0, 0];
  let maleSum = 0;
  let maleRatio = [0, 0, 0, 0, 0];
  if(data?.male?.length){
    for (let element of data.male) {
      if (element.age === '13-17') maleCount[0] += element.count
      else if (element.age === '18-24') maleCount[1] += element.count
      else if (element.age === '25-34') maleCount[2] += element.count
      else if (element.age === '35-44') maleCount[3] += element.count
      else if (element.age === '45-54') maleCount[4] += element.count
      else if (element.age === '55-64') maleCount[4] += element.count
      else if (element.age === '65+') maleCount[4] += element.count
    }
  }
  for (let count of maleCount) {
    maleSum += count;
  }
  if(maleSum){ //maleSum이 0인 경우 제외
    maleRatio = maleCount.map(count => count / maleSum * 100);
  }

  let femaleCount = [0, 0, 0, 0, 0];
  let femaleSum = 0;
  let femaleRatio = [0, 0, 0, 0, 0];
  if(data?.female?.length){
    for (let element of data.female) {
      if (element.age === '13-17') femaleCount[0] += element.count
      else if (element.age === '18-24') femaleCount[1] += element.count
      else if (element.age === '25-34') femaleCount[2] += element.count
      else if (element.age === '35-44') femaleCount[3] += element.count
      else if (element.age === '45-54') femaleCount[4] += element.count
      else if (element.age === '55-64') femaleCount[4] += element.count
      else if (element.age === '65+') femaleCount[4] += element.count
    }
  }
  for (let count of femaleCount) {
    femaleSum += count;
  }
  if(femaleSum){ //femaleSum이 0인 경우 제외
    femaleRatio = femaleCount.map(count => count / femaleSum * 100);
  }

  const barDataMale = {
    labels: ['13 - 17세', '18 - 24세', '25 - 34세', '35 - 44세', '+ 45세'],
    datasets: [
      {
        data: maleRatio,
        backgroundColor: '#4F5CD9',
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
        barThickness: `${window.innerWidth > 590 ? 20 : 16}`,
      },
    ],
  };

  const barDataFemale = {
    labels: ['13 - 17세', '18 - 24세', '25 - 34세', '35 - 44세', '+ 45세'],
    datasets: [
      {
        data: femaleRatio,
        backgroundColor: '#F35451',
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
        barThickness: `${window.innerWidth > 590 ? 20 : 16}`,
      },
    ],
  };

  const barOptions = {
    hoverBackgroundColor: `${radioValue === '남성' ? '#4F5CD9' : '#F35451'}`,
    layout: {
      padding: {
        right: `${window.innerWidth > 590 ? 0 : 30}`,
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        titleAlign: 'center',
        bodyAlign: 'center',
        callbacks: {
          title: function (context) {
            if (context[0].dataIndex === 4) {
              return `45세 이상 ${radioValue}`;
            } else {
              return context[0].label + ` ${radioValue}`;
            }
          },
          label: function (context) {
            if (radioValue === '남성') {
              return maleCount[context.dataIndex].toLocaleString() + '명 (' + context.parsed.y.toFixed(1) + '%)';
            } else {
              return femaleCount[context.dataIndex].toLocaleString() + '명 (' + context.parsed.y.toFixed(1) + '%)';
            }
          },
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#AAAAAA',
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        }
      },
      y: {
        ticks: {
          stepSize: 10,
          color: '#AAAAAA',
          callback: function(value) {
            return value + '% ';
          }
        },
        grid: {
          color: '#E7E7E7',
          drawTicks: false,
          drawBorder: false,
        }
      }
    }
  };

  return (
    <FollowerAgeDiv>

      <Row>
        <Title>팔로워 연령대</Title>
        <FlexBox />
        <CustomRadio radioValueList={radioValueList} radioValue={radioValue} setRadioValue={setRadioValue} />
      </Row>

      <FlexBox />

      <BarDiv>
        <BarContainer>
          <Bar data={radioValue === '남성' ? barDataMale : barDataFemale} options={barOptions} />
        </BarContainer>
      </BarDiv>

    </FollowerAgeDiv>
  );

}

export default InstagramFollowerAge;

const FollowerAgeDiv = styled.div`
  width: 464px;
  height: 320px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 20px;

  padding: 20px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1860px){
    margin-top: 4%;
    width: 100%;
    max-width: 523px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 320px;
    margin-top: 20px;
    padding: 0;
    border-radius: 5px;
  }
`

const Title = styled(T1)`
  font-weight: 700;

  @media (max-width: 590px){
    margin-top: 20px;
    margin-left: 15px;
  }
`

const BarDiv = styled.div`
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 590px){
    overflow: scroll;
    padding-left: 20px;
    padding-bottom: 30px;
  }
`

const BarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    min-width: 424px;
    max-width: 460px;
  }
`