//React
import React from "react";
import styled from "styled-components";

//Css
import { T1 } from "styles/DesignSystem";

function Loader({ ...props }) {
  return (
    <Background>
      <LoaderIconCss />
      <T1 top={30} color="#AAAAAA">
        잠시만 기다려 주세요
      </T1>
    </Background>
  );
}

export default Loader;

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
`;

const LoaderIconCss = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: transparent;
  border: 5px solid #aaaaaa;
  border-top: 5px solid transparent;
  animation: rotation;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
