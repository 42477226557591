//React
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as amplitude from "@amplitude/analytics-browser";

//Css
import { Column, Image, H5, T1, T2, B2, MainBlue } from "styles/DesignSystem";

//Assets
import youtubeLogo from "assets/icons/youtube-color.png";
import logoFacebookInstagram from "assets/icons/logo-facebook-instagram.svg";
import { AMPLITUDE_EVENT } from "constants/amplitude";

const sessionUserData = JSON.parse(window.sessionStorage.getItem("userData"));
const { instagramUserData, youtubeUserData } = sessionUserData ?? {};

function LinkTypeSelect() {
  const navigate = useNavigate();

  const onInstagrarmClick = async function () {
    if (Boolean(instagramUserData?.require_login_again)) {
      const kid = sessionUserData?.kakaoUserData?.userId;
      if (!kid) return;
      await amplitude.track(AMPLITUDE_EVENT.CLICK_CONNECT, {
        channel: "Instagram",
      }).promise;
      navigate(`/app/register/reauth?kid=${kid}&type=instagram`);
    } else {
      navigate("/intent");
    }
  };

  const onYoutubeClick = async function () {
    await amplitude.track(AMPLITUDE_EVENT.CLICK_CONNECT, {
      channel: "Youtube",
    }).promise;
    navigate("/youtube-intent");
  };

  return (
    <Container>
      <SelectContainer>
        <StyledH5top60>
          간단한 <MainBlue>SNS 계정 연동</MainBlue>으로
        </StyledH5top60>
        <StyledH5>서비스를 이용해 보세요</StyledH5>
        <StyledT2>서비스 이용을 위해 SNS 계정을 연동해야 합니다</StyledT2>

        <SelectButton1
          disabled={Boolean(
            instagramUserData && !instagramUserData.require_login_again
          )}
          onClick={onInstagrarmClick}
        >
          <Column style={{ alignItems: "flex-start" }}>
            <B2 color="#AAAAAA">인스타그램 & 페이스북</B2>
            <T1
              bold
              color={
                instagramUserData?.require_login_again === false
                  ? "#4480F7"
                  : undefined
              }
              top={5}
            >
              {instagramUserData
                ? instagramUserData.require_login_again
                  ? "재연동"
                  : "연동완료"
                : "Instagram 연동하기"}
            </T1>
          </Column>
          <Image src={logoFacebookInstagram} width={67} />
        </SelectButton1>
        <SelectButton
          style={{ marginTop: 8 }}
          disabled={Boolean(youtubeUserData)}
          top={15}
          onClick={onYoutubeClick}
        >
          <Column style={{ alignItems: "flex-start" }}>
            <B2 color="#AAAAAA">유튜브</B2>
            <T1 bold color={youtubeUserData ? "#4480F7" : undefined} top={5}>
              {youtubeUserData ? "연동완료" : "YouTube 연동하기"}
            </T1>
          </Column>
          <Image src={youtubeLogo} width={36} />
        </SelectButton>

        <StyledB2
          onClick={function () {
            navigate("/app/report/sample");
          }}
        >
          둘러보기
        </StyledB2>
      </SelectContainer>
    </Container>
  );
}

export default LinkTypeSelect;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  z-index: 1;

  @media (max-width: 590px) {
    // height: calc(100% - 60px);
    min-height: 580px;
    //margin-top: 60px;
    z-index: 10;
  }
`;

const SelectContainer = styled.div`
  padding: 60px 48px;
  background: #fcfcfc;
  border-radius: 10px;

  @media (max-width: 590px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 40px 20px;
  }
`;

const SelectButton = styled.button`
  width: 320px;
  height: 85px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 590px) {
    width: 100%;
  }

  &:hover:not([disabled]) {
    background: #ecf2fe;
    border: 1px solid #4480f7;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

const SelectButton1 = styled(SelectButton)`
  margin-top: 60px;

  @media (max-width: 590px) {
    margin-top: 67px;
  }
`;

const StyledH5top60 = styled(H5)`
  @media (max-width: 590px) {
    margin-top: 60px;
  }
`;

const StyledH5 = styled(H5)`
  margin-top: 5px;

  @media (max-width: 590px) {
    margin-top: 9px;
  }
`;

const StyledT2 = styled(T2)`
  margin-top: 8px;
  color: #555555;

  @media (max-width: 590px) {
    margin-top: 10px;
  }
`;

const StyledB2 = styled(B2)`
  width: 42px;
  margin-top: 14px;
  border-bottom: 1px solid #ffffff;
  color: #777777;
  cursor: pointer;

  @media (max-width: 590px) {
    margin-top: 60px;
    width: 39px;
  }

  &:hover {
    border-bottom: 1px solid #777777a1;
  }
`;

const MessageModal = styled.div`
  width: 440px;
  height: 80px;

  background: black;
  border-radius: 4px;
  opacity: ${(props) => (props.isActive ? "0.9" : "0")};

  color: white;

  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.4s linear;

  @media (max-width: 590px) {
    height: 60px;
    width: 320px;
  }
`;
