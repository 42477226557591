//React
import styled from "styled-components";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/css";

//Css
import {
  Text,
  CenterDefault,
  Row,
  Image,
  SizedBox,
  Blue,
  Title,
  ComponentDiv,
  ComponentContainer,
  RelativeDiv,
  RelativeContainer,
  RelativeComponent,
} from "pages/Landing/styles";

//Assets
import background from "assets/images/landing/section03/background.png";

//Assets
import aprom from "assets/images/landing/section03/aprom.png";
import sunglass from "assets/images/landing/section03/sunglass.png";
import cap from "assets/images/landing/section03/cap.png";
import padding from "assets/images/landing/section03/padding.png";
import { ReactComponent as ArrowLeft } from "assets/images/landing/section03/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/landing/section03/arrow-right.svg";

const videoLink = [
  "https://s3-active-checker.s3.ap-northeast-2.amazonaws.com/review_aprom.mp4",
  "https://s3-active-checker.s3.ap-northeast-2.amazonaws.com/review_cap.mp4",
  "https://s3-active-checker.s3.ap-northeast-2.amazonaws.com/review_sunglass.mp4",
  "https://s3-active-checker.s3.ap-northeast-2.amazonaws.com/review_padding.mp4",
];

function Section03({ whetherDesktop }) {
  SwiperCore.use([Navigation]);

  const params = {
    cssMode: false, // 아이폰12에서 끊어지는 현상 발생해서 추가함.
    spaceBetween: 100,
    effect: "coverflow",
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    speed: 600,
  };

  const SwiperStyles = { width: "100%" };
  const SlideStyles = {
    width: "100%",
    background: "transparent",
    display: "flex",
    flexDirection: "Column",
  };

  return (
    <ComponentDiv whetherBackground={true}>
      <Background>
        <Image
          src={background}
          width="100%"
          style={{ minWidth: whetherDesktop ? "1800px" : "1000px" }}
        />
      </Background>

      <ComponentContainer
        center={true}
        whetherBackground={true}
        style={{ maxWidth: "1280px" }}
      >
        <SizedBox height={whetherDesktop ? "250px" : "200px"} />

        <Title flexDirection="column" center={true}>
          SNS 분석 리포트를 받고 있는
          <Row>
            <Blue>실제 크리에이터의 후기</Blue>를 들어보세요!
          </Row>
        </Title>

        <SizedBox height={whetherDesktop ? "60px" : "30px"} />

        <Row style={{ width: "100%" }}>
          {whetherDesktop && (
            <NavigationButton className="swiper-button-prev">
              <ArrowLeft />
            </NavigationButton>
          )}

          <Swiper {...params} style={SwiperStyles}>
            <SwiperSlide style={SlideStyles}>
              <RelativeDiv>
                <RelativeContainer ratio="61.9%">
                  <RelativeComponent>
                    <Video
                      playsInline
                      controls
                      width={"100%"}
                      src={videoLink[0]}
                      type="video/mp4"
                      poster={whetherDesktop ? null : aprom}
                      style={{ overflow: "hidden" }}
                    />
                  </RelativeComponent>
                </RelativeContainer>
              </RelativeDiv>
              <SizedBox height={whetherDesktop ? "5%" : "45px"} />
              <TextBox>
                <CreatorName>크리에이터 에이프롬님</CreatorName>
                SNS 자체 인사이트 기능은 늘 스스로 분석하려고 애 썼는데,
                크리에이터리는 매일매일 각 포스팅별 도달 순위를 보여줘 팬들이
                어떤 컨텐츠를 좋아하는지 명확하게 알 수 있었어요. (그리고 미어캣
                넘 귀여오요. 보는 맛 +100)
                <CreatorImg src={aprom} />
              </TextBox>
            </SwiperSlide>

            <SwiperSlide style={SlideStyles}>
              <RelativeDiv>
                <RelativeContainer ratio="61.9%">
                  <RelativeComponent>
                    <Video
                      playsInline
                      controls
                      width={"100%"}
                      src={videoLink[1]}
                      type="video/mp4"
                      poster={whetherDesktop ? null : cap}
                      style={{ overflow: "hidden" }}
                    />
                  </RelativeComponent>
                </RelativeContainer>
              </RelativeDiv>
              <SizedBox height={whetherDesktop ? "5%" : "45px"} />
              <TextBox>
                <CreatorName>크리에이터 j_botong 님</CreatorName>
                크리에이터리 리포트는 믿을만한 정보만 전달해 줘서 좋았어요~!
                인스타를 키워 보고 싶다는 생각을 했지만 어떻게 키워야 할지 감을
                잡을 수 없었는데 크리에이터리는 새로운 인사이트에 대해 많은
                정보들을 줘서 좋았어요! 또 어떤 피드가 도달이 잘 되고 있는지
                알려줘서 내 인스타 스타일을 아는 데에 많은 도움이 됐어요!
                <CreatorImg src={cap} />
              </TextBox>
            </SwiperSlide>

            <SwiperSlide style={SlideStyles}>
              <RelativeDiv>
                <RelativeContainer ratio="61.9%">
                  <RelativeComponent>
                    <Video
                      playsInline
                      controls
                      width={"100%"}
                      src={videoLink[2]}
                      type="video/mp4"
                      poster={whetherDesktop ? null : sunglass}
                      style={{ overflow: "hidden" }}
                    />
                  </RelativeComponent>
                </RelativeContainer>
              </RelativeDiv>
              <SizedBox height={whetherDesktop ? "5%" : "45px"} />
              <TextBox>
                <CreatorName>크리에이터 neverbemodern 님</CreatorName>
                따로 어플을 깔지 않아도 카카오톡으로 쉽고 빠르게 사용할 수
                있어서 좋았습니다 평소에 보안 문제로 스트레스를 많이 받았었는데
                크리에이터리는 인스타그램과 공식 파트너십을 받아서 그 부분에서는
                걱정없이 사용할 수 있어서 좋았습니다 그리고 매일 크리에이터리가
                주는 솔직한 피드백이 계정을 키우는데 많은 도움이 됐습니다
                <CreatorImg src={sunglass} />
              </TextBox>
            </SwiperSlide>

            <SwiperSlide style={SlideStyles}>
              <RelativeDiv>
                <RelativeContainer ratio="61.9%">
                  <RelativeComponent>
                    <Video
                      playsInline
                      controls
                      width={"100%"}
                      src={videoLink[3]}
                      type="video/mp4"
                      poster={whetherDesktop ? null : padding}
                      style={{ overflow: "hidden" }}
                    />
                  </RelativeComponent>
                </RelativeContainer>
              </RelativeDiv>
              <SizedBox height={whetherDesktop ? "5%" : "45px"} />
              <TextBox>
                <CreatorName>크리에이터 y___hwa 님</CreatorName>
                매일 밤마다 내 인스타를 상세하게 분석해 줘서 좋았고 데이터를
                보내줄 때 보기 좋게 정리된 데이터를 전달해 줘서 확인하기도
                편했어요! 패션 인플루언서지만 다른 컨셉의 피드도 올리고
                있는데요! 어떤 피드가 도달이 잘 되는지 안 되는지를
                크리에이터리가 알려줘서 좋았어요!
                <CreatorImg src={padding} />
              </TextBox>
            </SwiperSlide>
          </Swiper>

          {whetherDesktop && (
            <NavigationButton className="swiper-button-next">
              <ArrowRight />
            </NavigationButton>
          )}
        </Row>

        {!whetherDesktop && (
          <Row>
            <NavigationButton className="swiper-button-prev">
              <ArrowLeft />
            </NavigationButton>
            <NavigationButton className="swiper-button-next">
              <ArrowRight />
            </NavigationButton>
          </Row>
        )}
      </ComponentContainer>
    </ComponentDiv>
  );
}

export default Section03;

const Background = styled.div`
  position: absolute;
  top: 45%;
  width: 100%;
  ${CenterDefault({})}

  @media only screen and (max-width: 590px) {
    top: 278px;
  }
`;

const NavigationButton = styled.button`
  width: 60px;
  height: 60px;
  margin: 63% 10px 0 10px;

  /* background: #FDFDFD;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px; */
  background: transparent;
  cursor: pointer;

  @media only screen and (max-width: 590px) {
    margin-top: 20px;
  }
`;

const Video = styled.video`
  width: 100%;
  border-radius: 20px;

  @media only screen and (max-width: 590px) {
    border-radius: 5px;
  }
`;

const TextBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 192px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid #eeeeee;
  cursor: grab;
  padding: 43px 250px 43px 25px;
  ${CenterDefault({
    alignItems: "flex-start",
  })}
  ${Text({
    color: "opacity80",
  })}

  @media (max-width: 900px) {
    padding-right: 25px;
  }

  @media (max-width: 590px) {
    min-height: 163px;
    padding: 20px 20px 13px 20px;
    font-size: 12px;
  }
`;

const CreatorName = styled.div`
  padding: 5px 20px;
  margin-bottom: 5px;

  background: #e4eaf6;
  border-radius: 5px;
  white-space: nowrap;

  ${Text({
    fontWeight: "700",
    fontSize: "15px",
    color: "meercatBlue",
  })}

  @media (max-width: 590px) {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -14px;
    transform: translate(-50%, 0%);
    font-size: 13px;
  }
`;

const CreatorImg = styled.img`
  max-width: 180px;

  position: absolute;
  right: 20px;
  bottom: 0px;

  @media (max-width: 900px) {
    display: none;
  }
`;
