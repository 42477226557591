import React from "react";
import styled from "styled-components";

//assets
import meercatLogoIcon from "assets/icons/creatorly-logo.png";
import {
  BlueText,
  Container,
  Desciprion1,
  Description2,
  Description3,
  FixedBackground,
  Header,
  Image,
  ImageContainer,
  Section,
  Section2,
  SmallStartButton,
  StartButton,
  Title1,
  Title2,
  Title3,
  Wrapper,
} from "../components/Marketing";
import campaignLogo from "assets/images/campaign-list/고봉민김밥인 협찬신청.png";
import meercat from "assets/images/Home/meercat.png";
import report from "assets/images/Home/report.png";
import trending from "assets/images/Home/trending.png";
import influencer from "assets/images/Home/influencer.png";
import unfollow from "assets/images/Home/unfollow.png";
import kakao from "assets/images/Home/kakao.png";
import account from "assets/images/Home/account.png";
import reference from "assets/images/Home/reference.png";
import brand from "assets/images/Home/brand.png";
import money from "assets/images/Home/money.png";
import CreatorSlide from "../components/Marketing/CreatorSlide";
import CreatorVideo from "../components/Marketing/Video";

export interface Marketing3Props {
  onStart: () => void;
}

const Marketing3: React.FC<Marketing3Props> = function ({ onStart }) {
  return (
    <>
      <FixedBackground />
      <Container>
        <Wrapper>
          <Header>
            <img src={meercatLogoIcon} style={{ width: 120 }} />
            <SmallStartButton onClick={onStart}>{"가입하기"}</SmallStartButton>
          </Header>

          <Section style={{ padding: "60px 36px" }} isGradient>
            <ImageContainer>
              <Image src={money} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 24 }} />
            <Title1>
              {"광고, 협찬으로"}
              <br />
              {"돈 버는 인스타 만들기"}
            </Title1>
            <div style={{ marginTop: 12 }} />
            <Desciprion1>
              {"브랜드 콜라보레이션을 통해,"} <br />
              {"인스타 수익창출을 시작해보세요"}
            </Desciprion1>
            <div style={{ marginTop: 24 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section>

          <Section background="#F5F5F5" style={{ padding: "60px 0px" }}>
            <Title3>
              {"29,114명 인플루언서의"}
              <br />
              {"이유 있는 선택"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorSlide />
            </ImageContainer>
          </Section>

          <Section2>
            <Title2>
              {"힙한 브랜드와의"}
              <br />
              {"콜라보레이션"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={brand} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {"전세계 유명 브랜드와 협업하여,"}
              <br />
              {"개성 넘치는 콘텐츠를 제작하세요"}
            </Description2>
          </Section2>

          <Section2>
            <Title2>
              {"유니크한 인스타 계정을"}
              <br />
              {"가졌다면 누구나"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={influencer} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {
                "팔로워 수와 관계없이 참여할 수 있는 캠페인도 있으니 고민하지 마세요"
              }
            </Description2>
          </Section2>

          <Section2>
            <Title2>
              {"카톡으로 편하게"}
              <br />
              {"광고 제안을 받아보세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={kakao} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {"친절한 미어캣이 카톡으로 직접 보내드려요."}
              <br />
              {"더 이상 광고를 일일이 찾아다니지 마세요!"}
            </Description2>
          </Section2>

          <Section background="#F5F5F5">
            <Title3>
              {"인플루언서의"}
              <br />
              {"생생한 후기를 들어보세요"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorVideo />
            </ImageContainer>
          </Section>

          <Section2>
            <Title2 style={{ fontSize: 22, lineHeight: "28px" }}>
              {"카카오톡 회원가입 후"}
              <br />
              {"인스타 채널을 꼭! 연동해주세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <Description3>
              {"Instagram 채널 연동이 완료되어야"} <br />
              {"알림을 받을 수 있습니다"}
            </Description3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={account} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section2>

          <Section background="#F5F5F5">
            <Title3>
              {"크리에이터를 1인 기업으로,"}
              <br />
              {"크리에이터리"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={reference} style={{ objectFit: "cover" }} />
            </ImageContainer>
          </Section>
        </Wrapper>
      </Container>
    </>
  );
};

export default Marketing3;
