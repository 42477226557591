import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import styled, { css } from "styled-components";

//assets
import life from "assets/icons/category/life.png";
import food from "assets/icons/category/food.png";
import beauty from "assets/icons/category/beauty.png";
import living from "assets/icons/category/living.png";
import travel from "assets/icons/category/travel.png";
import baby from "assets/icons/category/baby.png";
import it from "assets/icons/category/it.png";
import fashion from "assets/icons/category/fashion.png";
import books from "assets/icons/category/books.png";
import education from "assets/icons/category/education.png";
import offline from "assets/icons/category/offline.png";
import information from "assets/icons/category/information.png";
import game from "assets/icons/category/game.png";
import health from "assets/icons/category/health.png";
import business from "assets/icons/category/business.png";
import pets from "assets/icons/category/pets.png";

import meercat from "assets/Application/Settings/meercat.png";
import kakaotalk from "assets/Application/Settings/kakaotalk.png";

import check from "assets/icons/category-check.svg";

//api
import { api } from "apis/Report";
import { useNavigate } from "react-router-dom";

//utils
import refreshUserData from "utils/refreshUserData";
import LoadingDiv from "components/LoadingDiv";
import { GlobalContext } from "App";

const CATEGORY_TYPES = {
  LIFE: {
    name: "일상",
    image: life,
  },
  FOOD: {
    name: "음식/맛집",
    image: food,
  },
  BEAUTY: {
    name: "뷰티/미용",
    image: beauty,
  },
  LIVING: {
    name: "생활/리빙",
    image: living,
  },
  TRAVEL: {
    name: "여행",
    image: travel,
  },
  BABY: {
    name: "육아/아동",
    image: baby,
  },
  IT: {
    name: "디지털/IT",
    image: it,
  },
  FASHION: {
    name: "패션",
    image: fashion,
  },
  BOOK: {
    name: "도서",
    image: books,
  },
  EDUCATION: {
    name: "교육",
    image: education,
  },
  OFFLINE: {
    name: "방송/연예",
    image: offline,
  },
  INFORMATION: {
    name: "정보전달",
    image: information,
  },
  GAME: {
    name: "게임",
    image: game,
  },
  HEALTH: {
    name: "스포츠/헬스",
    image: health,
  },
  BUSINESS: {
    name: "비즈니스",
    image: business,
  },
  PETS: {
    name: "반려동물",
    image: pets,
  },
};

const GENDER_TYPES = {
  MALE: "남자",
  FEMALE: "여자",
  ETC: "기타",
  PRIVATE: "비공개",
};

const SNS_TYPE = {
  INSTAGRAM: "INSTAGRAM",
  YOUTUBE: "YOUTUBE",
};

function Settings() {
  const navigate = useNavigate();
  const { handleToast } = useContext(GlobalContext);
  const userData = JSON.parse(window.sessionStorage.getItem("userData"));
  const token = userData?.accessToken;
  const [instagramUserData, setInstagramUserData] = useState(
    userData?.instagramUserData
  );
  const [youtubeUserData, setYoutubeUserData] = useState(
    userData?.youtubeUserData
  );

  if (!token) window.location.href = `${window.location.href}/login`;
  if (!instagramUserData && !youtubeUserData)
    window.location.href = `${window.location.href}/register`;

  const [snsType, setSnsType] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const [name, setName] = useState("");
  const [isAlarm, setIsAlarm] = useState(false);
  const [category, setCategory] = useState([]);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [adType, setAdType] = useState([]);
  const [region, setRegion] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [specificLoading, setSpecificLoading] = useState(false);
  const [signoutLoading, setSignoutLoading] = useState(false);

  const categoryRef = useRef();
  const specificRef = useRef();

  const setToYoutubeData = function (youtubeUserData) {
    categoryRef.current =
      youtubeUserData?.category_array?.map(function (category) {
        const index = Object.keys(CATEGORY_TYPES).findIndex(
          (key) => CATEGORY_TYPES[key].name === category
        );
        return Object.keys(CATEGORY_TYPES)[index];
      }) ?? [];
    specificRef.current = {
      gender:
        Object.keys(GENDER_TYPES)[
          Object.keys(GENDER_TYPES).findIndex(
            (type) => GENDER_TYPES[type] === youtubeUserData?.gender
          )
        ],
      age: youtubeUserData?.age,
      adType: youtubeUserData?.ads_array ?? [],
      region: youtubeUserData?.region_array ?? [],
    };

    setSnsType(SNS_TYPE.YOUTUBE);
    setProfileImg(youtubeUserData?.thumbnail_url);
    setName(youtubeUserData?.channel_title);
    setIsAlarm(youtubeUserData?.is_active);
    setCategory(
      youtubeUserData?.category_array?.map(function (category) {
        const index = Object.keys(CATEGORY_TYPES).findIndex(
          (key) => CATEGORY_TYPES[key].name === category
        );
        return Object.keys(CATEGORY_TYPES)[index];
      }) ?? []
    );
    setGender(
      Object.keys(GENDER_TYPES)[
        Object.keys(GENDER_TYPES).findIndex(
          (type) => GENDER_TYPES[type] === youtubeUserData?.gender
        )
      ]
    );
    setAge(youtubeUserData?.age);
    setAdType(youtubeUserData?.ads_array ?? []);
    setRegion(youtubeUserData?.region_array ?? []);
  };

  const setToInstagramData = function (instagramUserData) {
    categoryRef.current =
      instagramUserData?.category_array?.map(function (category) {
        const index = Object.keys(CATEGORY_TYPES).findIndex(
          (key) => CATEGORY_TYPES[key].name === category
        );
        return Object.keys(CATEGORY_TYPES)[index];
      }) ?? [];
    specificRef.current = {
      gender:
        Object.keys(GENDER_TYPES)[
          Object.keys(GENDER_TYPES).findIndex(
            (type) => GENDER_TYPES[type] === instagramUserData?.gender
          )
        ],
      age: instagramUserData?.age,
      adType: instagramUserData?.ads_array ?? [],
      region: instagramUserData?.region_array ?? [],
    };

    setSnsType(SNS_TYPE.INSTAGRAM);
    setProfileImg(instagramUserData?.profile_picture);
    setName(instagramUserData?.user_name);
    setIsAlarm(instagramUserData?.is_active);
    setCategory(
      instagramUserData?.category_array?.map(function (category) {
        const index = Object.keys(CATEGORY_TYPES).findIndex(
          (key) => CATEGORY_TYPES[key].name === category
        );
        return Object.keys(CATEGORY_TYPES)[index];
      }) ?? []
    );
    setGender(
      Object.keys(GENDER_TYPES)[
        Object.keys(GENDER_TYPES).findIndex(
          (type) => GENDER_TYPES[type] === instagramUserData?.gender
        )
      ]
    );
    setAge(instagramUserData?.age);
    setAdType(instagramUserData?.ads_array ?? []);
    setRegion(instagramUserData?.region_array ?? []);
  };

  const categorySubmitValid = useMemo(
    function () {
      return (
        category.length !== categoryRef.current?.length ||
        category
          .map(function (type) {
            return categoryRef.current?.includes(type);
          })
          .filter((item) => item)?.length !== category.length
      );
    },
    [category]
  );

  const specificSubmitValid = useMemo(
    function () {
      return (
        gender !== specificRef.current?.gender ||
        age !== specificRef.current?.age ||
        adType.length !== specificRef.current?.adType.length ||
        adType
          .map(function (type) {
            return specificRef.current?.adType.includes(type);
          })
          .filter((item) => item)?.length !== adType.length ||
        region.length !== specificRef.current?.region.length ||
        region
          .map(function (type) {
            return specificRef.current?.region.includes(type);
          })
          .filter((item) => item)?.length !== region.length
      );
    },
    [gender, age, adType, region]
  );

  const categoryClick = function (item) {
    if (category.includes(item)) {
      const copy = [...category];

      const index = copy.findIndex((category) => category === item);
      copy.splice(index, 1);

      setCategory(copy);
    } else {
      if (category.length < 2) {
        setCategory((current) => [...current, item]);
      }
    }
  };

  const adTypeClick = function (item) {
    if (adType.includes(item)) {
      const copy = [...adType];

      const index = copy.findIndex((adType) => adType === item);
      copy.splice(index, 1);

      setAdType(copy);
    } else {
      setAdType((current) => [...current, item]);
    }
  };

  const regionClick = function (item) {
    if (region.includes(item)) {
      const copy = [...region];

      const index = copy.findIndex((region) => region === item);
      copy.splice(index, 1);

      setRegion(copy);
    } else {
      setRegion((current) => [...current, item]);
    }
  };

  const signoutClick = async function () {
    if (!signoutLoading) {
      setSignoutLoading(true);
      try {
        await api.signout(userData.kakaoUserData.userId, token);

        window.sessionStorage.removeItem("userData");
        window.history.replaceState({}, "", window.location.origin);
        setTimeout(function () {
          handleToast({
            type: "POSITIVE",
            desc: "회원탈퇴가 완료되었습니다.",
          });
        }, 500);
        navigate("/");
      } catch (error) {
        console.log(error);
      } finally {
        setSignoutLoading(false);
      }
    }
  };

  const clickCategoryChange = async function () {
    if (!categoryLoading) {
      setCategoryLoading(true);
      try {
        let query;
        const data = {
          category_array: category.map(function (item) {
            return CATEGORY_TYPES[item].name;
          }),
        };

        if (snsType === "YOUTUBE") {
          query = `youtube_user_id=${youtubeUserData?._id}`;
        }

        if (snsType === "INSTAGRAM") {
          query = `alpha_user_id=${instagramUserData?._id}`;
        }
        await api.setAdPreference(snsType, query, token, data);
        const response = await refreshUserData(token);

        if (snsType === SNS_TYPE.YOUTUBE) {
          setYoutubeUserData(response.youtubeUserData);
          setToYoutubeData(response.youtubeUserData);
        }

        if (snsType === SNS_TYPE.INSTAGRAM) {
          setInstagramUserData(response.instagramUserData);
          setToInstagramData(response.instagramUserData);
        }

        handleToast({
          type: "POSITIVE",
          desc: "계정 카테고리가 저장되었습니다",
        });
      } catch (error) {
        handleToast({
          type: "NEGATIVE",
          desc: "다시 한 번 시도해주세요",
        });
      } finally {
        setCategoryLoading(false);
      }
    }
  };

  const clickAlarmToggle = async function () {
    if (snsType === "YOUTUBE") {
      const current = isAlarm;

      setIsAlarm((current) => !current);
      handleToast({
        type: "POSITIVE",
        desc: "변경되었습니다",
      });

      await api.changeYoutubeAlarm(youtubeUserData._id, token, !current);
      const response = await refreshUserData(token);
      setYoutubeUserData(response.youtubeUserData);
    }

    if (snsType === "INSTAGRAM") {
      const current = isAlarm;

      setIsAlarm((current) => !current);
      handleToast({
        type: "POSITIVE",
        desc: "변경되었습니다",
      });

      await api.changeInstagramAlarm(instagramUserData._id, token, !current);
      const response = await refreshUserData(token);
      setInstagramUserData(response.instagramUserData);
    }
  };

  const clickSpecificChange = async function () {
    if (!specificLoading) {
      setSpecificLoading(true);
      try {
        let query;
        const data = {
          age: age ?? null,
          gender: GENDER_TYPES[gender] ?? null,
          ads_array: adType ?? [],
          region_array: adType.includes("방문 리뷰") ? region : [],
        };

        if (snsType === "YOUTUBE") {
          query = `youtube_user_id=${youtubeUserData?._id}`;
        }

        if (snsType === "INSTAGRAM") {
          query = `alpha_user_id=${instagramUserData?._id}`;
        }
        await api.setAdPreference(snsType, query, token, data);
        const response = await refreshUserData(token);

        if (snsType === SNS_TYPE.YOUTUBE) {
          setYoutubeUserData(response.youtubeUserData);
          setToYoutubeData(response.youtubeUserData);

          handleToast({
            type: "POSITIVE",
            desc: "상세정보가 저장되었습니다",
          });
        }

        if (snsType === SNS_TYPE.INSTAGRAM) {
          setInstagramUserData(response.instagramUserData);
          setToInstagramData(response.instagramUserData);

          handleToast({
            type: "POSITIVE",
            desc: "상세정보가 저장되었습니다",
          });
        }
      } catch (error) {
        handleToast({
          type: "NEGATIVE",
          desc: "다시 한 번 시도해주세요",
        });
      } finally {
        setSpecificLoading(false);
      }
    }
  };

  const clickYoutube = function () {
    if (youtubeUserData) {
      setSnsType(SNS_TYPE.YOUTUBE);
      setToYoutubeData(youtubeUserData);
    } else {
      navigate("/youtube-intent");
    }
  };

  const clickInstagram = function () {
    if (instagramUserData) {
      setSnsType(SNS_TYPE.INSTAGRAM);
      setToInstagramData(instagramUserData);
    } else {
      if (Boolean(instagramUserData?.require_login_again)) {
        const kid = userData?.kakaoUserData?.userId;

        if (!kid) return;

        navigate(`/app/register/reauth?kid=${kid}&type=instagram`);
      } else {
        navigate("/intent");
      }
    }
  };

  useEffect(function () {
    const initialize = function () {
      if (instagramUserData) {
        setToInstagramData(instagramUserData);

        return;
      }

      if (youtubeUserData) {
        setToYoutubeData(youtubeUserData);
      }

      return;
    };

    initialize();
  }, []);

  return (
    <ReportDiv>
      <ReportContainer>
        <ReportWrapper>
          <FlexLarge>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "column nowrap",
              }}
            >
              <div
                style={{
                  minWidth: "132px",
                  width: "132px",
                  height: "30px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  border: "1px solid white",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  background: "white",
                }}
              >
                <div
                  style={
                    snsType === SNS_TYPE.INSTAGRAM
                      ? {
                          minWidth: "65px",
                          minHeight: 28,
                          background: "#4480F7",
                          position: "absolute",
                          left: 0,
                          transition: "0.1s left ease",
                          borderTopLeftRadius: "5px",
                        }
                      : {
                          minWidth: "65px",
                          minHeight: 28,
                          background: "#4480F7",
                          position: "absolute",
                          left: "50%",
                          transition: "0.1s left ease",
                          borderTopRightRadius: "5px",
                        }
                  }
                />
                <p
                  style={{
                    flex: 1,
                    height: "100%",
                    color: snsType === SNS_TYPE.INSTAGRAM ? "white" : "black",
                    fontWeight: snsType === SNS_TYPE.INSTAGRAM ? 700 : 400,
                    fontSize: 12,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={clickInstagram}
                >
                  {"인스타"}
                </p>
                <p
                  style={{
                    flex: 1,
                    height: "100%",
                    color: snsType === SNS_TYPE.YOUTUBE ? "white" : "black",
                    fontWeight: snsType === SNS_TYPE.YOUTUBE ? 700 : 400,
                    fontSize: 12,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={clickYoutube}
                >
                  {"유튜브"}
                </p>
              </div>
              <ProfileSection>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                  }}
                >
                  <ThumbnailImg src={profileImg} />
                  <Name>{name}</Name>
                  {instagramUserData?.require_login_again && (
                    <Button
                      isActive
                      style={{ marginLeft: 8 }}
                      onClick={() => navigate("/app/register")}
                    >
                      재연동하기
                    </Button>
                  )}
                </div>
              </ProfileSection>
            </div>
            <SplitFlex>
              <div
                style={{
                  flex: "1.8",
                  display: "flex",
                  flexFlow: "column nowrap",
                  gap: "20px",
                }}
              >
                <CategorySection style={{ gap: 20 }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        gap: 5,
                      }}
                    >
                      <FieldText>{"계정 카테고리"}</FieldText>
                      <p style={{ fontSize: "14px", color: "#AAAAAA" }}>
                        재클릭으로 선택을 해제할 수 있습니다
                      </p>
                    </div>
                    <Button
                      isActive={categorySubmitValid}
                      disabled={!categorySubmitValid || categoryLoading}
                      onClick={clickCategoryChange}
                      style={{ minWidth: 72, minHeight: 37 }}
                    >
                      {categoryLoading ? <LoadingDiv /> : "저장하기"}
                    </Button>
                  </div>
                  <CategoryWrapper>
                    {Object.keys(CATEGORY_TYPES).map(function (item) {
                      const isActive = category.includes(item);

                      return (
                        <CategoryItem
                          isActive={isActive}
                          onClick={function () {
                            categoryClick(item);
                          }}
                        >
                          <img
                            style={{ maxWidth: 20 }}
                            src={CATEGORY_TYPES[item].image}
                          />
                          <p style={{ fontSize: 10 }}>
                            {CATEGORY_TYPES[item].name}
                          </p>
                        </CategoryItem>
                      );
                    })}
                  </CategoryWrapper>
                </CategorySection>
                <AlarmSection style={{ flexFlow: "column nowrap", gap: 10 }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FieldText>{"리포트 알림 설정"}</FieldText>
                    <SwitchWrapper
                      isActive={isAlarm}
                      onClick={clickAlarmToggle}
                    >
                      <SwitchCircle isActive={isAlarm} />
                    </SwitchWrapper>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      padding: "16px 15px",
                      border: "1px solid #EEEEEE",
                      borderRadius: 5,
                      display: "flex",
                      gap: "13px",
                    }}
                  >
                    <img src={kakaotalk} style={{ width: 38, height: 38 }} />
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        gap: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ color: "#AAAAAA", fontSize: 12 }}>
                        카카오톡 알림
                      </p>
                      <p style={{ color: "#555555", fontSize: 12 }}>
                        매일 밤 10시 분석리포트 수신 여부를 선택할 수 있습니다.
                      </p>
                    </div>
                  </div>
                </AlarmSection>
                <SignOutSection style={{ flexFlow: "column nowrap", gap: 10 }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <FieldText>{"회원탈퇴"}</FieldText>
                    <Button
                      onClick={signoutClick}
                      style={{ minWidth: 96, minHeight: 37 }}
                    >
                      {signoutLoading ? (
                        <LoadingDiv blue={true} />
                      ) : (
                        "회원 탈퇴하기"
                      )}
                    </Button>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      padding: "16px 15px",
                      border: "1px solid #EEEEEE",
                      borderRadius: 5,
                      display: "flex",
                      gap: "13px",
                    }}
                  >
                    <img src={meercat} style={{ width: 38, height: 38 }} />
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        gap: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ color: "#AAAAAA", fontSize: 12 }}>
                        크리에이터리 탈퇴
                      </p>
                      <p style={{ color: "#555555", fontSize: 12 }}>
                        모든 연동 정보를 삭제합니다.
                      </p>
                    </div>
                  </div>
                </SignOutSection>
              </div>
              <SpecificSection
                style={{
                  flex: "1.2",
                  display: "flex",
                  flexFlow: "column nowrap",
                  gap: 24,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column nowrap",
                      gap: 5,
                    }}
                  >
                    <FieldText style={{ fontSize: 18 }}>{"상세정보"}</FieldText>
                    <p style={{ fontSize: "14px", color: "#AAAAAA" }}>
                      {"맞춤 협찬 제안을 위한 정보입니다"}
                    </p>
                  </div>
                  <Button
                    isActive={specificSubmitValid}
                    disabled={!specificSubmitValid}
                    onClick={clickSpecificChange}
                    style={{ minWidth: 72, minHeight: 37 }}
                  >
                    {specificLoading ? <LoadingDiv /> : "저장하기"}
                  </Button>
                </div>
                <SpecificWrapper>
                  <Section2>
                    <FieldText>{"성별"}</FieldText>
                    <SpecificOptionWrapper>
                      {Object.keys(GENDER_TYPES).map(function (item) {
                        const isActive = gender === item;

                        return (
                          <RadioWrapper
                            onClick={function () {
                              setGender(item);
                            }}
                          >
                            <Radio isActive={isActive} />
                            <RadioText isActive={isActive}>
                              {GENDER_TYPES[item]}
                            </RadioText>
                          </RadioWrapper>
                        );
                      })}
                    </SpecificOptionWrapper>
                  </Section2>
                  <Section2>
                    <FieldText>{"연령대"}</FieldText>
                    <SpecificOptionWrapper>
                      {["10대", "20대", "30대", "40대 이상", "비공개"].map(
                        function (item) {
                          const isActive = age === item;

                          return (
                            <RadioWrapper
                              onClick={function () {
                                setAge(item);
                              }}
                            >
                              <Radio isActive={isActive} />
                              <RadioText isActive={isActive}>{item}</RadioText>
                            </RadioWrapper>
                          );
                        }
                      )}
                    </SpecificOptionWrapper>
                  </Section2>
                  {/* <Section2>
                <FieldText>{"자녀"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {["없음", "있음"].map(function (item) {
                    const isActive = child.exist === item;

                    return (
                      <RadioWrapper
                        onClick={function () {
                          setChild({
                            exist: item,
                            age: [],
                          });
                        }}
                      >
                        <Radio isActive={isActive} />
                        <RadioText isActive={isActive}>{item}</RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
                {child?.exist === "있음" && (
                  <div>
                    <p style={{color: '#999999', fontSize: 12}}>*중복 선택 가능</p>
                    <div style={{marginTop: 10}}/>
                    <ChildItemWrapper>
                      {[
                        "1~5세",
                        "6~9세",
                        "10~15세",
                        "16세~19세",
                        "20세 이상",
                      ].map(function (item) {
                        const isActive = child.age.includes(item);

                        return (
                          <ChildItem
                            isActive={isActive}
                            onClick={function () {
                              ageClick(item);
                            }}
                          >
                            {item}
                          </ChildItem>
                        );
                      })}
                    </ChildItemWrapper>
                  </div>
                )}
              </Section2>
              <Section2>
                <FieldText>{"반려동물"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {["없음", "있음"].map(function (item) {
                    const isActive = pet.exist === item;

                    return (
                      <RadioWrapper
                        onClick={function () {
                          setPet({
                            exist: item,
                            type: [],
                          });
                        }}
                      >
                        <Radio isActive={isActive} />
                        <RadioText isActive={isActive}>{item}</RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
                {pet?.exist === "있음" && (
                  <div>
                    <p style={{color: '#999999', fontSize: 12}}>*중복 선택 가능</p>
                    <div style={{marginTop: 10}}/>
                    <ChildItemWrapper>
                      {[
                        "강아지",
                        "고양이",
                        "기타",
                      ].map(function (item) {
                        const isActive = pet.type.includes(item);

                        return (
                          <ChildItem
                            isActive={isActive}
                            onClick={function () {
                                petClick(item)
                            }}
                          >
                            {item}
                          </ChildItem>
                        );
                      })}
                    </ChildItemWrapper>
                  </div>
                )}
              </Section2> */}
                  <Section2>
                    <FieldText>{"진행 가능한 광고 유형"}</FieldText>
                    <SpecificOptionWrapper
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        gap: "20px 100px",
                        marginBottom: 10,
                      }}
                    >
                      {["제품 협찬", "앱/서비스 리뷰", "방문 리뷰"].map(
                        function (item) {
                          const isActive = adType.includes(item);

                          return (
                            <RadioWrapper
                              onClick={function () {
                                adTypeClick(item);
                              }}
                            >
                              <Checkbox isActive={isActive}>
                                <img style={{ zIndex: 1 }} src={check} />
                              </Checkbox>
                              <RadioText isActive={isActive}>{item}</RadioText>
                            </RadioWrapper>
                          );
                        }
                      )}
                    </SpecificOptionWrapper>
                    {adType.includes("방문 리뷰") && (
                      <>
                        <FieldText>
                          {"활동 가능 지역"}{" "}
                          <span style={{ color: "#999999", fontSize: 12 }}>
                            *중복 선택 가능
                          </span>
                        </FieldText>
                        <div>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#555555",
                            }}
                          >
                            다른 지역
                          </p>
                          <div style={{ marginTop: 15 }} />
                          <ChildItemWrapper>
                            {[
                              "서울/수도권",
                              "인천",
                              "부산",
                              "대전",
                              "대구",
                              "울산",
                              "세종",
                              "강원도",
                              "충청북도",
                              "충청남도",
                              "전라북도",
                              "전라남도",
                              "경상북도",
                              "경상남도",
                              "제주도",
                            ].map(function (item) {
                              const isActive = region.includes(item);
                              return (
                                <RegionItem
                                  isActive={isActive}
                                  onClick={function () {
                                    regionClick(item);
                                  }}
                                >
                                  {item}
                                </RegionItem>
                              );
                            })}
                          </ChildItemWrapper>
                        </div>
                      </>
                    )}
                  </Section2>
                </SpecificWrapper>
              </SpecificSection>
            </SplitFlex>
          </FlexLarge>
          <VerticalLine />
          <FlexMedium></FlexMedium>
        </ReportWrapper>
      </ReportContainer>
    </ReportDiv>
  );
}

export default Settings;

const SpecificOptionWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 20px 140px;

  @media (max-width: 590px) {
    gap: 20px 100px;
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  background: #f4f4f4;
  position: relative;
`;

const ReportContainer = styled.div`
  background: #f4f4f4;
  width: 100%;
  min-height: calc(100vh - 80px);

  @media (max-width: 590px) {
    margin-top: 50px;
    min-height: calc(100vh - 60px);
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #e6e6e6;
  margin: 0 30px;

  @media (max-width: 1860px) {
    display: none;
  }
`;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;

  @media (max-width: 590px) {
    padding: 0 20px 100px 20px;
  }
`;

const FlexLarge = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2%;
  gap: 20px;

  margin-top: 37px;
  margin-bottom: 200px;

  @media (max-width: 590px) {
    gap: 20;
    margin-top: 30px;
  }
`;

const FlexMedium = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 150px;

  @media (max-width: 1860px) {
    width: 100%;
    max-width: 1066px;
    margin-top: 0;
    flex-direction: row;
    gap: 2%;
  }

  @media (max-width: 590px) {
    margin-bottom: 30px;
    flex-direction: column;
    gap: 0;
    width: 100%;
    min-width: 320px;
  }
`;

const Section = styled.section`
  background: white;
  border-radius: 5px;

  padding: 20px 20px;

  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.05);
`;

const ProfileSection = styled(Section)`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-left-radius: 0px;
`;

const CategorySection = styled(Section)`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
`;

const AlarmSection = styled(Section)`
  width: 100%;

  display: flex;
`;

const SignOutSection = styled(Section)`
  width: 100%;

  display: flex;
`;

const SplitFlex = styled.div`
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  gap: 20px;

  @media (max-width: 590px) {
    flex-flow: column nowrap;
  }
`;

const SpecificSection = styled(Section)`
  width: 100%;
  height: max-content;

  display: flex;
`;

const FieldText = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: black;

  word-breaking: break-all;
`;

const ThumbnailImg = styled.img`
  width: 48px;
  height: 48px;

  margin-right: 12px;

  border-radius: 50%;
`;

const SwitchWrapper = styled.div`
  min-width: 38px;
  height: 20px;

  border-radius: 10px;
  background: ${(props) => (props.isActive ? "#4480F7" : "#E6E6E6")};
  box-shadow: ${(props) =>
    props.isActive
      ? "inset 0px 0px 4px rgba(0, 0, 0, 0.25)"
      : "inset 0px 0px 4px rgba(0, 0, 0, 0.25)"};

  position: relative;

  cursor: pointer;

  transition: all 0.3s linear;
`;

const SwitchCircle = styled.div`
  min-width: 16px;
  min-height: 16px;

  border-radius: 50%;
  background: white;

  position: absolute;
  top: 2px;

  ${({ isActive }) =>
    isActive
      ? css`
          left: calc(100% - 18px);
        `
      : css`
          left: 2px;
        `}

  transition: all 0.1s linear;
`;

const Button = styled.button`
  min-width: max-content;
  padding: 10px 14px;

  font-size: 12px;
  color: #aaaaaa;

  border: 1px solid #eeeeee;
  border-radius: 5px;

  ${({ isActive }) =>
    isActive &&
    css`
      color: white;

      border: 1px solid #4480f7;
      background: #4480f7;
    `}
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: black;

  word-breaking: break-all;

  @media (max-width: 590px) {
    font-size: 16px;
  }
`;

// const CategoryWrapper = styled.section`
// width: 100%;

// display: grid;
// grid-template-columns: repeat(auto-fit, 70px);
// grid-row-gap: 12px;
// grid-column-gap: 12px;
// justify-content: flex-start;
// `

// const CategoryItem = styled.div`
//     min-width: 70px;
//     min-height: 70px;

//     border-radius: 5px;

//     display: flex;
//     flex-flow: column nowrap;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;

//     cursor: pointer;

//     ${({isActive}) => isActive ?
//         css`
//             background: rgba(68, 128, 247, 0.1);
//             border: 1px solid #4480F7;
//         `
//         :
//         css`
//             border: 1px solid #F0F0F0;
//         `
//     }
// `

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px) {
    height: auto;
    margin-top: 60px;
  }
`;

const ModalContainer = styled.div`
  width: 416px;
  height: 663px;
  padding: 60px 0;
  background: #ffffff;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 590px) {
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 733px;
    border-radius: 0px;
    padding: 40px 0;
  }
`;

const HeaderText = styled.h1`
  width: 320px;

  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
`;

const Blue = styled.span`
  color: #4480f7;
`;

const CategoryWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  justify-content: center;

  @media (max-width: 590px) {
    grid-template-columns: repeat(auto-fit, 65px);
    grid-row-gap: 6px;
    grid-column-gap: 6px;
  }
`;

const CategoryItem = styled.div`
  min-width: 70px;
  min-height: 70px;

  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
        `}

  @media (max-width: 590px) {
    min-width: 65px;
    width: 65px;
    min-height: 65px;
  }
`;

const Section2 = styled.section`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
`;

const SpecificFieldText = styled.p`
  width: 100%;

  font-weight: 700;
  font-size: 14px;
`;

const SpecificWrapper = styled.section`
  width: 100%;
  height: max-content;

  display: flex;
  flex-flow: column nowrap;
  gap: 60px;
`;

const RadioWrapper = styled.div`
  min-width: 60px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const Radio = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 1px solid #e6e6e6;
  background: transparent;

  ${({ isActive }) =>
    isActive &&
    css`
      background: radial-gradient(
        circle at center,
        #4480f7 35%,
        transparent 40%
      );
    `}
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;

  border: 1px solid #e6e6e6;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #4480f7;
    `}
`;

const RadioText = styled.p`
  font-size: 16px;
  font-weight: 400;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #4480f7;
    `}
`;

const ChildItemWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-row-gap: 10px;
  grid-column-gap: 12px;
  justify-content: flex-start;
`;

const ChildItem = styled.div`
  min-width: 90px;
  min-height: 90px;

  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
          color: #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
          color: #aaaaaa;
        `}
`;

const RegionItem = styled.div`
  min-width: 90px;
  min-height: 55px;

  border-radius: 5px;

  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
          color: #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
          color: #aaaaaa;
        `}
`;
