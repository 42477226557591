import styled from "styled-components";

export const Row = styled.div<{ top?: number; left?: number; gap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};
  gap: ${(props) => `${props.gap}px`};
`;

export const Column = styled.div<{ top?: number; left?: number; gap?: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};
  gap: ${(props) => `${props.gap}px`};
`;

export const FlexBox = styled.div`
  display: flex;
  flex: 1;
`;

export const Image = styled.img<{
  top?: number;
  left?: number;
  gap?: number;
  bottom?: number;
  right?: number;
  borderRadius?: number | string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
  margin-left: ${(props) => `${props.left}px`};
  margin-right: ${(props) => `${props.right}px`};
  border-radius: ${(props) => props.borderRadius};
`;

export const H1 = styled.p<{ top?: number; left?: number }>`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 36px;
    line-height: 46px;
  }
`;

export const H2 = styled.p<{ top?: number; left?: number }>`
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const H3 = styled.p<{ top?: number; left?: number }>`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const H4 = styled.p<{ top?: number; left?: number }>`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const H5 = styled.p<{ top?: number; left?: number }>`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const H6 = styled.p<{ top?: number; left?: number; bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 22px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const H7 = styled.p<{ top?: number; left?: number; bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 20px;
  line-height: 26px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const T1 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: ${(props) => (props.static ? "18px" : "16px")};
    line-height: ${(props) => (props.static ? "24px" : "20px")};
  }
`;

export const T2 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: ${(props) => (props.static ? "16px" : "14px")};
    line-height: ${(props) => (props.static ? "20px" : "18px")};
  }
`;

export const B1 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: ${(props) => (props.static ? "14px" : "12px")};
    line-height: ${(props) => (props.static ? "18px" : "16px")};
  }
`;

export const B2 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: ${(props) => (props.static ? "12px" : "11px")};
    line-height: ${(props) => (props.static ? "16px" : "14px")};
  }
`;

export const E1 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 11px;
  line-height: 14px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  @media (max-width: 590px) {
    font-size: ${(props) => (props.static ? "11px" : "10px")};
    line-height: ${(props) => (props.static ? "14px" : "14px")};
  }
`;

export const E2 = styled.p<{
  top?: number;
  left?: number;
  bold?: boolean;
  static?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.color};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};
`;

export const MainBlue = styled.span`
  color: #4480f7;
`;

export const ActionButton = styled.button<{
  top?: number;
  width?: number | string;
  height?: number | string;
}>`
  width: ${(props) => props.width || "320px"};
  height: ${(props) => props.height || "60px"};
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  margin-top: ${(props) => `${props.top}px`};

  &:hover,
  &:active {
    background: #ecf2fe;
    border: 1px solid #4480f7;
  }
`;

export const NextButton = styled.button<{
  top?: number;
  width?: number | string;
  height?: number | string;
  active?: boolean;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : "320px")};
  height: ${(props) => (props.height ? `${props.height}px` : "60px")};
  background: ${(props) => (props.active ? "#4480F7" : "#E6E6E6")};
  border-radius: 5px;
  color: #ffffff;
  margin-top: ${(props) => `${props.top}px`};

  &:hover,
  &:active {
    background: #3470e7;
  }
`;

export const LoadingSkeleton = styled.div<{
  top?: number;
  left?: number;
  width?: number | string;
  height?: number | string;
  active?: boolean;
}>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  margin-top: ${(props) => `${props.top}px`};
  margin-left: ${(props) => `${props.left}px`};

  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

export const Button = styled.button<{ full?: boolean }>`
  width: ${(props) => (props.full ? "100%" : "320px")};

  margin-top: 20px;
  padding: 20px 0px;

  font-size: 16px;
  color: white;

  background: #4480f7;
  border-radius: 5px;

  &:active {
    background: #3470e7;
    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    background: #e6e6e6;
  }
`;

export const Input = styled.input<{ full?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.full ? "100%" : "auto")};
  height: 48px;
  padding: 0 8px;

  border-radius: 8px;

  font-size: 1.125rem;

  border: solid 1px #e6e6e6;

  &:focus {
    border: solid 1px #3470e7;
  }

  &:valid {
    border: solid 1px #3470e7;
  }

  &[type="file"] {
    border: none;
  }
`;
