//React
import {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  createContext,
} from "react";
import styled from "styled-components";

//Components
import Skeleton from "pages/Report/Youtube/states/Skeleton";
import Connecting from "pages/Report/Youtube/states/Connecting";
import ChannelReport from "pages/Report/Youtube/ChannelReport";

//sampleData
//import sampleData from './sampleData.json';

export const AnalysisContext = createContext();

const Youtube = function ({ userData, youtubeData, change }) {
  const [youtubeReportState, setYoutubeReportState] = useState("Loading");
  const [youtubeUserData, setYoutubeUserData] = useState();
  const [youtubeReportData, setYoutubeReportData] = useState([]);
  const [youtubeRevenueData, setYoutubeRevenueData] = useState([]);
  const [youtubeSubscriberData, setYoutubeSubscriberData] = useState([]);

  useEffect(
    function () {
      const initialize = function(){          
          if(userData){
            setYoutubeUserData(userData)
          }

          if(youtubeData.youtubeData.length){
  
            setYoutubeReportData(youtubeData.youtubeData);
          }
  
          if(youtubeData.revenueData.length){
  
            setYoutubeRevenueData(youtubeData.revenueData);
          }
  
          if(youtubeData.subscriberData.length){
  
            setYoutubeSubscriberData(youtubeData.subscriberData);
          }
  
          if(youtubeData.youtubeData.length || youtubeData.revenueData.length || youtubeData.subscriberData.length){
  
            setYoutubeReportState('ChannelReport')
            return
          }
  
          setYoutubeReportState('Connecting')
      }

      if(youtubeData){
        initialize()
      }
    },
    [youtubeData]
  );

  return (
    <>
      {
        {
          //로딩
          Loading: <Skeleton />,

          //SNS 연결 중
          Connecting: <Connecting change={change} />,

          //유튜브 채널 리포트
          ChannelReport: (
            
            <ChannelReport
              change={change}
              youtubeUserData={youtubeUserData}
              youtubeReportData={youtubeReportData}
              youtubeRevenueData={youtubeRevenueData}
              youtubeSubscriberData={youtubeSubscriberData}
            />
          ),
        }[youtubeReportState]
      }
    </>
  );
};

export default Youtube;

const AnalysisContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 80px);

  background: #f4f4f4;
  overflow-y: scroll;

  @media (max-width: 590px) {
    width: 100%;

    margin-top: 60px;
    margin-bottom: 70px;
    margin-left: 0px;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  position: relative;
`;
