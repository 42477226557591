//React
import React from 'react';
import styled from 'styled-components';

//Css
import { 
  T1,
  B1,
} from 'styles/DesignSystem';

//Assets
import meercatError from 'assets/images/meercat-error.png';

function Error({errorCode}) {

  return (
    <LoginErrorDiv>
      <MeercatErrorImage src={meercatError} />
      <T1 static bold top={20}>로그인에 실패했습니다</T1>
      <B1 static color='#AAAAAA' top={8}>사용자가 많거나 일시적인 시스템 오류로 인해</B1>
      <B1 static color='#AAAAAA' top={5}>페이지를 불러올 수 없습니다{errorCode && ` (${errorCode})`}</B1>
      <BackLink static onClick={function(){ window.history.back(); }}>이전으로</BackLink>
    </LoginErrorDiv>
  );

}

export default Error;

const LoginErrorDiv = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MeercatErrorImage = styled.img`
  width: 138px;
`

const BackLink = styled(B1)`
  margin-top: 20px;
  color: #4480F7;
  border-bottom: 1px solid #4480F7;
  cursor: pointer;
`