import axios from "axios";
import { UploadData } from "./Submit";
import { Form } from "react-router-dom";

const commonAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001"
      : process.env.REACT_APP_API_HOST,
});

export interface ParticipationSettings {
  instagram_id?: boolean;
  instagram_follower?: boolean;
  youtube_channel_id?: boolean;
  youtube_subscriber?: boolean;
  user_name?: boolean;
  is_guide?: boolean;
  phone?: boolean;
  address?: boolean;
  visit_schedule?: boolean;
  etc?: string;
  secondary_utilization?: boolean,
  inform_payment?: boolean,
}

export interface UploadSetting {
  account: boolean;
  contents_url: boolean;
  identification: boolean;
  etc: string;
}

export type StandardResponse<T> = {
  error: any;
  response: T;
  meta?: any;
};

export interface Campaign {
  company: string;
  created_at: string;
  end_date: string;
  img_url: string;
  participants?: any[];
  participation_setting?: ParticipationSettings;
  product: string;
  start_date: string;
  title: string;
  updated_at: string;
  upload_setting?: UploadSetting;
  url: string;
  version: string;
  __v: number;
  _id: string;
}

const getCampaignDataById = async function (
  campaignId: string,
  accessToken: string
): Promise<StandardResponse<Campaign>> {
  const { data } = await commonAxios.get(`/api/v1/campaign/${campaignId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const getUserCampaignData = async function (
  campaignId: string,
  userId: string,
  accessToken: string
): Promise<StandardResponse<Campaign>> {
  const { data } = await commonAxios.get(`/campaigns/${campaignId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const submitApplication = async function (
  campaignId: string,
  accessToken: string,
  applicationData: {
    instagram_id?: string;
    instagram_follower?: string;
    youtube_channel_id?: string;
    youtube_subscriber?: string;
    user_name?: string;
    // is_guide?: boolean;
    phone?: string;
    address?: string;
    sub_address?: string;
    visit_schedule?: string;
    etc?: string;
  }
) {
  try {
    const { data } = await commonAxios.patch(
      `/api/v1/campaign/${campaignId}/participation`,
      {
        ...applicationData,
        is_guide: true,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const getCampaignParticipants = async function (campaignId: string) {
  const { data } = await commonAxios.get(
    `/api/v1/campaign/${campaignId}/participation`
  );

  return data;
};

const uploadCampaignContent = async function (
  campaignId: string,
  uploadData: UploadData
) {
  const accessToken = JSON.parse(
    window.sessionStorage.getItem("userData")
  )?.accessToken;
  const formData = new FormData();

  if (uploadData.identification && uploadData.identification2) {
    formData.append(
      "identification",
      uploadData.identification + uploadData.identification2
    );
  }

  if (uploadData.identification_image) {
    formData.append("identification_image", uploadData.identification_image);
  }

  if (uploadData.name) {
    formData.append("name", uploadData.name);
  }
  if (uploadData.account) {
    formData.append("account", uploadData.account);
  }

  if (uploadData.bank) {
    formData.append("bank", uploadData.bank);
  }

  if (uploadData.account_image) {
    formData.append("account_image", uploadData.account_image);
  }

  if (uploadData.contents_url) {
    formData.append("content_url", uploadData.contents_url);
  }

  if (uploadData.etc) {
    formData.append("upload_etc", uploadData.etc);
  }

  const { data } = await commonAxios.patch(
    `/api/v1/campaign/${campaignId}/content`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
};

export {
  getUserCampaignData,
  getCampaignDataById,
  submitApplication,
  getCampaignParticipants,
  uploadCampaignContent,
};
