//React
import { useState } from 'react';
import styled from 'styled-components'

//Css
import {
    H2,
    H7
} from 'styles/DesignSystem';

function YoutubeValueCalculator() {

    const [inputValue, setInputValue] = useState();
    const [expectedImpressions, setExpectedImpressions] = useState('');
    const [expectedReach, setExpectedReach] = useState('');

    function calculateReach(follower) {

        let result = '';

        if (!follower) {
            result = '';

        } else if (follower < 500) {
            result = '500명 이하의 팔로워에 대해서는 예측이 불가능합니다.';

        } else if (follower < 610) {
            result = '예상 도달 ' + Math.round( 0.78567863*follower - 96.1338 ) + '회 / 팔로워 대비 ' + (Math.round( 0.8541476*follower - 133.17667 )/follower*100).toFixed(1) + '%'

        } else if (follower < 720) {
            result = '예상 도달 ' + Math.round( 0.31050858*follower + 191.1930 ) + '회 / 팔로워 대비 ' + (Math.round( 0.31050858*follower + 191.1930 )/follower*100).toFixed(1) + '%'

        } else if (follower < 830) {
            result = '예상 도달 ' + Math.round( 0.93646718*follower - 264.9462 ) + '회 / 팔로워 대비 ' + (Math.round( 0.93646718*follower - 264.9462 )/follower*100).toFixed(1) + '%'

        } else if (follower < 1000) {
            result = '예상 도달 ' + Math.round( 0.79532163*follower - 148.3216 ) + '회 / 팔로워 대비 ' + (Math.round( 0.79532163*follower - 148.3216 )/follower*100).toFixed(1) + '%'

        } else if (follower < 2000) {
            result = '예상 도달 ' + Math.round( 0.08890315*follower + 558.1296 ) + '회 / 팔로워 대비 ' + (Math.round( 0.08890315*follower + 558.1296 )/follower*100).toFixed(1) + '%'

        } else if (follower < 3000) {
            result = '예상 도달 ' + Math.round( 0.09306742*follower + 769.3409 ) + '회 / 팔로워 대비 ' + (Math.round( 0.09306742*follower + 769.3409 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 4000) {
            result = '예상 도달 ' + Math.round( 0.12221897*follower + 777.8719 ) + '회 / 팔로워 대비 ' + (Math.round( 0.12221897*follower + 777.8719 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 5000) {
            result = '예상 도달 ' + Math.round( -0.11563043*follower + 1923.1101 ) + '회 / 팔로워 대비 ' + (Math.round( -0.11563043*follower + 1923.1101 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 6000) {
            result = '예상 도달 ' + Math.round( 0.18788421*follower + 433.2933 ) + '회 / 팔로워 대비 ' + (Math.round( 0.18788421*follower + 433.2933 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 7000) {
            result = '예상 도달 ' + Math.round( 0.16841624*follower + 411.0722 ) + '회 / 팔로워 대비 ' + (Math.round( 0.16841624*follower + 411.0722 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 8000) {
            result = '예상 도달 ' + Math.round( -0.33162682*follower + 3899.2260 ) + '회 / 팔로워 대비 ' + (Math.round( -0.33162682*follower + 3899.2260 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 9000) {
            result = '예상 도달 ' + Math.round( 1.19438408*follower - 8222.5656 ) + '회 / 팔로워 대비 ' + (Math.round( 1.19438408*follower - 8222.5656 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 10000) {
            result = '예상 도달 ' + Math.round( -0.23349527*follower + 4873.6696 ) + '회 / 팔로워 대비 ' + (Math.round( -0.23349527*follower + 4873.6696 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 40000) {
            result = '예상 도달 ' + Math.round( 0.08810614*follower + 1460.922 ) + '회 / 팔로워 대비 ' + (Math.round( 0.08810614*follower + 1460.922 )/follower*100).toFixed(1) + '%'
        
        } else if (follower >= 40000) {
            result = '40000명 이상의 팔로워에 대해서는 예측이 불가능합니다.';
        }

        setExpectedReach(result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }

    function calculateImpressions(follower) {

        let result = '';

        if (!follower) {
            result = '';

        } else if (follower < 500) {
            result = '500명 이하의 팔로워에 대해서는 예측이 불가능합니다.';

        } else if (follower < 610) {
            result = '예상 노출 ' + Math.round( 1.0719987*follower - 154.0706 ) + '회 / 팔로워 대비 ' + (Math.round( 1.0719987*follower - 154.0706 )/follower*100).toFixed(1) + '%'

        } else if (follower < 720) {
            result = '예상 노출 ' + Math.round( 0.3052700*follower + 304.0080 ) + '회 / 팔로워 대비 ' + (Math.round( 0.3052700*follower + 304.0080 )/follower*100).toFixed(1) + '%'

        } else if (follower < 830) {
            result = '예상 노출 ' + Math.round( 1.0863888*follower - 249.0641 ) + '회 / 팔로워 대비 ' + (Math.round( 1.0863888*follower - 249.0641 )/follower*100).toFixed(1) + '%'

        } else if (follower < 1000) {
            result = '예상 노출 ' + Math.round( 0.91812865*follower - 109.1286 ) + '회 / 팔로워 대비 ' + (Math.round( 0.91812865*follower - 109.1286 )/follower*100).toFixed(1) + '%'

        } else if (follower < 2000) {
            result = '예상 노출 ' + Math.round( 0.09867440*follower + 710.7916 ) + '회 / 팔로워 대비 ' + (Math.round( 0.09867440*follower + 710.7916 )/follower*100).toFixed(1) + '%'

        } else if (follower < 3000) {
            result = '예상 노출 ' + Math.round( 0.09188971*follower + 993.5768 ) + '회 / 팔로워 대비 ' + (Math.round( 0.09188971*follower + 993.5768 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 4000) {
            result = '예상 노출 ' + Math.round( 0.13371353*follower + 987.9565 ) + '회 / 팔로워 대비 ' + (Math.round( 0.13371353*follower + 987.9565 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 5000) {
            result = '예상 노출 ' + Math.round( -0.08674545*follower + 2085.1357 ) + '회 / 팔로워 대비 ' + (Math.round( -0.08674545*follower + 2085.1357 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 6000) {
            result = '예상 노출 ' + Math.round( 0.20774318*follower + 619.7395 ) + '회 / 팔로워 대비 ' + (Math.round( 0.20774318*follower + 619.7395 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 7000) {
            result = '예상 노출 ' + Math.round( 0.24270717*follower + 220.3500 ) + '회 / 팔로워 대비 ' + (Math.round( 0.24270717*follower + 220.3500 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 8000) {
            result = '예상 노출 ' + Math.round( -0.36491320*follower + 4448.0482 ) + '회 / 팔로워 대비 ' + (Math.round( -0.36491320*follower + 4448.0482 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 9000) {
            result = '예상 노출 ' + Math.round( 1.40498863*follower - 9667.2322 ) + '회 / 팔로워 대비 ' + (Math.round( 1.40498863*follower - 9667.2322 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 10000) {
            result = '예상 노출 ' + Math.round( -0.23487510*follower + 5399.4198 ) + '회 / 팔로워 대비 ' + (Math.round( -0.23487510*follower + 5399.4198 )/follower*100).toFixed(1) + '%'
        
        } else if (follower < 40000) {
            result = '예상 노출 ' + Math.round( 0.10750325*follower + 1730.8075 ) + '회 / 팔로워 대비 ' + (Math.round( 0.10750325*follower + 1730.8075 )/follower*100).toFixed(1) + '%'
        
        } else if (follower >= 40000) {
            result = '40000명 이상의 팔로워에 대해서는 예측이 불가능합니다.';
        }

        setExpectedImpressions(result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }

    return (
        <Container>

            <H2 top={60}>Impressions 예측 계산기</H2>
            <LinkInput
                type="text"
                placeholder="팔로워 수를 입력하세요"
                onFocus={(e) => e.target.placeholder = ''}
                onBlur={(e) => e.target.placeholder = "팔로워 수를 입력하세요"}
                onKeyPress={(event) => {

                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                    if (event.target.value.length > 4) {
                        event.preventDefault();
                    }
                    if (event.key==='Enter') {
                        calculateReach(inputValue);
                        calculateImpressions(inputValue);
                    }

                }}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
            />
            <LinkButton
                onClick={function () {
                    calculateReach(inputValue);
                    calculateImpressions(inputValue);
                }}
            >
                예상 노출 확인하기
            </LinkButton>

            <H7 top={50}>
                {expectedReach}
            </H7>
            <H7 top={4}>
                {expectedImpressions}
            </H7>

        </Container>
    );
}

export default YoutubeValueCalculator;

const Container = styled.div`
  width: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const LinkInput = styled.input`
  width: 420px;
  height: 60px;
  margin-top: 20px;
  padding: 0 12px;
  background: #EDEFFB;
  border: 1px solid #F6F6F6;
  border-radius: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  text-align: center;

  ::placeholder {
    color: #6A74B0;
  }

  &:focus {
    border: 2px solid #5465FF;
  }

  @media (max-width: 590px){
    width: 320px;
  }
`;

const LinkButton = styled.button`
  width: 420px;
  height: 60px;
  margin-top: 10px;
  background: #5465FF;
  border-radius: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;

  &:hover,
  &:active{
    background: #4251DC;
  }

  @media (max-width: 590px){
    width: 320px;
  }
`;