//API
import axios from 'axios';
import Axios from "axios";

const meercatAxios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: "json",
  withCredentials: false,
  timeout: 10000,
})

export const getInstagramReports = async function (token) {

    try {

        let options = {
            url: `${process.env.REACT_APP_API_HOST}/api/v1/webreport`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios(options);

        return response;

    } catch (error) {
        
        //401 Unauthorized
        throw error.response.status;

    }

}

export const analysisApi = {
    setAnalysisAlarm(avatarId, token, type){
  
      return{
        async promise(){
          try{
            const options = {
              url: `/api/v1/report-users/alarm?avatar_id=${avatarId}`,
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${token}`
              },
              data: {
                is_active: type
              }
            }
  
            const response = await meercatAxios(options);
  
            if(response){
  
              return response
            }
  
          }catch(error){
  
            throw error.response.status
          }
        }
      }
    },
    
    activateService(avatarId, token){
  
      return {
        async promise(){
          try{
            const options = {
              url: `/api/v1/report-users`,
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              data: {
                "avatar_id": avatarId
              }
            }
  
            const response = await meercatAxios(options);
  
            if(response){
  
              return response
            }
  
          }catch(error){
  
            throw error.response.status
          }
        }
      }
    },
  
    activateYoutubeReport(avatarId, token, reportUserId){
      return {
        async promise(){
          try{
            const options = {
              url: `/api/v1/youtube/users`,
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              data: {
                "avatar_id": avatarId,
                "report_user_id": reportUserId
              }
            }
  
            const response = await meercatAxios(options);
  
            if(response){
  
              return response
            }
  
          }catch(error){
  
            throw error.response.status
          }
        }
      }
    },
  
    getYoutubeUserData(avatarId, token){
      return {
        async promise(){
          try{
            const options = {
              url: `/api/v1/report-users/youtube-user/?avatar_id=${avatarId}`,
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
  
            const response = await meercatAxios(options);
  
            if(response){
  
              return response
            }
  
          }catch(error){
  
            throw error.response.data.error;
          }
        }
      }
    },
  
    getYoutubeReportData(token, youtubeUserId){
      return {
        async promise(){
          try{
            const options = {
              url: `/api/v1/youtube/reports/${youtubeUserId}`,
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
  
            const response = await meercatAxios(options);
  
            if(response){
  
              return response
            }
  
          }catch(error){
  
            throw error.response.data.error;
          }
        }
      }
    }
}

const testId = "64127dcdfa5e28248a7b9bd8"

export const youtubeApi = {
  
  getYoutubeReports: async function(userId, token){
    try{
      const response = await meercatAxios.get(`/api/v1/youtube/reports/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return response.data.response
    }catch(error){
      throw error
    }
  },
}

export const api = {
  setAdPreference: async function(snsType, query, token, data){
    try{
      let sns = snsType === 'YOUTUBE' ? 'google' : 'facebook'
      const options = {
        url: `/api/v1/${sns}/category?${query}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {...data}
      }
      const response = await meercatAxios(options)

      return response

    }catch(error){
      throw error
    }
  },
  
  getUserData: async function(token){
    try{
      const options = {
        url: '/api/v1/kakao',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
      const response = await meercatAxios(options)
      
      return response
    }catch(error){
      throw error
    }
  },
  
  signout: async function(userId, token){
    try{
      const options = {
        url: '/api/v1/kakao',
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          kakao_account_id: userId
        }
      }
      const response = await meercatAxios(options)

      return response
    }catch(error){
      throw error
    }
  },
  changeYoutubeAlarm: async function(youtube_user_id, token, is_active){
    try{
      const options = {
        url: `/api/v1/youtube/alarm?youtube_user_id=${youtube_user_id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          is_active: is_active
        }
      }
      const response = await meercatAxios(options)

      return response

    }catch(error){
      throw error
    }
  },
  changeInstagramAlarm: async function(instagram_user_id, token, is_active){
    try{
      const options = {
        url: `/api/v1/alpha/user/alarm?alpha_user_id=${instagram_user_id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          is_active: is_active
        }
      }
      const response = await meercatAxios(options)

      return response

    }catch(error){
      throw error
    }
  },
}