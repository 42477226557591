//React
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'

//Css
import {
  Image,
  H5,
  T2,
  ActionButton
} from 'styles/DesignSystem';

//Assets
import meercat from 'assets/images/meercat-link-fail.png';

function LinkFail() {

  const type = new URL(window.location.href).searchParams.get('type');

  const navigate = useNavigate();

  const failMessage = {
    'facebookRegisterFail':
      <>
        <T2 top={20} color='#555555'>페이스북 계정 연동을 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'facebookRegisterInfoFail':
      <>
        <T2 top={20} color='#555555'>페이스북 정보 불러오기를 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'facebookCallbackFail':
      <>
        <T2 top={20} color='#555555'>페이스북 토큰 가져오기를 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'kakaoRegisterFail':
      <>
        <T2 top={20} color='#555555'>카카오 계정 연동을 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'kakaoSignUpFail':
      <>
        <T2 top={20} color='#555555'>카카오 계정 가입을 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'notMatch':
      <>
        <T2 top={20} color='#555555'>기존에 가입했던 계정의 휴대폰 번호와</T2>
        <T2 top={5} color='#555555'>카카오톡 휴대폰 번호가 불일치합니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'noChannel':
      <>
        <T2 top={20} color='#555555'>유튜브 채널이 없는 구글 계정으로</T2>
        <T2 top={5} color='#555555'>연동을 시도하였습니다.</T2>
        <T2 top={5} color='#555555'>구글 계정을 확인하신 다음 재가입해주세요.</T2>
      </>,

    'googleRegisterFail':
      <>
        <T2 top={20} color='#555555'>구글 계정 연동을 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'kakaoPhoneUpdateFail':
      <>
        <T2 top={20} color='#555555'>휴대폰 번호 인증에 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'integrateYoutubeFail':
      <>
        <T2 top={20} color='#555555'>유튜브 통합 연동에 실패했습니다.</T2>
        <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
      </>,

    'creatorlyGoogleRegisterFail':
    <>
      <T2 top={20} color='#555555'>유튜브 통합 연동 확인에 실패했습니다.</T2>
      <T2 top={5} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>
    </>,
  }

  return (
    <Container>
      <ModalContainer>

        <H5>SNS 연동 중 오류가 발생했어요</H5>
        {failMessage[type] ?? <T2 top={20} color='#555555'>아래 버튼을 통해 문의해주세요.</T2>}

        <Image src={meercat} width={122} height={144} top={40} />

        <ActionButton top={40} onClick={function () { navigate('/app/register'); }}>
          <T2>회원가입 다시 진행하기</T2>
        </ActionButton>
        <ActionButton top={10} onClick={function () { window.ChannelIO('showMessenger'); }}>
          <T2>데이터 분석팀에게 SOS</T2>
        </ActionButton>

      </ModalContainer>
    </Container>
  );
}

export default LinkFail;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px){
    height: auto;
    margin-top: 60px;
  }
`

const ModalContainer = styled.div`
  width: 416px;
  height: 600px;
  padding: 60px 0;
  background: #FFFFFF;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px){
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 595px;
    border-radius: 0px;
    padding: 40px 0;
  }
`