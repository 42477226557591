//React
import styled from "styled-components";

//Design System
import { Row, Column, FlexBox, H7, B2 } from "styles/DesignSystem";

//Assets
import updateLogo from "assets/Application/common/analysis-icon.svg";

import defaultProfile from "assets/Application/Analysis/icons/default-profile.svg";
// import Button from "../../../../../../components/Button";

const handleImgError = (error) => {
  error.target.src = defaultProfile;
};

const handleProfileOnclick = function (user_name) {
  window.open(`https://m.youtube.com/channel/${user_name}`, "_blank");
};

function YoutubeProfile({ userData, year, month, day }) {
  return (
    <ProfileDiv>
      <ProfileColumn>
        <Row>
          <Row
            onClick={() => {
              handleProfileOnclick(userData?.channel_id);
            }}
            style={{ cursor: "pointer" }}
          >
            <ProfileImage
              src={userData?.thumbnail_url}
              onError={handleImgError}
            />
            <UserName>{userData?.channel_title}</UserName>
          </Row>
          <FlexBox />
        </Row>
        <FlexSpace />
        <AnalysisPoint>
          분석 시점 | {year}년 {month}월 {day}일 10:00 까지
        </AnalysisPoint>
        <AnalysisPointMobile static>
          분석 시점 | {year}년 {month}월 {day}일 10:00 까지
        </AnalysisPointMobile>
        {/* <Button>{updateLogo} 업데이트</Button> */}
      </ProfileColumn>
    </ProfileDiv>
  );
}

export default YoutubeProfile;

const ProfileDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 117px;
  background: #ffffff;
  border-radius: 5px;

  padding: 24px 50px 30px 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 590px) {
    min-width: 320px;
    height: auto;
    padding: 20px 0px;
  }
`;

const ProfileColumn = styled(Column)`
  flex: 4;

  @media (max-width: 590px) {
    flex: none;
    padding: 0 15px;
  }
`;

const FlexSpace = styled.div`
  flex: 1;

  @media (max-width: 590px) {
    display: none;
  }
`;

const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transform: translate(50, 50);
  object-fit: cover;
  margin-right: 15px;

  @media (max-width: 590px) {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

const UserName = styled(H7)`
  font-weight: 700;

  @media (max-width: 590px) {
    //T2
    font-size: 16px;
    line-height: 20px;
  }
`;

const AnalysisPoint = styled(B2)`
  color: #aaaaaa;
  margin-left: 51px;

  @media (max-width: 590px) {
    display: none;
  }
`;

const AnalysisPointMobile = styled(B2)`
  color: #aaaaaa;
  margin-top: 8px;

  @media (min-width: 591px) {
    display: none;
  }
`;
