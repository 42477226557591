//React
import { useState, useEffect } from 'react';
import styled from 'styled-components';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

//CountUp
import CountUp from 'react-countup';

//Components
import CustomRadio from 'pages/Report/components/CustomRadio';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
  B1,
  B2,
  E1,
} from 'styles/DesignSystem';

//Assets
import profileDefault from 'assets/icons/application-header-profile.svg';
import imageExpired from 'assets/images/image-expired.png';
import feedLikeIcon from 'assets/icons/report-instagram-feed-like.svg';
import feedCommentIcon from 'assets/icons/report-instagram-feed-comment.svg';
import feedReachIcon from 'assets/icons/report-instagram-reach.svg';
import feedSaveIcon from 'assets/icons/report-instagram-save.svg';
import feedHeart from 'assets/icons/report-heart.svg';
import feedRocket from 'assets/icons/report-rocket.svg';

const handleProfileImgError = (error) => {
  error.target.src = profileDefault;
}

const handleFeedImgError = (error) => {
  error.target.src = imageExpired;
}

const handleProfileOnclick = function (permalink) {
  window.open(`${permalink}`, '_blank');
}

function TimeForToday(value) {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
  if (betweenTime < 1) return '방금전';
  if (betweenTime < 60) {
    return `${betweenTime}분 전`;
  }
  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간 전`;
  }
  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일 전`;
  }

  return `${Math.floor(betweenTimeDay / 365)}년 전`;
}

function InstagramRecentFeed({ userData, data }) {

  const radioValueList = ['1개월', '3개월', '전체']
  const [titleValue, setTitleValue] = useState('최신 피드');
  const [radioValue, setRadioValue] = useState(radioValueList[0]);

  const [recentFeedIndex, setRecentFeedIndex] = useState(0);
  const [onePopularFeedIndex, setOnePopularFeedIndex] = useState(0);
  const [threePopularFeedIndex, setThreePopularFeedIndex] = useState(0);
  const [allPopularFeedIndex, setAllPopularFeedIndex] = useState(0);

  const oneMonthParams = {
    onSlideChange: (e) => setOnePopularFeedIndex(e.activeIndex),
  };
  const threeMonthParams = {
    onSlideChange: (e) => setThreePopularFeedIndex(e.activeIndex),
  };
  const allMonthParams = {
    onSlideChange: (e) => setAllPopularFeedIndex(e.activeIndex),
  };

  useEffect(() => {
    setRecentFeedIndex(0);
    setOnePopularFeedIndex(0);
    setThreePopularFeedIndex(0);
    setAllPopularFeedIndex(0);
  }, [data]);

  return (
    <RecentFeedDiv>

      <TitleRow>
        <TitleInnerRow>
          <Title active={titleValue === '최신 피드'} onClick={() => { setTitleValue('최신 피드') }}>최신 피드</Title>
          <Title left={15} active={titleValue === '인기 피드'} onClick={() => { setTitleValue('인기 피드') }}>인기 피드</Title>
          <FlexBox />
        </TitleInnerRow>
        {
          titleValue === '인기 피드'
            ? (<CustomRadio radioValueList={radioValueList} radioValue={radioValue} setRadioValue={setRadioValue} />)
            : (<div style={{ height: '20px' }} />)
        }
      </TitleRow>

      <HorizontalLine />

      <RecentFeedContainer>
        <Row>
          <ProfileImage
            src={userData.profile_picture}
            onError={handleProfileImgError}
          />
          <T2 bold left={8}>@{data.user_name}</T2>
          <FlexBox />
          <VisitFeedText onClick={() => {
            if (titleValue === '최신 피드') {
              handleProfileOnclick(data.recent_seven_day_feed[recentFeedIndex]?.permalink);
            }
            if (titleValue === '인기 피드') {
              if (radioValue === '1개월') {
                handleProfileOnclick(data.top_reach_feed.oneTopReachFeed[onePopularFeedIndex].permalink);
              }
              if (radioValue === '3개월') {
                handleProfileOnclick(data.top_reach_feed.threeTopReachFeed[threePopularFeedIndex].permalink);
              }
              if (radioValue === '전체') {
                handleProfileOnclick(data.top_reach_feed.allTopReachFeed[allPopularFeedIndex].permalink);
              }
            }
          }}>
            피드 보러가기
          </VisitFeedText>
        </Row>
        {
          titleValue === '최신 피드'
          && (data?.recent_seven_day_feed?.length
            ? (<>
              <FeedImageWrapper>
                <FeedImage
                  src={data.recent_seven_day_feed[recentFeedIndex]?.media_url}
                  onError={handleFeedImgError}
                />
              </FeedImageWrapper>

              <Swiper
                spaceBetween={15}
                slidesPerView={'auto'}
                style={{ marginTop: '15px', cursor: 'grab' }}
              >
                {data.recent_seven_day_feed.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ width: '82px' }}
                  >
                    <RecentFeedScrollContianer onClick={() => { setRecentFeedIndex(index); }} >
                      {index === recentFeedIndex && <RecentFeedScrollImageSellected />}
                      <RecentFeedScrollImage src={item.media_url} whetherSelected={index === recentFeedIndex} onError={handleFeedImgError} />
                      <E1 color="#AAAAAA" top={5} static>{item.timestamp.substr(0, 10).replaceAll('-', '.')}</E1>
                    </RecentFeedScrollContianer>
                  </SwiperSlide>
                ))}
              </Swiper>

              <FeedCaptionWrapper style={{ display: '-webkit-box' }}>
                <B1 static>{data.recent_seven_day_feed[recentFeedIndex]?.caption}</B1>
              </FeedCaptionWrapper>
              <B2 top={8} color="#AAAAAA" static>{TimeForToday(data.recent_seven_day_feed[recentFeedIndex]?.timestamp)}</B2>

              <RecentFeedDataContainer>
                <Row>
                  <RecentFeedDataColumn>
                    <Row>
                      <FeedIconContainer>
                        <FeedIcon src={feedLikeIcon} />
                      </FeedIconContainer>
                      <T2 bold>좋아요</T2>
                      <FlexBox />
                      <T2 bold color="#4480F7">
                        <CountUp
                          duration={0.7}
                          end={data.recent_seven_day_feed[recentFeedIndex]?.like_count.total}
                          separator=','
                        />
                      </T2>
                    </Row>
                    <Row top={6}>
                      <ContraryYesterday>전날 대비</ContraryYesterday>
                      <FlexBox />
                      <RecentFeedDataComparison whetherPositive={data.recent_seven_day_feed[recentFeedIndex]?.like_count.sub >= 0} color="#4480F7" />
                      <B2 color="#4480F7">{Math.abs(data.recent_seven_day_feed[recentFeedIndex]?.like_count.sub).toLocaleString()}</B2>
                    </Row>
                  </RecentFeedDataColumn>

                  <FlexBox />

                  <RecentFeedDataColumn>
                    <Row>
                      <FeedIconContainer>
                        <FeedIcon src={feedCommentIcon} />
                      </FeedIconContainer>
                      <T2 bold>댓글</T2>
                      <FlexBox />
                      <T2 bold color="#2AAE81">
                        <CountUp
                          duration={0.7}
                          end={data.recent_seven_day_feed[recentFeedIndex]?.comments_count.total}
                          separator=','
                        />
                      </T2>
                    </Row>
                    <Row top={6}>
                      <ContraryYesterday>전날 대비</ContraryYesterday>
                      <FlexBox />
                      <RecentFeedDataComparison whetherPositive={data.recent_seven_day_feed[recentFeedIndex]?.comments_count.sub >= 0} color="#2AAE81" />
                      <B2 color="#2AAE81">{Math.abs(data.recent_seven_day_feed[recentFeedIndex]?.comments_count.sub).toLocaleString()}</B2>
                    </Row>
                  </RecentFeedDataColumn>
                </Row>

                <Row top={20}>
                  <RecentFeedDataColumn>
                    <Row>
                      <FeedIconContainer>
                        <FeedIcon src={feedReachIcon} />
                      </FeedIconContainer>
                      <T2 bold>도달</T2>
                      <FlexBox />
                      <T2 bold color="#F35451">
                        <CountUp
                          duration={0.7}
                          end={data.recent_seven_day_feed[recentFeedIndex]?.reach.total}
                          separator=','
                        />
                      </T2>
                    </Row>
                    <Row top={6}>
                      <ContraryYesterday>전날 대비</ContraryYesterday>
                      <FlexBox />
                      <RecentFeedDataComparison whetherPositive={data.recent_seven_day_feed[recentFeedIndex]?.reach.sub >= 0} color="#F35451" />
                      <B2 color="#F35451">{Math.abs(data.recent_seven_day_feed[recentFeedIndex]?.reach.sub).toLocaleString()}</B2>
                    </Row>
                  </RecentFeedDataColumn>

                  <FlexBox />

                  <RecentFeedDataColumn>
                    <Row>
                      <FeedIconContainer>
                        <FeedIcon src={feedSaveIcon} />
                      </FeedIconContainer>
                      <T2 bold>저장</T2>
                      <FlexBox />
                      <T2 bold color="#FFC121">
                        <CountUp
                          duration={0.7}
                          end={data.recent_seven_day_feed[recentFeedIndex]?.saved.total}
                          separator=','
                        />
                      </T2>
                    </Row>
                    <Row top={6}>
                      <ContraryYesterday>전날 대비</ContraryYesterday>
                      <FlexBox />
                      <RecentFeedDataComparison whetherPositive={data.recent_seven_day_feed[recentFeedIndex]?.saved.sub >= 0} color="#FFC121" />
                      <B2 color="#FFC121">{Math.abs(data.recent_seven_day_feed[recentFeedIndex]?.saved.sub).toLocaleString()}</B2>
                    </Row>
                  </RecentFeedDataColumn>
                </Row>
              </RecentFeedDataContainer>
            </>)

            : (<NoDataContainer>
              <T2 color='#AAAAAA'>데이터 없음</T2>
            </NoDataContainer>)
          )
        }

        {titleValue === '인기 피드'
          && (<>
            {radioValue === '1개월'
              && (<>
                {data?.top_reach_feed?.oneTopReachFeed?.length
                  ? (<>
                    <Swiper {...oneMonthParams}
                      spaceBetween={20}
                      slidesPerView={1}
                      style={{ cursor: 'grab' }}
                      modules={[Pagination]}
                      pagination={{ clickable: true }}
                    >
                      {data.top_reach_feed.oneTopReachFeed.map((item, index) => (
                        <SwiperSlide
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <FeedImageWrapper>
                            <FeedImage
                              src={item.media_url}
                              onError={handleFeedImgError}
                            />
                          </FeedImageWrapper>

                          <Row top={15}>
                            <FeedIcon src={feedHeart} />
                            <B1 left={5} color='#555555'>좋아요</B1>
                            <B1 left={5} bold>{item.like_count}</B1>

                            <PopularFeedIconBlank />

                            <FeedIcon src={feedRocket} />
                            <B1 left={5} color='#555555'>도달</B1>
                            <B1 left={5} bold>{item.reach}</B1>
                          </Row>

                          <FeedCaptionWrapper style={{ display: '-webkit-box' }}>
                            <B1 static>{item.caption}</B1>
                          </FeedCaptionWrapper>
                          <B2 top={8} color="#AAAAAA" static>{TimeForToday(item.timestamp)}</B2>

                          <div style={{ height: '40px' }} />

                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                  )
                  : (<NoDataContainer>
                    <T2 color='#AAAAAA'>데이터 없음</T2>
                  </NoDataContainer>)
                }
              </>)
            }

            {radioValue === '3개월'
              && (<>
                {data?.top_reach_feed?.threeTopReachFeed?.length
                  ? (<>
                    <Swiper {...threeMonthParams}
                      spaceBetween={20}
                      slidesPerView={1}
                      style={{ cursor: 'grab' }}
                      modules={[Pagination]}
                      pagination={{ clickable: true }}
                    >
                      {data.top_reach_feed.threeTopReachFeed.map((item, index) => (
                        <SwiperSlide
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <FeedImageWrapper>
                            <FeedImage
                              src={item.media_url}
                              onError={handleFeedImgError}
                            />
                          </FeedImageWrapper>

                          <Row top={15}>
                            <FeedIcon src={feedHeart} />
                            <B1 left={5} color='#555555'>좋아요</B1>
                            <B1 left={5} bold>{item.like_count}</B1>

                            <PopularFeedIconBlank />

                            <FeedIcon src={feedRocket} />
                            <B1 left={5} color='#555555'>도달</B1>
                            <B1 left={5} bold>{item.reach}</B1>
                          </Row>

                          <FeedCaptionWrapper style={{ display: '-webkit-box' }}>
                            <B1 static>{item.caption}</B1>
                          </FeedCaptionWrapper>
                          <B2 top={8} color="#AAAAAA" static>{TimeForToday(item.timestamp)}</B2>

                          <div style={{ height: '40px' }} />

                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                  )
                  : (<NoDataContainer>
                    <T2 color='#AAAAAA'>데이터 없음</T2>
                  </NoDataContainer>)
                }
              </>)
            }

            {radioValue === '전체'
              && (<>
                {data?.top_reach_feed?.allTopReachFeed?.length
                  ? (<>
                    <Swiper {...allMonthParams}
                      spaceBetween={20}
                      slidesPerView={1}
                      style={{ cursor: 'grab' }}
                      modules={[Pagination]}
                      pagination={{ clickable: true }}
                    >
                      {data.top_reach_feed.allTopReachFeed.map((item, index) => (
                        <SwiperSlide
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <FeedImageWrapper>
                            <FeedImage
                              src={item.media_url}
                              onError={handleFeedImgError}
                            />
                          </FeedImageWrapper>

                          <Row top={15}>
                            <FeedIcon src={feedHeart} />
                            <B1 left={5} color='#555555'>좋아요</B1>
                            <B1 left={5} bold>{item.like_count}</B1>

                            <PopularFeedIconBlank />

                            <FeedIcon src={feedRocket} />
                            <B1 left={5} color='#555555'>도달</B1>
                            <B1 left={5} bold>{item.reach}</B1>
                          </Row>

                          <FeedCaptionWrapper style={{ display: '-webkit-box' }}>
                            <B1 static>{item.caption}</B1>
                          </FeedCaptionWrapper>
                          <B2 top={8} color="#AAAAAA" static>{TimeForToday(item.timestamp)}</B2>

                          <div style={{ height: '40px' }} />

                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                  )
                  : (<NoDataContainer>
                    <T2 color='#AAAAAA'>데이터 없음</T2>
                  </NoDataContainer>)
                }
              </>)
            }
          </>)
        }
      </RecentFeedContainer>



    </RecentFeedDiv>
  );

}

export default InstagramRecentFeed;

const RecentFeedDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    margin-top: 8px;
    border-radius: 5px;
  }
`

const TitleRow = styled(Row)`
  margin: 20px;

  @media (max-width: 590px){
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`

const TitleInnerRow = styled(Row)`
  flex: 1;

  @media (max-width: 590px){
    margin: 20px 0 0 15px;
  }
`

const Title = styled(T1)`
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => !props.active && '#AAAAAA'};
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
`

const RecentFeedContainer = styled.div`
  width: 100%;
  padding: 30px 20px;

  @media (max-width: 590px){
    padding: 20px 15px;
  }
`

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: translate(50, 50);
  object-fit: cover;
`

const VisitFeedText = styled(B1)`
  color: #4480F7;
  cursor: pointer;
`

const FeedImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 82%;
  margin-top: 20px;

  @media (max-width: 590px){
	  padding-bottom: 100%;
  }
`

const FeedImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  border-radius: 5px;
  transform: translate(50, 50);
  object-fit: cover;
`

const RecentFeedScrollContianer = styled(Column)`
  margin-right: 15px;
  cursor: pointer;
`

const RecentFeedScrollImage = styled.img`
  width: 82px;
  height: 42px;
  border-radius: 2px;
  transform: translate(50, 50);
  object-fit: cover;
  border: ${(props) => props.whetherSelected ? '1px solid #618AFF' : '1px solid transparent'};
  filter: ${(props) => props.whetherSelected ? 'opacity(50%) background(#999999)' : 'none'};
`

export const RecentFeedScrollImageSellected = styled.div`
  width: 82px;
  height: 42px;
  border-radius: 2px;
  background: #618AFF;
  opacity: 0.2;
  position:absolute;
  z-index: 1;
`;

export const FeedCaptionWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RecentFeedDataContainer = styled.div`
  width: 100%;
  padding: 20px 20px 20px 15px;
  margin-top: 20px;
  border: 1px solid #F6F6F6;
  border-radius: 5px;

  @media (max-width: 590px){
    padding: 15px 10px;
  }
`;

const RecentFeedDataColumn = styled(Column)`
  width: 45%;

  @media (max-width: 590px){
    width: 47%;
  }
`

const FeedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    width: 16px;
    height: 16px;
  }
`

const FeedIcon = styled.img`
  width: 13px;
  height: 13px;
`

const RecentFeedDataComparison = styled.div`
  border-top: ${(props) => props.whetherPositive ? '' : `6px solid ${props.color}`};
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: ${(props) => props.whetherPositive ? `6px solid ${props.color}` : ''};
  margin-right: 3px;
`

const NoDataContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContraryYesterday = styled(B2)`
  margin-left: 28px;
  color:#999999;

  @media (max-width: 590px){
    margin-left: 24px;
  }
`

const PopularFeedIconBlank = styled.div`
  width: 60px;
`