//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Elements
import DifferenceChecker from 'pages/Report/Youtube/ChannelReport/elements/DifferenceChecker';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

//Assets
import likeIcon from 'assets/Application/Analysis/icons/channel-like.svg';
import dislikeIcon from 'assets/Application/Analysis/icons/channel-dislike.svg';
import commentIcon from 'assets/Application/Analysis/icons/channel-comment.svg';
import viewIcon from 'assets/Application/Analysis/icons/channel-view.svg';
import totalTimeIcon from 'assets/Application/Analysis/icons/channel-time-total.svg';
import averageTimeIcon from 'assets/Application/Analysis/icons/channel-time-average.svg';

function YoutubeChannelData({ todayData, yesterdayData }) {

  return (
    <ChannelDataDiv>
      
      <T1 bold>활동 데이터</T1>

      <ChannelDataWrapper>
        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={likeIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>좋아요</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.like_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.like_count - yesterdayData.like_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={dislikeIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>싫어요</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.dislike_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.dislike_count - yesterdayData.dislike_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={commentIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>댓글</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.comment_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.comment_count - yesterdayData.comment_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={viewIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>조회수</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.view_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.view_count - yesterdayData.view_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={totalTimeIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>총 시청 시간</T2>
          <FlexBox />
          <T1 bold>
            {
              todayData.estimated_minutes_watched > 5999
                ? (<><CountUp end={parseInt(todayData.estimated_minutes_watched / 60)} separator=',' duration={0.7} />시간</>)
              : todayData.estimated_minutes_watched > 59
                ? (<>
                    <CountUp end={parseInt(todayData.estimated_minutes_watched / 60)} separator=',' duration={0.7} />시간&nbsp;
                    <CountUp end={todayData.estimated_minutes_watched % 60} separator=',' duration={0.7} />분
                </>)
                : (<><CountUp end={todayData.estimated_minutes_watched % 60} separator=',' duration={0.7} />분</>)
            }
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.estimated_minutes_watched - yesterdayData.estimated_minutes_watched) : null} 
            left={15} 
            unit={['시간', '분']}
          />
        </Row>

        <HorizontalLine />

        <Row>
          <ChannelDataIconContainer>
            <ChannelDataIcon src={averageTimeIcon} />
          </ChannelDataIconContainer>
          <T2 color='#666666'>평균 시청 시간</T2>
          <FlexBox />
          <T1 bold>
            {
              todayData.average_view_duration > 59
               && (<><CountUp end={parseInt(todayData.average_view_duration / 60)} separator=',' duration={0.7} />분&nbsp;</>)
            }
            <CountUp end={todayData.average_view_duration % 60} separator=',' duration={0.7} />초
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.average_view_duration - yesterdayData.average_view_duration) : null} 
            left={15} 
            unit={['분', '초']}
          />
        </Row>
      </ChannelDataWrapper>

    </ChannelDataDiv>
  );

}

export default YoutubeChannelData;

const ChannelDataDiv = styled.div`
  width: 464px;
  height: 467px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1860px){
    width: 100%;
    margin-top: 4%;
  }

  @media (max-width: 590px){
    min-width: 320px;
    height: 473px;
    margin-top: 0;
    padding: 20px 15px 30px 15px;
  }
`

const ChannelDataWrapper = styled(Column)`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  gap: 20px;

  @media (max-width: 590px){
    margin-top: 30px;
  }
`

const ChannelDataIconContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChannelDataIcon = styled.img`
  width: 20px;
  height: 20px;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
`