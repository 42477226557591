//React
import styled from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
  B1,
} from 'styles/DesignSystem';

ChartJS.register(ArcElement, Tooltip);

function YoutubeAudienceGender({ data, count }) {

  let genderCountRatio = {
    male: {
      count: 0,
      ratio: 0
    }, 
    female: {
      count: 0,
      ratio: 0
    }, 
    others: {
      count: 0,
      ratio: 0
    }, 
  }

  if (data) {
    let genderRawDataKey = Object.keys(data)

    for (let gender of genderRawDataKey) {
      if (gender.includes('/male')) {
        genderCountRatio.male.ratio += data[gender];
      } else if (gender.includes('/female')) {
        genderCountRatio.female.ratio += data[gender];
      }
    }

    if(genderCountRatio.male.ratio + genderCountRatio.female.ratio > 100){
      genderCountRatio.male.ratio = parseFloat(((genderCountRatio.male.ratio / (genderCountRatio.male.ratio + genderCountRatio.female.ratio)) * 100).toFixed(1));
      genderCountRatio.female.ratio = parseFloat((100 - genderCountRatio.male.ratio).toFixed(1));
    } else {
      genderCountRatio.others.ratio = parseFloat((100 - genderCountRatio.male.ratio - genderCountRatio.female.ratio).toFixed(1));
    }

    genderCountRatio.male.count = parseInt(genderCountRatio.male.ratio/100*count);
    genderCountRatio.female.count = parseInt(genderCountRatio.female.ratio/100*count);
    genderCountRatio.others.count = parseInt(genderCountRatio.others.ratio/100*count);
  }

  const doughnutData = {
    labels: ['남성', '여성', '기타'],
    datasets: [
      {
        data: [genderCountRatio.male.count ,genderCountRatio.female.count, genderCountRatio.others.count ],
        backgroundColor: ['#4F5CD9', '#F35451', '#AAAAAA'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptionsDefault = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return ' ' + context.label + ': ' + context.parsed.toLocaleString() + '명';
          },
        }
      }
    }
  };

  return (
    <AudienceGenderDiv>
      <T1 bold>시청자 성별</T1>

      <AudienceGenderDataWrapper>
        <DoughnutDiv>
          {
            data
            ?(<Doughnut data={doughnutData} options={doughnutOptionsDefault} />)
            :(<T2 color='#AAAAAA' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>데이터 없음</T2>)
          }
        </DoughnutDiv>

        <FlexBox />

        <DataDiv>

          <Row>
            <GenderColor color='#4F5CD9' />
            <StaticB1 color='#777777'>남성</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#4F5CD9'>{genderCountRatio.male.ratio===0 ? 0 : genderCountRatio.male.ratio.toFixed(1)}%</StaticB1>
          </Row>


          <HorizontalLine />

          <Row>
            <GenderColor color='#F35451' />
            <StaticB1 color='#777777'>여성</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#F35451'>{genderCountRatio.female.ratio===0 ? 0 : genderCountRatio.female.ratio.toFixed(1)}%</StaticB1>
          </Row>


          <HorizontalLine />

          <Row>
            <GenderColor color='#CCCCCC' />
            <StaticB1 color='#777777'>기타</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#AAAAAA'>{genderCountRatio.others.ratio===0 ? 0 : genderCountRatio.others.ratio.toFixed(1)}%</StaticB1>
          </Row>

        </DataDiv>


      </AudienceGenderDataWrapper>

    </AudienceGenderDiv>
  );

}

export default YoutubeAudienceGender;

const AudienceGenderDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 268px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    height: 214px;
    margin-top: 20px;
    padding: 20px 15px 0 15px;
  }
`

const AudienceGenderDataWrapper = styled(Row)`
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 40px 0 40px;

  @media (max-width: 1860px){
    padding: 0 10% 0 10%;
  }

  @media (max-width: 590px){
    padding: 0 5%;
  }
`

const DoughnutDiv = styled.div`
  width: 40%;

  @media (max-width: 590px){
    width: 40%;
    min-width: 108px;
    max-width: 120px;
  }
`

const DataDiv = styled(Column)`
  width: 40%;
  gap: 15px;
  justify-content: center;

  @media (max-width: 590px){
    width: 45%;
  }
`

const GenderColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${props => props.color};
`

const StaticB1 = styled(B1)`
  @media (max-width: 590px){
    font-size: 14px;
    line-height: 18px;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
`