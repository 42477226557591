//API
import axios from 'axios';

export const loginByKauthCode = async function (code) {

    try {

        let options = {
            url: `${process.env.REACT_APP_API_HOST}/api/v1/kakao/callback?code=${code}`,
            method: 'GET'
        }
        const response = await axios(options);

        return response;

    } catch (error) {
        
        // kakaoCallbackFail 401
        // notKakaoAccount 403
        throw error.response.status;

    }

}

export const loginByCodeCategory = async function(code){
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/kakao/callback-category?code=${code}`)

        return response;
    }catch(error){
        throw error
    }
}