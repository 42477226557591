import React, { useEffect, useRef } from "react";
import styled from "styled-components";

//assets
import sunglass from "assets/images/landing/section03/sunglass.png";
import thumbnail from "assets/images/Home/thumbnail.png";

function CreatorVideo() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoSource =
    "https://s3-active-checker.s3.ap-northeast-2.amazonaws.com/review_sunglass.mp4";

  useEffect(function () {
    const observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            // videoRef.current.play();
            observer.disconnect();
          }
        });
      },
      {
        threshold: 1,
      }
    );

    observer.observe(videoRef.current);

    return function () {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Video
        ref={videoRef}
        src={videoSource}
        muted
        controls
        poster={window.innerWidth < 590 && thumbnail}
      />
    </>
  );
}

export default CreatorVideo;

const Video = styled.video`
  width: 100%;
`;
