//React
import { useState } from 'react'
import styled from 'styled-components';

//Marquee
import Marquee from "react-fast-marquee";

//sampleData
import sampleData from './sampleData.json';

//Design System
import {
  T2,
} from 'styles/DesignSystem';

//Components
import Calendar from 'pages/Report/elements/Calendar';
import Profile from 'pages/Report/elements/Profile';
import FollowerData from 'pages/Report/elements/FollowerData';
import FeedData from 'pages/Report/elements/FeedData';
import MeercatRanking from 'pages/Report/elements/MeercatRanking';
import RecentFeed from 'pages/Report/elements/RecentFeed';
import FollowerGender from 'pages/Report/elements/FollowerGender';
import FollowerAge from 'pages/Report/elements/FollowerAge';
import FollowerCountry from 'pages/Report/elements/FollowerCountry';

function InstagramReport() {

  const sessionUserData = sampleData.sampleCallback.data;
  const instagramData = sampleData.sampleInstagram.data;
  const [sellectedDateIndex, setSellectedDateIndex] = useState(sampleData.sampleInstagram.data.length - 1);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  return (
    <ReportDiv>
      <ReportContainer>

        <MarqueeContainer>
          <Marquee style={{
            background: '#4480F7',
            color: '#FFFFFF',
            height: '40px',
            width: '100%',
          }}
            gradient={false}
            speed={50}
          >
            <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
            <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
            <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
            <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
          </Marquee>
        </MarqueeContainer>

        <ReportWrapper>

          <FlexLarge>
            <Calendar
              data={instagramData}
              setSellectedDateIndex={setSellectedDateIndex}
              year={year} month={month} day={day}
              setYear={setYear} setMonth={setMonth} setDay={setDay}
            />
            <Profile
              userData={sessionUserData.instagramUserData}
              todayData={instagramData?.[sellectedDateIndex]}
              yesterdayData={sellectedDateIndex && instagramData?.[sellectedDateIndex - 1]}
              year={year} month={month} day={day}
            />
            <FlexSmallMain>
              <FollowerData
                todayData={instagramData?.[sellectedDateIndex]}
                yesterdayData={sellectedDateIndex && instagramData?.[sellectedDateIndex - 1]}
              />
              <RecentFeed
                userData={sessionUserData.instagramUserData}
                data={instagramData?.[sellectedDateIndex]}
              />
            </FlexSmallMain>
            <FlexSmallMain>
              <MeercatRanking
                userData={sessionUserData.instagramUserData}
                data={instagramData?.[sellectedDateIndex]}
              />
              <FeedData
                todayData={instagramData?.[sellectedDateIndex]}
                yesterdayData={sellectedDateIndex && instagramData?.[sellectedDateIndex - 1]}
              />
            </FlexSmallMain>
          </FlexLarge>

          <VerticalLine />

          <FlexMedium>
            <FlexSmallSub>
              <FollowerGender
                data={instagramData?.[sellectedDateIndex]?.gender_age_raw_data}
              />
              <FollowerAge
                data={instagramData?.[sellectedDateIndex]?.gender_age_raw_data}
              />
            </FlexSmallSub>
            <FollowerCountry
              todayData={instagramData?.[sellectedDateIndex]?.country_raw_data}
              yesterdayData={sellectedDateIndex && instagramData?.[sellectedDateIndex - 1]?.country_raw_data}
            />
          </FlexMedium>

        </ReportWrapper>
      </ReportContainer>
    </ReportDiv>
  );

}

export default InstagramReport;

const ReportDiv = styled.div`
  width: 100%;
  background: #F4F4F4;
  position: relative;
`

const ReportContainer = styled.div`
  background: #F4F4F4;
  min-height: calc(100vh - 80px);

  @media (max-width: 590px){
    min-height: calc(100vh - 60px);
  }
`

const MarqueeContainer = styled.div`
  @media (max-width: 590px){
    padding-top: 50px;
    background: #4480F7;
  }
`

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;

  @media (max-width: 590px){
    padding: 0 20px 100px 20px;
  }
`

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #E6E6E6;
  margin: 0 30px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexLarge = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2%;

  @media (max-width: 590px){
    gap: 0;
    margin-top: 50px;
  }
`

const FlexMedium = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 150px;

  @media (max-width: 1860px){
    width: 100%;
    max-width: 1066px;
    margin-top: 0;
    flex-direction: row;
    gap: 2%;
  }

  @media (max-width: 590px){
    margin-bottom: 30px;
    flex-direction: column;
    gap: 0;
    width: 100%;
    min-width: 320px;
  }
`

const FlexSmallMain = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`

const FlexSmallSub = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`