//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as amplitude from "@amplitude/analytics-browser";

//GA
import ReactGA from "react-ga4";

//Components
import Loader from "components/Loader";
import { AMPLITUDE_EVENT } from "constants/amplitude";

function Community() {
  const sessionUserData = JSON.parse(window.sessionStorage.getItem("userData"));
  const { instagramUserData, youtubeUserData } = sessionUserData ?? {};
  const username =
    instagramUserData?.user_name || youtubeUserData?.channel_title;

  useEffect(() => {
    ReactGA.set({ userId: username });
    ReactGA.event({
      category: "meercat",
      action: "CommunityPage",
    });
    amplitude.track(AMPLITUDE_EVENT.VIEW_COMMUNITY);
  }, []);

  const navigate = useNavigate();
  const [whetherLoaded, setWhetherLoaded] = useState(false);

  useEffect(() => {
    if (sessionUserData) {
      setWhetherLoaded(true);
    } else {
      navigate("/login?redirect_uri=community", { replace: true });
    }
  }, []);

  if (whetherLoaded) {
    return (
      <Iframe
        src={`https://cafe.meercat.io/login?token=${sessionUserData?.accessToken}&userId=${username}`}
      />
    );
  } else {
    return <Loader />;
  }
}

export default Community;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
