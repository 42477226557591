//React
import { useState, useEffect } from 'react'
import styled from 'styled-components';

import YoutubeCalendar from 'pages/Report/Youtube/ChannelReport/components/YoutubeCalendar';
import Profile from 'pages/Report/Youtube/ChannelReport/components/Profile';
import SubscriberData from 'pages/Report/Youtube/ChannelReport/components/SubscriberData';
import SubscriberTimeline from 'pages/Report/Youtube/ChannelReport/components/SubscriberTimeline';
import RevenueData from 'pages/Report/Youtube/ChannelReport/components/RevenueData';
import RevenueMonthData from 'pages/Report/Youtube/ChannelReport/components/RevenueMonthData';
import InflowKeyword from 'pages/Report/Youtube/ChannelReport/components/InflowKeyword';
import VideoData from 'pages/Report/Youtube/ChannelReport/components/VideoData';
import PopularVideo from 'pages/Report/Youtube/ChannelReport/components/PopularVideo';
import AlgorithmPick from 'pages/Report/Youtube/ChannelReport/components/AlgorithmPick';
import AudienceGender from 'pages/Report/Youtube/ChannelReport/components/AudienceGender';
import AudienceAge from 'pages/Report/Youtube/ChannelReport/components/AudienceAge';
import AudienceCountry from 'pages/Report/Youtube/ChannelReport/components/AudienceCountry';

function YoutubeReport({ change, youtubeUserData, youtubeReportData, youtubeRevenueData, youtubeSubscriberData }) {
  const todayIndex = youtubeReportData?.length - 1
  const [selectedDateIndex, setSelectedDateIndex] = useState(todayIndex);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  return (
    <ReportWrapper>
      <FlexLeftLarge>
        <section
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            gap: "24px",
          }}
        >
          <YoutubeCalendar
            data={youtubeReportData}
            selectedDateIndex={selectedDateIndex}
            setSelectedDateIndex={setSelectedDateIndex}
            year={year}
            month={month}
            day={day}
            setYear={setYear}
            setMonth={setMonth}
            setDay={setDay}
          />
          <div
            style={{
              marginBottom: 15,
              minWidth: "132px",
              height: "30px",
              display: "flex",
              position: "relative",
              alignItems: "center",
              borderRadius: "20px",
              border: "1px solid white",
            }}
          >
            <div
              style={{
                minWidth: "65px",
                minHeight: 30,
                position: "absolute",
                right: 0,
                background: "#4480F7",
                borderRadius: "20px",
              }}
            />
            <p
              style={{
                flex: 1,
                height: "100%",
                color: "black",
                fontWeight: 400,
                fontSize: 12,
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={change}
            >
              {"인스타"}
            </p>
            <p
              style={{
                flex: 1,
                height: "100%",
                color: "white",
                fontWeight: 700,
                fontSize: 12,
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {"유튜브"}
            </p>
          </div>
        </section>

        <Profile
          userData={youtubeUserData}
          year={year}
          month={month}
          day={day}
        />

        <SubscriberData
          subscriberData={youtubeSubscriberData}
          todayIndex={todayIndex - selectedDateIndex}
        />

        <SubscriberTimeline
          subscriberData={youtubeSubscriberData}
          todayIndex={todayIndex - selectedDateIndex}
        />

        <RevenueData
          revenueData={youtubeRevenueData}
          todayIndex={todayIndex - selectedDateIndex}
        />

        <FlexSmall>
          <RevenueMonthData
            revenueData={youtubeRevenueData}
            todayIndex={todayIndex - selectedDateIndex}
          />

          <InflowKeyword
            data={youtubeReportData?.[selectedDateIndex]?.yt_search_keyword}
          />
        </FlexSmall>

        <FlexLeftHidden>
          <FlexSmall>
            <VideoData
              todayData={youtubeReportData?.[selectedDateIndex]}
              yesterdayData={
                selectedDateIndex && youtubeReportData?.[selectedDateIndex - 1]
              }
            />
            <PopularVideo
              data={youtubeReportData?.[selectedDateIndex]?.top_five_videos}
            />
          </FlexSmall>

          <AlgorithmPick
            data={
              youtubeReportData?.[selectedDateIndex]?.top_three_month_videos
            }
          />
        </FlexLeftHidden>

        <FlexSmall>
          <AudienceGender
            data={youtubeReportData?.[selectedDateIndex]?.gender_age_raw_data}
            count={youtubeReportData?.[selectedDateIndex]?.view_count}
          />
          <AudienceAge
            data={youtubeReportData?.[selectedDateIndex]?.gender_age_raw_data}
            count={youtubeReportData?.[selectedDateIndex]?.view_count}
          />
        </FlexSmall>

        <AudienceCountry
          todayData={youtubeReportData?.[selectedDateIndex]?.country_raw_data}
          yesterdayData={
            selectedDateIndex &&
            youtubeReportData?.[selectedDateIndex - 1]?.country_raw_data
          }
        />
      </FlexLeftLarge>

      <VerticalLine />

      <FlexRight>
        <VideoData
          todayData={youtubeReportData?.[selectedDateIndex]}
          yesterdayData={
            selectedDateIndex && youtubeReportData?.[selectedDateIndex - 1]
          }
        />

        <PopularVideo
          data={youtubeReportData?.[selectedDateIndex]?.top_five_videos}
        />

        <AlgorithmPick
          data={youtubeReportData?.[selectedDateIndex]?.top_three_month_videos}
        />
      </FlexRight>
    </ReportWrapper>
  );

}

export default YoutubeReport;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 30px 0 30px;

  @media (max-width: 590px){
    padding: 0 20px 20px 20px;

    margin-bottom: 100px;
  }
`

const FlexLeftLarge = styled.div`
width: 100%;
max-width: 1066px;
height: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
column-gap: 2%;

@media (max-width: 590px){
  gap: 0;
  margin-top: 50px;
}
`

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #E6E6E6;
  margin: 0 30px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexRight = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexSmall = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`

const FlexLeftHidden = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;

  @media (min-width: 1861px){
    display: none;
  }

  @media (max-width: 590px){
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
`