import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-width: 375px;
  max-width: 375px;

  height: max-content;
  min-height: 100vh;

  margin: auto;

  background: white;

  display: flex;
  flex-flow: column nowrap;

  position: relative;

  @media (max-width: 590px) {
    max-width: 100%;
  }
`;

export const FixedBackground = styled.div`
  min-width: 100vw;
  min-height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background: linear-gradient(
      0deg,
      hsla(285, 2%, 57%, 0.03),
      hsla(285, 2%, 57%, 0.03)
    ),
    #fff;

  z-index: -1;
`;

export const Header = styled.header`
  width: 100%;
  padding: 12px 16px;

  position: sticky;
  top: 0;

  background: white;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 4px 8px rgb(0, 0, 0, 0.03);
  z-index: 10;
`;

export const Title1 = styled.h1`
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #212121;
  text-align: center;
`;

export const Desciprion1 = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #616161;
  text-align: center;

  word-break: keep-all;
`;

export const StartButton = styled.button`
  width: max-content;
  padding: 12px 60px;

  background: #4480f7;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;

  &:active {
    transform: scale(0.98);
  }
`;

export const SmallStartButton = styled.button`
  width: max-content;
  padding: 8px 16px;

  background: #4480f7;
  border-radius: 20px;

  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: white;

  &:active {
    transform: scale(0.98);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Title2 = styled.h2`
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #212121;

  word-break: keep-all;
`;

export const Title3 = styled.h3`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #212121;
  text-align: center;

  word-break: keep-all;
`;

export const Description2 = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #616161;
  break-words: keep;
`;

export const Description3 = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #616161;
  break-words: keep;
`;

export const BlueText = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #4480f7;
`;

export const Section = styled.section<{
  isGradient?: boolean;
  background?: string;
}>`
  width: 100%;

  padding: 60px 36px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  ${({ isGradient }) =>
    isGradient &&
    css`
      background: linear-gradient(0deg, #95c7ff 0%, rgba(255, 255, 255, 0) 70%);
    `}

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
`;

export const Section2 = styled.section`
  width: 100%;

  padding: 36px 36px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;
