import React from "react";
import styled from "styled-components";

//assets
import meercatLogoIcon from "assets/icons/creatorly-logo.png";
import {
  BlueText,
  Container,
  Desciprion1,
  Description2,
  Description3,
  FixedBackground,
  Header,
  Image,
  ImageContainer,
  Section,
  Section2,
  SmallStartButton,
  StartButton,
  Title1,
  Title2,
  Title3,
  Wrapper,
} from "../components/Marketing";
import CreatorSlide from "../components/Marketing/CreatorSlide";
import CreatorVideo from "../components/Marketing/Video";

import meercat from "assets/images/Home/meercat.png";
import report from "assets/images/Home/report.png";
import trending from "assets/images/Home/trending.png";
import influencer from "assets/images/Home/influencer.png";
import unfollow from "assets/images/Home/unfollow.png";
import kakao from "assets/images/Home/kakao.png";
import account from "assets/images/Home/account.png";
import reference from "assets/images/Home/reference.png";

export interface Marketing1Props {
  onStart: () => void;
}

const Marketing1: React.FC<Marketing1Props> = function ({ onStart }) {
  return (
    <>
      <FixedBackground />
      <Container>
        <Wrapper>
          <Header>
            <img src={meercatLogoIcon} style={{ width: 120 }} />
            <SmallStartButton onClick={onStart}>{"가입하기"}</SmallStartButton>
          </Header>
          <Section isGradient={true} style={{ padding: "60px 36px" }}>
            <ImageContainer>
              <Image src={meercat} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 24 }} />
            <Title1>
              내 인스타를 위한 <br /> AI비서, 미어캣
            </Title1>
            <div style={{ marginTop: 12 }} />
            <Desciprion1>
              {
                "Instagram, Facebook, Youtube의 공식 인증 파트너, 미어캣의 분석을 통해 SNS 채널을 성장시키세요"
              }
            </Desciprion1>
            <div style={{ marginTop: 24 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section>
          <Section background="#F5F5F5" style={{ padding: "60px 0px" }}>
            <Title3>
              {"29,114명 인플루언서의"}
              <br />
              {"이유 있는 선택"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorSlide />
            </ImageContainer>
          </Section>
          <Section2>
            <BlueText>{"일일 리포트"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>{"오늘 내 계정은 좀 어때?"}</Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={report} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {"✅ 매일 밤 10시, 오늘의 리포트 발송"}
              <br />
              {"✅ 랭킹을 통해 알아보는 채널 성장세"}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"피드 인사이트"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>{"내일은 어떤 콘텐츠를 올릴까?"}</Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={trending} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {"✅ 내 피드의 인기 콘텐츠 분석"} <br />
              {"✅ 나와 유사한 계정 추천"}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"트렌드 리포트"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>{"요즘 뜨는 콘텐츠를 알려줘"}</Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={influencer} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {`✅ 오늘의 TOP 릴스 `}
              <br />
              {`✅ 인기 키워드/해시태그`}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"언팔 알림"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>{"누가 나를 언팔했을까?"}</Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={unfollow} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {`✅ 맞팔 상대가 언팔로우 했을 경우 알림 발송`}
              <br />
              {`✅ 일일 언팔 현황 리포트`}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"카카오톡 알림"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>
              {"매일 밤, 카톡으로"}
              <br />
              {"슈퍼 편하게 확인하세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={kakao} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {`✅ 어디서든 인스타그램 랭킹 확인`}
              <br />
              {`✅ 성장세 지속을 위한 리마인드 기능`}
            </Description2>
          </Section2>

          <Section background="#F5F5F5">
            <Title3>
              {"인플루언서들의"}
              <br />
              {"생생한 후기를 들어보세요"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorVideo />
            </ImageContainer>
          </Section>

          <Section>
            <Title2 style={{ fontSize: 22, lineHeight: "28px" }}>
              {"카카오톡 회원가입 후"}
              <br />
              {"인스타 채널을 꼭! 연동해주세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <Description3>
              {"Instagram 채널 연동이 완료되어야"} <br />
              {"알림을 받을 수 있습니다"}
            </Description3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={account} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section>
          <Section background="#F5F5F5">
            <Title3>
              {"크리에이터를 1인 기업으로,"}
              <br />
              {"크리에이터리"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={reference} style={{ objectFit: "cover" }} />
            </ImageContainer>
          </Section>
        </Wrapper>
      </Container>
    </>
  );
};

export default Marketing1;
