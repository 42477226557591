//React
import styled from 'styled-components';

//Design System
import {
  Row,
  Column,
  FlexBox,
  LoadingSkeleton,
} from 'styles/DesignSystem';

function Skeleton() {

  return (
    <ReportWrapper>

      <FlexLeftLarge>

        <CalendarDiv>
        </CalendarDiv>

        <ProfileDiv>
          <DesktopDisplay>
            <LoadingSkeleton width={340} height={36} top={24} left={33} />
            <LoadingSkeleton width={260} height={16} top={10} left={33} />
          </DesktopDisplay>
          <MobileDisplay>
            <LoadingSkeleton width={120} height={24} top={20} left={15} />
            <LoadingSkeleton width={200} height={16} top={8} left={15} />
          </MobileDisplay>
        </ProfileDiv>

        <SubscriberDataDiv>
          <DesktopDisplay>
            <Row>
              <LoadingSkeleton width={150} height={24} />
              <FlexBox />
              <LoadingSkeleton width={200} height={24} />
            </Row>
            <LoadingSkeleton height={300} top={20} />
          </DesktopDisplay>
          <MobileDisplay>
            <LoadingSkeleton width={140} height={24} />
            <Row top={10}>
              <LoadingSkeleton width={80} height={18} />
              <FlexBox />
              <LoadingSkeleton width={120} height={18} />
            </Row>
            <LoadingSkeleton height={212} top={20} />
          </MobileDisplay>

        </SubscriberDataDiv>

        <SubscriberTimelineDiv>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton height={300} top={20} />
          </DesktopDisplay>
          <MobileDisplay>
            <LoadingSkeleton width={140} height={24} />
            <Row>
              <FlexBox />
              <LoadingSkeleton width={90} height={14} />
            </Row>
            <LoadingSkeleton height={280} top={20} />
          </MobileDisplay>
        </SubscriberTimelineDiv>

        <RevenueDataDiv>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton width={240} height={18} top={8} />
            <LoadingSkeleton height={230} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={230} top={20} />
          </DesktopDisplay>
          <MobileDisplay>
            <LoadingSkeleton width={140} height={24} />
            <LoadingSkeleton width={260} height={16} top={8} />
            <LoadingSkeleton height={100} top={30} />
            <LoadingSkeleton height={30} top={30} />
            <LoadingSkeleton height={30} top={30} />
            <LoadingSkeleton height={40} top={30} />
            <LoadingSkeleton height={220} top={20} />
          </MobileDisplay>
        </RevenueDataDiv>

        <FlexSmall>
          <RevenueMonthDataDiv>
            <DesktopDisplay>
              <LoadingSkeleton width={150} height={24} />
              <LoadingSkeleton height={34} top={30} />
              <LoadingSkeleton height={34} top={20} />
            </DesktopDisplay>
            <MobileDisplay>
              <LoadingSkeleton width={140} height={24} />
              <LoadingSkeleton height={24} top={30} />
              <LoadingSkeleton height={24} top={20} />
            </MobileDisplay>
          </RevenueMonthDataDiv>

          <InflowKeywordDiv>
            <DesktopDisplay>
              <LoadingSkeleton width={150} height={24} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
              <LoadingSkeleton height={36} top={20} />
            </DesktopDisplay>
            <MobileDisplay>
              <LoadingSkeleton width={140} height={24} />
              <LoadingSkeleton height={34} top={30} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
              <LoadingSkeleton height={34} top={20} />
            </MobileDisplay>
          </InflowKeywordDiv>
        </FlexSmall>

        <FlexLeftHidden>
          <FlexSmall>
            <VideoData>
              <DesktopDisplay>
                <LoadingSkeleton width={150} height={24} />
                <LoadingSkeleton height={36} top={30} />
                <LoadingSkeleton height={36} top={30} />
                <LoadingSkeleton height={36} top={30} />
                <LoadingSkeleton height={36} top={30} />
                <LoadingSkeleton height={36} top={30} />
                <LoadingSkeleton height={36} top={30} />
              </DesktopDisplay>
              <MobileDisplay>
                <LoadingSkeleton width={140} height={24} />
                <LoadingSkeleton height={34} top={40} />
                <LoadingSkeleton height={34} top={30} />
                <LoadingSkeleton height={34} top={30} />
                <LoadingSkeleton height={34} top={30} />
                <LoadingSkeleton height={34} top={30} />
                <LoadingSkeleton height={34} top={30} />
              </MobileDisplay>
            </VideoData>
            <PopularVideo>
              <DesktopDisplay>
                <LoadingSkeleton width={150} height={24} />
                <LoadingSkeleton height={345} top={20} />
              </DesktopDisplay>
              <MobileDisplay>
                <LoadingSkeleton width={140} height={24} />
                <LoadingSkeleton height={285} top={20} />
              </MobileDisplay>
            </PopularVideo>
          </FlexSmall>

          <AlgorithmPick>
            <DesktopDisplay>
              <LoadingSkeleton width={150} height={24} />
              <LoadingSkeleton width={240} height={18} top={8} />
              <LoadingSkeleton height={270} top={36} />
              <LoadingSkeleton height={24} top={15} />
              <LoadingSkeleton width={170} height={40} top={15} />
              <Row top={30}>
                <Column>
                  <LoadingSkeleton width={400} height={24} />
                  <LoadingSkeleton width={170} height={40} top={15} />
                </Column>
                <LoadingSkeleton width={60} height={60} top={-6} left={19} />
              </Row>
              <Row top={30}>
                <Column>
                  <LoadingSkeleton width={400} height={24} />
                  <LoadingSkeleton width={170} height={40} top={15} />
                </Column>
                <LoadingSkeleton width={60} height={60} top={-6} left={19} />
              </Row>
              <Row top={30}>
                <Column>
                  <LoadingSkeleton width={400} height={24} />
                  <LoadingSkeleton width={170} height={40} top={15} />
                </Column>
                <LoadingSkeleton width={60} height={60} top={-6} left={19} />
              </Row>
              <Row top={30}>
                <Column>
                  <LoadingSkeleton width={400} height={24} />
                  <LoadingSkeleton width={170} height={40} top={15} />
                </Column>
                <LoadingSkeleton width={60} height={60} top={-6} left={19} />
              </Row>
            </DesktopDisplay>
            <MobileDisplay>
              <LoadingSkeleton width={140} height={24} />
              <LoadingSkeleton width={260} height={16} top={8} />
              <LoadingSkeleton height={193} top={30} />
              <LoadingSkeleton style={{ width: '80%' }} height={60} top={30} />
              <Row top={42} style={{ width: '100%' }}>
                <LoadingSkeleton height={82} style={{ width: '70%' }} />
                <FlexBox />
                <LoadingSkeleton width={60} height={60} top={-20} />
              </Row>
              <Row top={42} style={{ width: '100%' }}>
                <LoadingSkeleton height={82} style={{ width: '70%' }} />
                <FlexBox />
                <LoadingSkeleton width={60} height={60} top={-20} />
              </Row>
              <Row top={42} style={{ width: '100%' }}>
                <LoadingSkeleton height={82} style={{ width: '70%' }} />
                <FlexBox />
                <LoadingSkeleton width={60} height={60} top={-20} />
              </Row>
              <Row top={42} style={{ width: '100%' }}>
                <LoadingSkeleton height={82} style={{ width: '70%' }} />
                <FlexBox />
                <LoadingSkeleton width={60} height={60} top={-20} />
              </Row>
            </MobileDisplay>
          </AlgorithmPick>
        </FlexLeftHidden>

        <FlexSmall>
          <AudienceGenderDiv>
            <DesktopDisplay>
              <LoadingSkeleton width={150} height={24} />
              <Row top={35} left={48}>
                <LoadingSkeleton width={154} height={154} />
                <FlexBox />
                <Column style={{ marginRight: '50px' }}>
                  <LoadingSkeleton width={154} height={24} top={0} />
                  <LoadingSkeleton width={154} height={24} top={20} />
                  <LoadingSkeleton width={154} height={24} top={20} />
                </Column>
              </Row>
            </DesktopDisplay>
            <MobileDisplay>
              <LoadingSkeleton width={140} height={24} />
              <Row top={25} left={20}>
                <LoadingSkeleton width={120} height={120} />
                <FlexBox />
                <Column style={{ marginRight: '10px' }}>
                  <LoadingSkeleton width={140} height={22} top={0} />
                  <LoadingSkeleton width={140} height={22} top={20} />
                  <LoadingSkeleton width={140} height={22} top={20} />
                </Column>
              </Row>
            </MobileDisplay>
          </AudienceGenderDiv>

          <AudienceAgeDiv>
            <DesktopDisplay>
              <LoadingSkeleton width={150} height={24} />
              <LoadingSkeleton height={230} top={20} />
            </DesktopDisplay>
            <MobileDisplay>
              <LoadingSkeleton width={140} height={24} />
              <LoadingSkeleton height={212} top={40} />
            </MobileDisplay>
          </AudienceAgeDiv>
        </FlexSmall>

        <AudienceCountryDiv>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton height={240} top={30} />
            <LoadingSkeleton height={50} top={30} />
            <LoadingSkeleton height={50} top={10} />
            <LoadingSkeleton height={50} top={10} />
          </DesktopDisplay>
          <MobileDisplay>
            <LoadingSkeleton width={140} height={24} />
            <LoadingSkeleton height={172} top={20} />
            <LoadingSkeleton height={44} top={10} />
            <LoadingSkeleton height={44} top={10} />
            <LoadingSkeleton height={44} top={10} />
            <LoadingSkeleton height={30} top={10} />
            <LoadingSkeleton width={240} height={16} top={10} />
          </MobileDisplay>
        </AudienceCountryDiv>

      </FlexLeftLarge>

      <VerticalLine />

      <FlexRight>
        <VideoData>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
            <LoadingSkeleton height={36} top={30} />
          </DesktopDisplay>
        </VideoData>
        <PopularVideo>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton height={345} top={20} />
          </DesktopDisplay>
        </PopularVideo>
        <AlgorithmPick>
          <DesktopDisplay>
            <LoadingSkeleton width={150} height={24} />
            <LoadingSkeleton width={240} height={18} top={8} />
            <LoadingSkeleton height={270} top={36} />
            <LoadingSkeleton height={24} top={15} />
            <LoadingSkeleton width={170} height={40} top={15} />
            <Row top={30}>
              <Column>
                <LoadingSkeleton width={400} height={24} />
                <LoadingSkeleton width={170} height={40} top={15} />
              </Column>
              <LoadingSkeleton width={60} height={60} top={-6} left={19} />
            </Row>
            <Row top={30}>
              <Column>
                <LoadingSkeleton width={400} height={24} />
                <LoadingSkeleton width={170} height={40} top={15} />
              </Column>
              <LoadingSkeleton width={60} height={60} top={-6} left={19} />
            </Row>
            <Row top={30}>
              <Column>
                <LoadingSkeleton width={400} height={24} />
                <LoadingSkeleton width={170} height={40} top={15} />
              </Column>
              <LoadingSkeleton width={60} height={60} top={-6} left={19} />
            </Row>
            <Row top={30}>
              <Column>
                <LoadingSkeleton width={400} height={24} />
                <LoadingSkeleton width={170} height={40} top={15} />
              </Column>
              <LoadingSkeleton width={60} height={60} top={-6} left={19} />
            </Row>
          </DesktopDisplay>
        </AlgorithmPick>
      </FlexRight>

    </ReportWrapper>
  );

}

export default Skeleton;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;

  @media (max-width: 590px){
    padding: 0 20px 20px 20px;
  }
`

const FlexLeftLarge = styled.div`
  width: 100%;
  max-width: 1050px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;

  @media (max-width: 590px){
    gap: 0;
    margin-top: 30px;
  }
`

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #E6E6E6;
  margin: 0 30px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexRight = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexSmall = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`

const FlexLeftHidden = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;

  @media (min-width: 1861px){
    display: none;
  }

  @media (max-width: 590px){
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
`

const CalendarDiv = styled.div`
  width: 100%;
  max-width: 1050px;
  height: 80px;
  background: transparent;

  @media (max-width: 590px){
    height: 62px;
  }
`

const ProfileDiv = styled.div`
  width: 100%;
  max-width: 1050px;
  height: 117px;
  background: #FFFFFF;
  border-radius: 5px;

  @media (max-width: 590px){
    min-width: 320px;
    height: 88px;
  }
`

const SubscriberDataDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 390px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  
  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 340px;
    margin-top: 20px;
    padding: 20px 15px;
  }
`

const SubscriberTimelineDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 390px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 390px;
    margin-top: 20px;
  }
`

const RevenueDataDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 835px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 652px;
    margin-top: 20px;
    padding: 20px 15px;
  }
`

const RevenueMonthDataDiv = styled.div`
  width: 100%;
  height: 185px;
  margin-top: 4%;
  border-radius: 5px;
  background: #FFFFFF;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    height: 167px;
    min-width: 320px;
    margin-top: 20px;
    padding: 20px 15px;
  }
`

const InflowKeywordDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 630px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 612px;
    margin-top: 20px;
  }
`

const AudienceGenderDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 268px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 214px;
    margin-top: 20px;
  }
`

const AudienceAgeDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 320px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 320px;
    margin-top: 20px;
  }
`

const AudienceCountryDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 607px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 150px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 491px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`

const VideoData = styled.div`
  width: 464px;
  height: 467px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 1860px){
    width: 100%;
    margin-top: 4%;
  }

  @media (max-width: 590px){
    height: 473px;
    margin-top: 0;
  }
`

const PopularVideo = styled.div`
  width: 464px;
  height: 439px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: 1860px){
    width: 100%;
    margin-top: 4%;
  }

  @media (max-width: 590px){
    height: 371px;
    margin-top: 20px;
  }
`

const AlgorithmPick = styled.div`
  width: 464px;
  height: 926px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 150px;
  padding: 20px;
  overflow: hidden;

  @media (max-width: 1860px){
    width: 49%;
    margin-top: 2%;
    margin-bottom: 0;
  }

  @media (max-width: 590px){
    width: 100%;
    height: 909px;
    margin-top: 0;
  }
`

const DesktopDisplay = styled.div`
  @media (max-width: 590px){
    display: none;
  }
`

const MobileDisplay = styled.div`
  @media (min-width: 591px){
    display: none;
  }
`