//React
import { useState } from 'react';
import styled from 'styled-components';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps'
import { scaleLinear } from 'd3-scale';
import ReactTooltip from 'react-tooltip';

//Map
import topologyMap from 'pages/Report/Youtube/ChannelReport/components/AudienceCountry/topology';

//Components
import DifferenceChecker from 'pages/Report/Youtube/ChannelReport/elements/DifferenceChecker';
import CountryCode2to3 from 'pages/Report/Youtube/ChannelReport/components/AudienceCountry/countryCode2to3';
import CountryCode3to2 from 'pages/Report/Youtube/ChannelReport/components/AudienceCountry/countryCode3to2';
import CountryKoreanFlag from 'pages/Report/Youtube/ChannelReport/components/AudienceCountry/countryKoreanFlag';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
  B1,
  B2,
} from 'styles/DesignSystem';

const colorScale = scaleLinear().domain([0, 0.03, 0.1, 1]).range(['#F4F4F4', '#4480F7', '#2178C5', '#005DAE']);
const colorScaleHover = scaleLinear().domain([0, 0.01, 0.05, 1]).range(['#F4F4F4', '#4480F7', '#2178C5', '#005DAE']);
function YoutubeAudienceCountry({ todayData, yesterdayData }) {

  const [tooltipContent, setTooltipContent] = useState('');

  let geoCountryData = [];

  if (todayData) {

    //countryRawData 국가별 인원수 기준으로 내림차순 정렬
    const countryRawData = Object.entries(todayData);
    countryRawData.sort(function (a, b) {
      return b[1] - a[1];
    });

    //geoData로 쓰일 데이터 형식 변환
    let countrySum = 0;
    for (let element of countryRawData) {
      countrySum += element[1];
    }
    for (let element of countryRawData) {
      geoCountryData.push(
        {
          country_code: CountryCode2to3[element[0]],
          count: element[1],
          ratio: (element[1] / countrySum)
        }
      );
    }

  }

  return (
    <AudienceCountryDiv>

      <T1 bold>시청자 국가</T1>

      {
        todayData
          ? (<GlobalMapDiv data-tip="">
            <ComposableMap
              width={400}
              height={200}
              projection="geoMercator"
              projectionConfig={{
                rotate: [0, 0, 0],
                center: [-10, 20],
                scale: 60,
              }}
            >
              <ZoomableGroup
                translateExtent={[[-50, -100], [482, 241]]}
              >
                {geoCountryData.length > 0 && (
                  <Geographies geography={topologyMap} >
                    {({ geographies }) =>

                      geographies.map((geo) => {

                        const geoData = geoCountryData.find((element) => element.country_code === geo.id);

                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            style={{
                              default: {
                                fill: `${geoData ? colorScale(geoData['ratio']) : '#F4F4F4'}`,
                                outline: 'none',
                                cursor: 'default',
                              },
                              hover: {
                                fill: `${geoData ? colorScaleHover(geoData['ratio']) : '#DDDDDD'}`,
                                outline: 'none',
                                cursor: 'default',
                              },
                              pressed: {
                                fill: `${geoData ? colorScaleHover(geoData['ratio']) : '#DDDDDD'}`,
                                outline: 'none',
                                cursor: 'default',
                              }
                            }}
                            onMouseEnter={() => {
                              if (geoData) {
                                setTooltipContent(`${CountryKoreanFlag?.[CountryCode3to2[geo.id]]?.flag ?? ''} ${geo.properties.name}: ${(geoData['ratio'] * 100).toFixed(2)}%`);
                              } else {
                                setTooltipContent(`${CountryKoreanFlag?.[CountryCode3to2[geo.id]]?.flag ?? ''} ${geo.properties.name}`);
                              }
                            }}
                            onMouseLeave={() => {
                              setTooltipContent('');
                            }}
                          />
                        );

                      })

                    }
                  </Geographies>
                )}
              </ZoomableGroup>
            </ComposableMap>

            <ReactTooltip>{tooltipContent && <div style={{ margin: '4px 0' }}>{tooltipContent}</div>}</ReactTooltip>
          </GlobalMapDiv>)
          : (<NoData>데이터 없음</NoData>)
      }

      <CountryDifferenceColumn>
        <CountryDifferenceBox>
          {
            geoCountryData[0]
              ? (<>
                <B1 bold left={8}>1.</B1>
                <CountryFlag>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[0].country_code]]?.flag ?? '🏳️'}</CountryFlag>
                <CountryDifferenceText>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[0].country_code]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                <FlexBox />
                <CountryCount>{geoCountryData[0].count.toLocaleString()}명</CountryCount>
                <DifferenceChecker data={yesterdayData ? geoCountryData[0].count - (yesterdayData?.[CountryCode3to2[geoCountryData[0].country_code]] ?? 0) : null} />
              </>)
              : (<>
                <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                <FlexBox />
                <DifferenceChecker data={null} />
              </>)
          }
        </CountryDifferenceBox>
        <CountryDifferenceBox>
          {
            geoCountryData[1]
              ? (<>
                <B1 bold left={8}>2.</B1>
                <CountryFlag>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[1].country_code]]?.flag ?? '🏳️'}</CountryFlag>
                <CountryDifferenceText>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[1].country_code]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                <FlexBox />
                <CountryCount>{geoCountryData[1].count.toLocaleString()}명</CountryCount>
                <DifferenceChecker data={yesterdayData ? geoCountryData[1].count - (yesterdayData?.[CountryCode3to2[geoCountryData[1].country_code]] ?? 0) : null} />
              </>)
              : (<>
                <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                <FlexBox />
                <DifferenceChecker data={null} />
              </>)
          }
        </CountryDifferenceBox>
        <CountryDifferenceBox>
          {
            geoCountryData[2]
              ? (<>
                <B1 bold left={8}>3.</B1>
                <CountryFlag>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[2].country_code]]?.flag ?? '🏳️'}</CountryFlag>
                <CountryDifferenceText>{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[2].country_code]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                <FlexBox />
                <CountryCount>{geoCountryData[2].count.toLocaleString()}명</CountryCount>
                <DifferenceChecker data={yesterdayData ? geoCountryData[2].count - (yesterdayData?.[CountryCode3to2[geoCountryData[2].country_code]] ?? 0) : null} />
              </>)
              : (<>
                <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                <FlexBox />
                <DifferenceChecker data={null} />
              </>)
          }
        </CountryDifferenceBox>



      </CountryDifferenceColumn>

      <CountryInflowOutflow inflow>
        {
          geoCountryData[0]
            ? (<>
              오늘은 🔥&nbsp;
              <CountryDifferenceText bold color="#F35451">{CountryKoreanFlag?.[CountryCode3to2[geoCountryData[0].country_code]]?.name ?? '알 수 없는 국가'}에서 제일 많이 시청</CountryDifferenceText>
              하고 있어요
            </>)
            : (<>
              오늘은 시청자가 존재하지 않습니다
            </>)
        }
      </CountryInflowOutflow>

      <AudienceCountryNotice>* 국가 데이터가 비공개인 시청자는 집계에서 누락되었습니다</AudienceCountryNotice>

    </AudienceCountryDiv>
  );

}

export default YoutubeAudienceCountry;

const AudienceCountryDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 607px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 150px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    height: auto;
    margin-top: 20px;
    padding: 20px 15px 30px;
    margin-bottom: 0px;
  }
`

const GlobalMapDiv = styled.div`
  margin: 22.5px 0;
  cursor: grab;

  @media (max-width: 590px){
    margin: 10px 0;
  }
`

const CountryDifferenceColumn = styled(Column)`
  gap: 10px;

  @media (max-width: 590px){
    gap: 8px;
  }
`

const CountryDifferenceBox = styled(Row)`
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  padding: 0 10px;
`

const CountryInflowOutflow = styled(Row)`
  width: 100%;
  padding: 8px 0;
  margin-top: ${(props) => props.inflow ? '15px' : '10px'};
  background: ${(props) => props.inflow ? '#FFEDED' : '#ECF2FE'};
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #555555;
  justify-content: center;

  @media (max-width: 590px){
    margin-top: 8px;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
  }
`

const CountryFlag = styled.p`
  font-size: 20px;
  margin-left: 10px;
  margin-top: 1px;

  @media (max-width: 590px){
    font-size: 17px;
    margin-left: 6px;
  }
`

const CountryDifferenceText = styled(B1)`
  font-weight: 700;
  margin-left: 4px;

  @media (max-width: 590px){
    font-size: 12px;
    line-height: 14px;
  }
`

const CountryCount = styled(B1)`
  margin-right: 8px;

  @media (max-width: 590px){
    font-size: 13px;
    line-height: 14px;
  }
`

const AudienceCountryNotice = styled(B2)`
  margin-top: 10px;
  color: #AAAAAA;

  @media (max-width: 590px){
    margin-top: 7px;
  }
`

const NoData = styled(T2)`
  width: 100%;
  height: 100%;
  color: #AAAAAA;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    height: 300px;
  }
`