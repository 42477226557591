//React
import { useState, useEffect } from 'react'
import styled from 'styled-components'

//Marquee
import Marquee from "react-fast-marquee";

//Design System
import {
  Row,
  Column,
  T2,
  B1,
} from 'styles/DesignSystem';

//Assets
import campaign_1 from 'assets/images/campaign-list/스픽 협찬신청.png';
import campaign_2 from 'assets/images/campaign-list/무화당 협찬신청.png';
import campaign_3 from 'assets/images/campaign-list/GFFG 협찬 신청.png';
import campaign_4 from 'assets/images/campaign-list/AOMG 협찬신청.png';

function Campaign() {

  return (
    <CampaignDiv>

      <MarqueeContainer>
        <Marquee style={{
          background: '#4480F7',
          color: '#FFFFFF',
          height: '50px',
          width: '100%',
        }}
          gradient={false}
          speed={50}
        >
          <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
          <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
          <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
          <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
        </Marquee>
      </MarqueeContainer>

      <CampaignContainer>
        <NoticeContainer>
          <Row><T2 color='#FFFFFF'>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2></Row>
        </NoticeContainer>


        <CampaignColumn first onClick={function(){alert('해당 페이지에서는 접근하실 수 없습니다.');}}>
          <CampaignImage src={campaign_4} />
          <CustomT2>[크리에이터리] 노티드 매장에서 AOMG음악과 도넛 즐기고 릴스 업로드하기!</CustomT2>
          <B1 static color='#777777' top={6}>신청 가능 기간: 2022년 12월 21일 10:00까지</B1>
        </CampaignColumn>


        <CampaignColumn onClick={function(){alert('해당 페이지에서는 접근하실 수 없습니다.');}}>
          <CampaignImage src={campaign_3} />
          <CustomT2>[크리에이터리] 요즘 핫한 GFFG 상품권 쏜다!</CustomT2>
          <B1 static color='#777777' top={6}>신청 가능 기간: 2022년 11월 18일 23:59까지</B1>
        </CampaignColumn>


        <CampaignColumn onClick={function(){alert('해당 페이지에서는 접근하실 수 없습니다.');}}>
          <CampaignImage src={campaign_2} />
          <CustomT2>[무화당] 부담없는 저탄수 카스테라</CustomT2>
          <B1 static color='#777777' top={6}>신청 가능 기간: 2022년 11월 16일 15:00까지</B1>
        </CampaignColumn>


        <CampaignColumn onClick={function(){alert('해당 페이지에서는 접근하실 수 없습니다.');}}>
          <CampaignImage src={campaign_1} />
          <CustomT2>[스픽] 말하면서 배우는 진짜 영어</CustomT2>
          <B1 static color='#777777' top={6}>신청 가능 기간: 2022년 8월 22일 23:59까지</B1>
        </CampaignColumn>

      </CampaignContainer>

    </CampaignDiv>
  )

}

export default Campaign;

const CampaignDiv = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #F4F4F4;
  position: relative;
  //display: flex;
  //justify-content: center;

  @media (max-width: 590px){
    min-height: calc(100vh - 50px);
  }
`

const MarqueeContainer = styled.div`
  @media (max-width: 590px){
    display: none;
  }
`

const CampaignContainer = styled.div`
  width: 100%;
  padding: 40px 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: 590px){
    margin-top: 50px;
    padding: 30px 20px 120px 20px;
    justify-content: center;
    gap: 0;
  }
`

const NoticeContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #4480F7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 591px){
    display: none;
  }
`

const CampaignColumn = styled(Column)`
  width: 320px;
  cursor: pointer;
  position: relative;

  @media (max-width: 590px){
    width: 100%;
    margin-top: ${(props) => props.first ? '20px' : '40px'}; 
  }
`

const CampaignImage = styled.img`
  width: 320px;
  height: 160px;
  border-radius: 5px;

  @media (max-width: 590px){
    width: 100%;
    height: auto;
  }
`

export const CampaignImageSellected = styled.div`
  width: 320px;
  height: 160px;
  border-radius: 5px;
  background: #000000BB;
  position:absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;

  @media (max-width: 590px){
    width: 100%;
    height: calc( (100vw - 40px)/2 );
  }
`;

export const CustomT2 = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 13px;
`;