// React
import { useEffect } from 'react'

//Components
import Loader from 'components/Loader';

function androidOrIOS() {

    var agent = navigator.userAgent.toUpperCase();

    if (navigator.userAgent.match(/android/i)) {

        if (agent.indexOf('KAKAOTALK') >= 0 || agent.indexOf('INSTAGRAM') >= 0) { //카카오 인앱 브라우저면서 안드로이드인 경우는 useragent에 safari를 포함하고 있어서 먼저 걸러줘야함
            window.location.replace(`intent://meercat.io/intent#Intent;scheme=http;package=com.android.chrome;end`);
        
        } else if(agent.indexOf('FIREFOX') >= 0 || agent.indexOf('EDG') >= 0 || agent.indexOf('SAFARI') >= 0 || agent.indexOf('CHROME') >= 0 || agent.indexOf('NAVER') >= 0) {
            window.location.replace(`https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/register/kauth&response_type=code&state=youtube`);
        
        } else {
            window.location.replace(`intent://meercat.io/intent#Intent;scheme=http;package=com.android.chrome;end`);
        }

    } else {
        window.location.replace(`https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/register/kauth&response_type=code&state=youtube`);
    }

}

function YoutubeIntent() {  

    useEffect(() => {
        androidOrIOS(); // 브라우저에 따른 리다이렉트
    }, []);

    return ( // 미어캣 로딩 이미지
        <Loader/>
    );

}

export default YoutubeIntent;