//React
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'

//Css
import {
  Row,
  Column,
  FlexBox,
  Image,
  H5,
  H6,
  T2,
  MainBlue,
  NextButton
} from 'styles/DesignSystem';

//Components
import { LinkReauth } from 'apis/Register';

//Assets
import arrowLeftDisabled from 'assets/icons/button-arrow-left-disabled.svg';
import arrowLeft from 'assets/icons/button-arrow-left.svg';
import arrowRight from 'assets/icons/button-arrow-right.svg';
import guide1 from 'assets/images/link-guide/guide1.png';
import guide2 from 'assets/images/link-guide/guide2.png';
import guide3 from 'assets/images/link-guide/guide3.png';
import guide4 from 'assets/images/link-guide/guide4.png';
import guide5 from 'assets/images/link-guide/guide5.png';
import guide6 from 'assets/images/link-guide/guide6.png';
import guide7 from 'assets/images/link-guide/guide7.png';
import guide8 from 'assets/images/link-guide/guide8.png';
import guide9 from 'assets/images/link-guide/guide9.png';
import guide10 from 'assets/images/link-guide/guide10.png';
import guide11 from 'assets/images/link-guide/guide11.png';
import guide12 from 'assets/images/link-guide/guide12.png';
import guide13 from 'assets/images/link-guide/guide13.png';

function Guide() {

  const kid = new URL(window.location.href).searchParams.get('kid');
  const type = new URL(window.location.href).searchParams.get('type');

  const navigate = useNavigate();
  const [guideIndex, setGuideIndex] = useState(0);
  const guideImage = [guide1, guide2, guide3, guide4, guide5, guide6, guide7, guide8, guide9, guide10, guide11, guide12, guide13, guide6];



  return (
    <Container>
      <ModalContainer>
        <ContentsWrapper>

          {
            guideIndex < 5
              ? <H5>프로페셔널 계정 전환 가이드</H5>
            : guideIndex === 5
              ? <H5>지금까지 <MainBlue>잘 따라 오셨나요?</MainBlue></H5>
            : guideIndex > 5 && guideIndex < 13
              ?<H5>페이스북 연동 가이드</H5>
              :<H5>가이드를 <MainBlue>모두 확인했나요?</MainBlue></H5>
          }

          <Image src={guideImage[guideIndex]} width={360} height={(guideIndex === 5 || guideIndex === 11 || guideIndex === 13) ? 196 : 274} top={60} />

          {
            {
              0: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>01</H6> <H6 left={10}>인스타그램 우측 상단에 위치한</H6></Row>
                <H6 top={5}><MainBlue>메뉴</MainBlue>를 클릭해 주세요</H6>
              </Column>,

              1: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>02</H6> <H6 left={10}>메뉴에서 <MainBlue>설정 - 계정</MainBlue>을</H6></Row>
                <H6 top={5}>차례대로 선택해 주세요</H6>
              </Column>,

              2: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>03</H6> <H6 left={10}>메인 하단에 <MainBlue>계정 유형 전환</MainBlue>에서</H6></Row>
                <H6 top={5}><MainBlue>프로페셔널 계정으로 전환</MainBlue>을 눌러주세요</H6>
              </Column>,

              3: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>04</H6> <H6 left={10}>계정 전환이 완료되면</H6></Row>
                <H6 top={5}><MainBlue>프로필 편집</MainBlue>을 클릭해 주세요</H6>
              </Column>,

              4: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>05</H6> <H6 left={10}><MainBlue>페이지 연결을 꼭 완료해 주세요</MainBlue></H6></Row>
                <H6 top={5}>페이지가 없다면 새로 만들어 주세요</H6>
              </Column>,

              5: <Column top={30} style={{ alignItems: 'center' }}>
                <H6>다음 페이지부터는 가이드 종료 후,</H6>
                <H6 top={5}>바로 실행되는 페이지에요</H6>
                <T2 top={10} color='#555555'>가이드 내용을 잘 기억해 주세요!</T2>
              </Column>,

              6: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>01</H6> <H6 left={10}>인스타그램과 연결한</H6></Row>
                <H6 top={5}>페이스북 계정으로 <MainBlue>로그인</MainBlue>해 주세요</H6>
              </Column>,

              7: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>02</H6> <H6 left={10}>내용을 확인하고</H6></Row>
                <H6 top={5}><MainBlue>동의하고 계속하기</MainBlue>를 눌러주세요</H6>
              </Column>,

              8: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>03</H6> <H6 left={10}>모든 계정을 선택해 주세요</H6></Row>
                <T2 top={13} color='#555555'>*추후 인스타그램 계정 변경이 쉬워져요</T2>
              </Column>,

              9: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>04</H6> <H6 left={10}>모든 페이지를 선택해 주세요</H6></Row>
              </Column>,

              10: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>05</H6> <H6 left={10}>Creatorly 앱에</H6></Row>
                <H6 top={5}><MainBlue>모든 권한을 허용해 주세요</MainBlue></H6>
              </Column>,

              11: <Column top={12} style={{ alignItems: 'center' }}>
                <H6>모든 권한을 허용해야</H6>
                <H6 top={10}><MainBlue>정상적으로 서비스가 작동해요!</MainBlue></H6>
              </Column>,

              12: <Column top={30} style={{ alignItems: 'center' }}>
                <Row><H6 bold>06</H6> <H6 left={10}>확인 버튼을 누르면</H6></Row>
                <H6 top={5}>다음 과정을 진행할 수 있어요</H6>
              </Column>,

              13: <Column top={30} style={{ alignItems: 'center' }}>
                <H6>마지막으로! 다음 버튼을 눌러,</H6>
                <H6 top={5}>계정을 연동해 주세요!</H6>
                <T2 top={10} color='#555555'>가이드 다시 확인은 새로고침을 눌러주세요</T2>
              </Column>,

            }[guideIndex]
          }

          <FlexBox />

          {
            guideIndex < 13
              ? <Row>
                <Image src={guideIndex > 0 ? arrowLeft : arrowLeftDisabled} width={42} height={42} style={{ cursor: 'pointer' }}
                  onClick={function () { if (guideIndex > 0) setGuideIndex(guideIndex - 1) }}
                />
                <Image src={arrowRight} width={42} height={42} left={22} style={{ cursor: 'pointer' }}
                  onClick={function () { setGuideIndex(guideIndex + 1) }}
                />
              </Row>
              : <NextButton active onClick={function(){ 
                if(kid&&type){
                  LinkReauth(kid, type, navigate, 'guide');
                } else {
                  navigate('/app/register');
                }
              }}><T2>다음</T2></NextButton>
          }

        </ContentsWrapper>
      </ModalContainer>
    </Container>
  );
}

export default Guide;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px){
    height: auto;
    margin-top: 60px;
  }
`

const ModalContainer = styled.div`
  width: 416px;
  height: 663px;
  padding: 60px 0;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 733px;
    border-radius: 0px;
    padding: 40px 0;
  }
`

const ContentsWrapper = styled.div`
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
`