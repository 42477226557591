import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
	    font-style: normal;
        color: #333333;
        background: #FFFFFF;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: transparent;

        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none
    }
    
    img{
        -webkit-user-drag: none;
    }

    button{
        background: inherit; 
        border: none; 
        box-shadow: none; 
        border-radius: 0; 
        padding: 0;
        overflow: visible; 
        cursor: pointer;
    }

    p {
        white-space: pre-line; // \n 적용시키기 위해 달아줌
    }

    input, textarea {
        outline: none;
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    a {
        color: #FFFFFF; 
        text-decoration: none; 
        outline: none;
    }

`;

export default GlobalStyle;
