//React
import styled from 'styled-components';

//Assets
import radioChecked from 'assets/Application/Analysis/icons/radio-checked.svg';

//Components
import {
    Row,
    B1
} from 'styles/DesignSystem';

function CustomRadio({ radioValueList, radioValue, setRadioValue }) {

    const handleRadioOnclick = function (event) {
        setRadioValue(event.target.value);
    }

    return (

        <RadioButtonRow count={radioValueList.length}>
            {radioValueList.map((item) => (
                <LabelRow key={item}>
                    <RadioButton radioChecked={radioChecked}
                        type="radio"
                        value={item}
                        checked={radioValue===item}
                        onChange={handleRadioOnclick}
                    />
                    <RadioText color={radioValue===item ? '#333333' : '#AAAAAA'}>{item}</RadioText>
                </LabelRow>
            ))}
        </RadioButtonRow>

    );

}

export default CustomRadio;

const RadioButtonRow = styled(Row)`
    gap: ${(props) => props.count===2 ? '30px' : '20px'};

    @media (max-width: 590px){
        margin: ${(props) => props.count===2 ? '20px 15px 0 0' : '15px  0 20px 15px'};
    }
`

const LabelRow = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const RadioButton = styled.input`
    background-color: #FFFF;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #E6E6E6;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    cursor: pointer;

    &:checked{
        background: url(${(props) => props.radioChecked});
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 100%;
    }
`

const RadioText = styled(B1)`
    margin-left: 5px;

    @media (max-width: 590px){
        font-size: 14px;
        line-height: 18px;
    }
`