import React, { FormEvent, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Input } from "styles/DesignSystem";
import {
  Campaign,
  UploadSetting,
  getCampaignDataById,
  uploadCampaignContent,
} from "../api";

import Error from "pages/Error/PageError";
import regex from "constants/regex";

//assets
import meercat from "assets/images/meercat-link-complete.png";
import meercatLogoIcon from "assets/icons/meercat-logo-icon.png";
import backArrowIcon from "assets/icons/back-arrow-icon.png";

export interface UploadData {
  name?: string;
  identification?: string;
  identification2?: string;
  bank?: string;
  account?: string;
  phone?: string;
  contents_url?: string;
  identification_image?: File;
  account_image?: File;
  etc?: string;
}

export interface ApplyProps {}

export const Submit: React.FC<ApplyProps> = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(window.sessionStorage.getItem("userData"));
  const accessToken = userData?.accessToken;
  const userId = userData?.kakaoUserData?.userId;
  const campaignId = new URL(window.location.href).searchParams.get(
    "campaignId"
  );

  if (!userData) navigate(`/login?redirect_uri=${window.location.href}`);

  const [step, setStep] = useState(0);
  const [campaignData, setCampaignData] = useState<Campaign>();
  const [uploadData, setUploadData] = useState<UploadData>({
    name: "",
    identification: "",
    identification2: "",
    bank: "",
    account: "",
    phone: "",
    contents_url: "",
    identification_image: null,
    account_image: null,
    etc: "",
  });
  const [success, setSuccess] = useState(false);
  const [whetherParticipated, setWhetherParticipated] = useState(false);

  const changeUploadData = function (key: keyof UploadData, value: any) {
    setUploadData(function (currentApplicationData) {
      const newApplicationData = { ...currentApplicationData };

      newApplicationData[key] = value;

      return newApplicationData;
    });
  };

  const whetherFieldRequired = function (key: keyof UploadSetting) {
    const uploadSetting = campaignData?.upload_setting;
    return !!uploadSetting?.[key];
  };

  const handleUpload = async function () {
    try {
      await uploadCampaignContent(campaignId, uploadData);

      setSuccess(true);
      window.scrollTo({ top: 0 });
    } catch (error) {
      if (error.response.status === 403) {
        setWhetherParticipated(true);
      }
    }
  };

  const isUploadValid = useMemo(
    function () {
      if (campaignData) {
        let isValid = true;
        const requiredKeys = Object.entries(campaignData?.upload_setting)
          ?.map(function (setting) {
            const key = setting[0];
            const value = setting[1];

            if (value) return key;

            return null;
          })
          .filter((item) => item);

        if (requiredKeys.includes("contents_url")) {
          if (!uploadData["contents_url"]) {
            isValid = false;
          }
        }

        if (requiredKeys.includes("account")) {
          if (!uploadData["account"]) {
            isValid = false;
          }

          if (!uploadData["bank"]) {
            isValid = false;
          }

          if (!uploadData["account_image"]) {
            isValid = false;
          }
        }

        if (requiredKeys.includes("identification")) {
          if (!uploadData["identification"]) {
            isValid = false;
          }

          if (!uploadData["identification_image"]) {
            isValid = false;
          }
        }

        if (requiredKeys.includes("etc")) {
          if (!uploadData["etc"]) {
            isValid = false;
          }
        }

        return isValid;
      }

      return false;
    },
    [campaignData, uploadData]
  );

  useLayoutEffect(function () {
    (async function () {
      try {
        const data = await getCampaignDataById(campaignId, accessToken);

        setCampaignData(data.response);
      } catch (error) {
        if (!userData) {
          navigate(`/login?redirect_uri=${window.location.href}`);
        }
      }
    })();
  }, []);

  if (!campaignId)
    return (
      <>
        <Error />
      </>
    );

  if (!campaignData) {
    return (
      <>
        <Background />
      </>
    );
  }

  if (whetherParticipated) {
    return (
      <>
        <Background />
        <ReportDiv>
          <FlexLarge>
            <Card>
              <Padded>
                <ColumnBox style={{ minHeight: "100vh" }}>
                  <div
                    style={{
                      marginTop: "24px",
                      fontWeight: 700,
                      fontSize: 18,
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center",
                      width: "100%",
                      gap: 12,
                    }}
                  >
                    <img src={meercat} style={{ width: 180 }} />
                    <p style={{ fontSize: 16 }}>이미 제출을 완료했어요</p>
                    <button
                      style={{
                        width: 200,
                        background: "#4480F7",
                        padding: "12px 8px",
                        color: "white",
                        fontWeight: 700,
                        borderRadius: 8,
                      }}
                      onClick={function () {
                        navigate("/app/campaign");
                      }}
                    >
                      돌아가기
                    </button>
                  </div>
                </ColumnBox>
              </Padded>
            </Card>
          </FlexLarge>
        </ReportDiv>
      </>
    );
  }

  if (step === 0) {
    return (
      <>
        <Background />
        {campaignData && (
          <>
            <ReportDiv>
              <FlexLarge>
                <Card>
                  <header style={{ width: "100%", padding: "24px 24px" }}>
                    <img src={meercatLogoIcon} />
                  </header>
                  <div style={{ marginTop: 28 }} />
                  <Padded>
                    <ColumnBox style={{ minHeight: "100vh" }}>
                      <ColumnBox style={{ gap: 8 }}>
                        <h1 style={{ color: "#212121", fontSize: "28px" }}>
                          컨텐츠 업로드하기
                        </h1>
                        <p style={{ color: "#616161", fontSize: 14 }}>
                          아래 캠페인에 참여 중인 인플루언서가
                          <br />
                          콘텐츠를 제출하기 위한 페이지입니다
                        </p>
                      </ColumnBox>
                      <div style={{ marginTop: 24 }} />
                      <img
                        placeholder="image"
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        width="100%"
                        src={campaignData?.img_url}
                        onClick={function () {
                          window.open(campaignData.url);
                        }}
                      />
                      <div style={{ marginTop: 16 }} />
                      <ColumnBox
                        style={{
                          gap: 8,
                          padding: 16,
                          background:
                            "linear-gradient(0deg, rgba(147, 144, 148, 0.05), rgba(147, 144, 148, 0.05)), #FFFFFF",
                          borderRadius: 8,
                        }}
                      >
                        <p
                          style={{
                            color: "#616161",
                            fontWeight: 700,
                            fontSize: 14,
                          }}
                        >
                          {campaignData.product}
                          &nbsp;
                          {"("}
                          {campaignData.company}
                          {")"}
                        </p>
                        <p
                          style={{
                            color: "#212121",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {campaignData.title}
                        </p>
                      </ColumnBox>
                      <div style={{ marginTop: 36 }} />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            width: "100%",
                            background: "#4480F7",
                            padding: "12px 8px",
                            color: "white",
                            fontWeight: 700,
                            borderRadius: 4,
                          }}
                          onClick={function () {
                            setStep(1);
                            window.scrollTo({ top: 0 });
                          }}
                        >
                          다음
                        </button>
                      </div>
                    </ColumnBox>
                  </Padded>
                </Card>
              </FlexLarge>
            </ReportDiv>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Background />
      <ReportDiv>
        <FlexLarge>
          <Card>
            {!success && (
              <header style={{ width: "100%", padding: "24px 24px" }}>
                <img
                  src={backArrowIcon}
                  style={{ cursor: "pointer" }}
                  onClick={function () {
                    setStep(0);
                    window.scrollTo({ top: 0 });
                  }}
                />
              </header>
            )}
            <Padded>
              <ColumnBox style={{ minHeight: "100vh" }}>
                {success && (
                  <div
                    style={{
                      marginTop: "24px",
                      fontWeight: 700,
                      fontSize: 18,
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center",
                      width: "100%",
                      gap: 12,
                    }}
                  >
                    <img src={meercat} style={{ width: 180 }} />
                    <p style={{ fontSize: 16 }}>
                      결과물 업로드가 완료되었습니다!
                    </p>
                    <button
                      style={{
                        width: 200,
                        background: "#4480F7",
                        padding: "12px 8px",
                        color: "white",
                        fontWeight: 700,
                        borderRadius: 8,
                      }}
                      onClick={function () {
                        navigate("/app/campaign");
                      }}
                    >
                      돌아가기
                    </button>
                  </div>
                )}
                {!success && (
                  <>
                    <ColumnBox style={{ gap: 16 }}>
                      {whetherFieldRequired("contents_url") && (
                        <ColumnBox style={{ gap: 4 }}>
                          <Label>콘텐츠 URL</Label>
                          <p style={{ color: "#616161", fontSize: 12 }}>
                            업로드한 Instagram 콘텐츠의 링크를 복사해주세요
                          </p>
                          <Input
                            required
                            full
                            onChange={function (e) {
                              changeUploadData("contents_url", e.target.value);
                            }}
                            placeholder="https://www.instagram.com/"
                            style={{
                              width: "100%",
                              border: "1px solid #C9C5CA",
                              borderRadius: 4,
                              color: "#212121",
                              fontSize: 14,
                              padding: "10px 8px",
                              height: "max-content",
                            }}
                          />
                        </ColumnBox>
                      )}
                      {whetherFieldRequired("identification") && (
                        <>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>이름(실명)</Label>
                            <Input
                              required
                              full
                              placeholder="예) 김민지"
                              value={uploadData.name}
                              onChange={function (e) {
                                if (
                                  regex.writeKorean.test(e.target.value) ||
                                  !e.target.value
                                ) {
                                  changeUploadData("name", e.target.value);
                                }
                              }}
                              style={{
                                width: "100%",
                                border: "1px solid #C9C5CA",
                                borderRadius: 4,
                                color: "#212121",
                                fontSize: 14,
                                padding: "10px 8px",
                                height: "max-content",
                              }}
                            />
                          </ColumnBox>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>주민등록번호</Label>
                            <RowBox style={{ alignItems: "center" }}>
                              <Input
                                minLength={6}
                                maxLength={6}
                                type="tel"
                                required
                                full
                                placeholder="주민등록번호 앞 6자리"
                                value={uploadData.identification}
                                onChange={function (e) {
                                  if (
                                    regex.number.test(e.target.value) ||
                                    !e.target.value
                                  ) {
                                    console.log(1);
                                    changeUploadData(
                                      "identification",
                                      e.target.value
                                    );
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  border: "1px solid #C9C5CA",
                                  borderRadius: 4,
                                  color: "#212121",
                                  fontSize: 14,
                                  padding: "10px 8px",
                                  height: "max-content",
                                }}
                              />
                              <i style={{ margin: "0 8px" }}>{"-"}</i>
                              <Input
                                minLength={7}
                                maxLength={7}
                                required
                                full
                                type="tel"
                                placeholder="주민등록번호 뒤 7자리"
                                value={uploadData.identification2}
                                onChange={function (e) {
                                  if (
                                    regex.number.test(e.target.value) ||
                                    !e.target.value
                                  ) {
                                    changeUploadData(
                                      "identification2",
                                      e.target.value
                                    );
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  border: "1px solid #C9C5CA",
                                  borderRadius: 4,
                                  color: "#212121",
                                  fontSize: 14,
                                  padding: "10px 8px",
                                  height: "max-content",
                                }}
                              />
                            </RowBox>
                          </ColumnBox>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>신분증 사본</Label>
                            {uploadData.identification_image && (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    uploadData.identification_image
                                  )}
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    borderRadius: 4,
                                  }}
                                />
                                <p style={{ fontSize: 12, fontWeight: 700 }}>
                                  {uploadData.identification_image.name}
                                </p>
                                <div style={{ marginTop: 2 }} />
                              </>
                            )}
                            <InputLabel htmlFor="identification_image">
                              {uploadData.identification_image
                                ? "이미지 변경하기"
                                : "이미지 업로드하기"}
                            </InputLabel>
                            <Input
                              id="identification_image"
                              type="file"
                              required
                              full
                              onChange={function (e) {
                                const fileList = e.currentTarget.files;
                                const file = fileList[0];

                                changeUploadData("identification_image", file);
                              }}
                              style={{ width: 0, height: 0 }}
                            />
                          </ColumnBox>
                        </>
                      )}
                      {whetherFieldRequired("account") && (
                        <>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>계좌번호</Label>
                            <div
                              style={{ display: "flex", gap: 4, width: "100%" }}
                            >
                              <select
                                name="pets"
                                id="pet-select"
                                onChange={function (e) {
                                  changeUploadData("bank", e.target.value);
                                }}
                                style={{
                                  border: "1px solid #C9C5CA",
                                  borderRadius: 4,
                                  color: uploadData?.bank
                                    ? "#212121"
                                    : "#9E9E9E",
                                  fontSize: 12,
                                  padding: "10px 8px",
                                  height: "max-content",
                                }}
                              >
                                <option value="">은행 선택</option>
                                <option value="농협">농협</option>
                                <option value="우리">우리은행</option>
                                <option value="토스뱅크">토스뱅크</option>
                                <option value="하나은행">하나은행</option>
                                <option value="국민은행">국민은행</option>
                                <option value="카카오뱅크">카카오뱅크</option>
                                <option value="신한은행">신한은행</option>
                                <option value="IBK기업">IBK기업</option>
                                <option value="SC제일">SC제일</option>
                                <option value="대구은행">대구은행</option>
                                <option value="부산은행">부산은행</option>
                                <option value="광주은행">광주은행</option>
                                <option value="새마을금고">새마을금고</option>
                                <option value="케이뱅크">케이뱅크</option>
                              </select>
                              <Input
                                type={"tel"}
                                required
                                full
                                placeholder="‘-’ 제외한 나머지 숫자만 입력"
                                value={uploadData.account}
                                onChange={function (e) {
                                  if (
                                    regex.number.test(e.target.value) ||
                                    !e.target.value
                                  ) {
                                    changeUploadData("account", e.target.value);
                                  }
                                }}
                                style={{
                                  flex: 1,
                                  border: "1px solid #C9C5CA",
                                  borderRadius: 4,
                                  color: "#212121",
                                  fontSize: 14,
                                  padding: "10px 8px",
                                  height: "max-content",
                                }}
                              />
                            </div>
                          </ColumnBox>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>계좌번호 사본</Label>
                            {uploadData.account_image && (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    uploadData.account_image
                                  )}
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    borderRadius: 4,
                                  }}
                                />
                                <p style={{ fontSize: 12, fontWeight: 700 }}>
                                  {uploadData.account_image.name}
                                </p>
                                <div style={{ marginTop: 2 }} />
                              </>
                            )}
                            <InputLabel htmlFor="account_image">
                              {uploadData.account_image
                                ? "이미지 변경하기"
                                : "이미지 업로드하기"}
                            </InputLabel>
                            <Input
                              id="account_image"
                              type="file"
                              required
                              full
                              placeholder="test"
                              onChange={function (e) {
                                const fileList = e.currentTarget.files;
                                const file = fileList[0];

                                changeUploadData("account_image", file);
                              }}
                              style={{ width: 0, height: 0 }}
                            />
                          </ColumnBox>
                        </>
                      )}
                      {whetherFieldRequired("etc") && (
                        <>
                          <ColumnBox style={{ gap: 4 }}>
                            <Label>기타 항목</Label>
                            <Input
                              required
                              full
                              placeholder={
                                campaignData.upload_setting.etc ?? ""
                              }
                              onChange={function (e) {
                                changeUploadData("etc", e.target.value);
                              }}
                              style={{
                                flex: 1,
                                border: "1px solid #C9C5CA",
                                borderRadius: 4,
                                color: "#212121",
                                fontSize: 14,
                                padding: "10px 8px",
                                height: "max-content",
                              }}
                            />
                          </ColumnBox>
                        </>
                      )}
                      <Button
                        full
                        disabled={!isUploadValid}
                        onClick={handleUpload}
                      >
                        작성 완료
                      </Button>
                    </ColumnBox>
                  </>
                )}
              </ColumnBox>
            </Padded>
          </Card>
        </FlexLarge>
      </ReportDiv>
    </>
  );
};

const Background = styled.div`
  width: 100%;
  height: 100%;

  background: #f4f4f4;

  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 590px) {
    background: white;
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  background: #f4f4f4;
  position: relative;

  display: flex;
  justify-content: center;
`;

const FlexLarge = styled.div`
  height: 100%;
  margin: 40px 0px;

  padding: 0px 0px;

  @media (max-width: 590px) {
    margin: 0px;
  }
`;

const Card = styled.div`
  width: 100vw;
  max-width: 560px;

  display: flex;
  flex-direction: column;
  align-items: start;

  border-radius: 4px;
  background-color: white;
`;

const Padded = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 24px;
`;

const ColumnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: end;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: bold;
  color: #616161;
`;

const InputLabel = styled.label`
  width: max-content;
  padding: 10px 8px;

  border-radius: 4px;

  display: flex;
  align-items: center;

  background: #4480f7;
  color: white;
  font-weight: 400;
  font-size: 12px;

  cursor: pointer;

  &:active {
    transform: scale(0.96);
  }
`;
