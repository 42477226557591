import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Components
import Loader from 'components/Loader';
import { LinkGauth } from 'apis/Register';


function Gauth() {  

    const navigate = useNavigate();

    useEffect(() => {
    const code = new URL(window.location.href).searchParams.get('code');
    const state = new URL(window.location.href).searchParams.get('state');

        LinkGauth(code, state, navigate);
    }, []);

    return (
        <Loader/>
    );

}

export default Gauth;