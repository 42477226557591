import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import * as amplitude from "@amplitude/analytics-browser";

//assets
import life from "assets/icons/category/life.png";
import food from "assets/icons/category/food.png";
import beauty from "assets/icons/category/beauty.png";
import living from "assets/icons/category/living.png";
import travel from "assets/icons/category/travel.png";
import baby from "assets/icons/category/baby.png";
import it from "assets/icons/category/it.png";
import fashion from "assets/icons/category/fashion.png";
import books from "assets/icons/category/books.png";
import education from "assets/icons/category/education.png";
import offline from "assets/icons/category/offline.png";
import information from "assets/icons/category/information.png";
import game from "assets/icons/category/game.png";
import health from "assets/icons/category/health.png";
import business from "assets/icons/category/business.png";
import pets from "assets/icons/category/pets.png";

import check from "assets/icons/category-check.svg";
import back from "assets/icons/back-arrow-icon.png";

//api
import { loginByCodeCategory } from "apis/Login";
import { api } from "apis/Report";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "App";
import LoadingDiv from "components/LoadingDiv";
import { AMPLITUDE_EVENT } from "constants/amplitude";
import useAmplitude from "hook/useAmplitude";

const CATEGORY_TYPES = {
  LIFE: {
    name: "일상",
    image: life,
  },
  FOOD: {
    name: "음식/맛집",
    image: food,
  },
  BEAUTY: {
    name: "뷰티/미용",
    image: beauty,
  },
  LIVING: {
    name: "생활/리빙",
    image: living,
  },
  TRAVEL: {
    name: "여행",
    image: travel,
  },
  BABY: {
    name: "육아/아동",
    image: baby,
  },
  IT: {
    name: "디지털/IT",
    image: it,
  },
  FASHION: {
    name: "패션",
    image: fashion,
  },
  BOOK: {
    name: "도서",
    image: books,
  },
  EDUCATION: {
    name: "교육",
    image: education,
  },
  OFFLINE: {
    name: "방송/연예",
    image: offline,
  },
  INFORMATION: {
    name: "정보전달",
    image: information,
  },
  GAME: {
    name: "게임",
    image: game,
  },
  HEALTH: {
    name: "스포츠/헬스",
    image: health,
  },
  BUSINESS: {
    name: "비즈니스",
    image: business,
  },
  PETS: {
    name: "반려동물",
    image: pets,
  },
};

const GENDER_TYPES = {
  MALE: "남자",
  FEMALE: "여자",
  ETC: "기타",
  PRIVATE: "비공개",
};

function Category() {
  const navigate = useNavigate();
  const { handleToast } = useContext(GlobalContext);
  const { updateUserProperties } = useAmplitude();
  const snsType = window.sessionStorage.getItem("category_sns_type");
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState([]);

  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  // const [child, setChild] = useState({
  //     exist: null,
  //     age: []
  // })
  // const [pet, setPet] = useState({
  //     exist: null,
  //     type: []
  // })
  const [adType, setAdType] = useState([]);
  const [region, setRegion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const categoryClick = function (item) {
    if (category.includes(item)) {
      const copy = [...category];

      const index = copy.findIndex((category) => category === item);
      copy.splice(index, 1);

      console.log(copy);

      setCategory(copy);
    } else {
      if (category.length < 2) {
        setCategory((current) => [...current, item]);
      }
    }
  };

  // const ageClick = function(item){
  //     if(child.age.includes(item)){
  //         const copy = [...child.age]

  //         const index = copy.findIndex(age => age === item)
  //         copy.splice(index, 1)

  //         console.log(copy)

  //         setChild(current => ({
  //             exist: current.exist,
  //             age: copy
  //         }))
  //     }else{
  //         setChild(current => ({
  //             exist: current.exist,
  //             age: [...current.age, item]
  //         }))
  //     }
  // }

  // const petClick = function(item){
  //     if(pet.type.includes(item)){
  //         const copy = [...child.type]

  //         const index = copy.findIndex(type => type === item)
  //         copy.splice(index, 1)

  //         setPet(current => ({
  //             exist: current.exist,
  //             type: copy
  //         }))
  //     }else{
  //         setPet(current => ({
  //             exist: current.exist,
  //             type: [...current.type, item]
  //         }))
  //     }
  // }

  const adTypeClick = function (item) {
    if (adType.includes(item)) {
      const copy = [...adType];

      const index = copy.findIndex((adType) => adType === item);
      copy.splice(index, 1);

      setAdType(copy);
    } else {
      setAdType((current) => [...current, item]);
    }
  };

  const regionClick = function (item) {
    if (region.includes(item)) {
      const copy = [...region];

      const index = copy.findIndex((region) => region === item);
      copy.splice(index, 1);

      setRegion(copy);
    } else {
      setRegion((current) => [...current, item]);
    }
  };

  const handleSubmit = async function () {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const userData = JSON.parse(window.sessionStorage.getItem("userData"));
        const token = userData.accessToken;
        const data = {
          age: age,
          gender: GENDER_TYPES[gender],
          category_array: category.map(function (item) {
            return CATEGORY_TYPES[item].name;
          }),
          children_age_array: [],
          pet_array: [],
          ads_array: adType,
          region_array: adType.includes("방문 리뷰") ? region : [],
        };
        let sns;
        let query;

        if (snsType === "YOUTUBE") {
          query = `youtube_user_id=${userData?.youtubeUserData?._id}`;
        }

        if (snsType === "INSTAGRAM") {
          query = `alpha_user_id=${userData?.instagramUserData?._id}`;
        }

        await api.setAdPreference(snsType, query, token, data);

        const latestUserData = await api.getUserData(token);
        const newUserData = {
          accessToken: token,
          ...latestUserData.data.data,
        };

        window.sessionStorage.setItem("userData", JSON.stringify(newUserData));

        handleToast({
          type: "POSITIVE",
          desc: "카테고리 설정이 완료되었습니다",
        });
        navigate("/app/settings");
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const submitValid = useMemo(
    function () {
      return (
        category.length > 0 &&
        category.length <= 2 &&
        gender &&
        age &&
        // (child.exist === '없음' || (child.exist === '있음' && child.age.length > 0)) &&
        // (pet.exist === '없음' || (pet.exist === '있음' && pet.type.length > 0)) &&
        adType.length > 0 &&
        (!adType.includes("방문 리뷰") ||
          (adType.includes("방문 리뷰") && region.length > 0))
      );
    },
    [category, gender, age, adType, region]
  );

  useLayoutEffect(function () {
    const userData = JSON.parse(window.sessionStorage.getItem("userData"));
    const youtubeUserData = userData?.youtubeUserData;
    const instagramUserData = userData?.instagramUserData;
    const code = new URL(window.location.href).searchParams.get("code");

    if (
      ((snsType === "YOUTUBE" && !youtubeUserData) ||
        (snsType === "INSTAGRAM" && !instagramUserData)) &&
      !code
    ) {
      window.location.replace(
        `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/category&response_type=code`
      );
    }

    if (code) {
      // consume code
      (async function () {
        try {
          const response = await loginByCodeCategory(code);
          window.sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.data)
          );
          await updateUserProperties();
          await amplitude.track(AMPLITUDE_EVENT.SUCCESS_LOGIN).promise;
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  return (
    <Container>
      <ModalContainer>
        {step === 1 && (
          <>
            <TopMargin />
            <HeaderText>
              {"본인을 설명할 수 있는"}
              <br />
              <Blue>{"카테고리"}</Blue>
              {"를 선택해 주세요"}
            </HeaderText>
            <p
              style={{
                width: 320,
                fontSize: 14,
                color: "#AAAAAA",
                marginTop: 5,
              }}
            >
              ( 최대 2개 )
            </p>
            <CategoryWrapper>
              {Object.keys(CATEGORY_TYPES).map(function (item) {
                const isActive = category.includes(item);

                return (
                  <CategoryItem
                    isActive={isActive}
                    onClick={function () {
                      categoryClick(item);
                    }}
                  >
                    <img
                      style={{ maxWidth: 20 }}
                      src={CATEGORY_TYPES[item].image}
                    />
                    <p style={{ fontSize: 10 }}>{CATEGORY_TYPES[item].name}</p>
                  </CategoryItem>
                );
              })}
            </CategoryWrapper>
            <Button
              disabled={category.length === 0}
              onClick={function () {
                setStep(2);
              }}
            >
              {"다음"}
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Header>
              <img
                src={back}
                style={{ cursor: "pointer" }}
                onClick={function () {
                  setStep(1);
                }}
              />
            </Header>
            <HeaderText>
              {"맞춤형 광고 제안을 받기 위해"}
              <br />
              <Blue>{"상세 정보"}</Blue>
              {"를 설정해 주세요"}
            </HeaderText>
            <div style={{ marginTop: 40 }} />
            <SpecificWrapper>
              <Section>
                <FieldText>{"성별"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {Object.keys(GENDER_TYPES).map(function (item) {
                    const isActive = gender === item;

                    return (
                      <RadioWrapper
                        onClick={function () {
                          setGender(item);
                        }}
                      >
                        <Radio isActive={isActive} />
                        <RadioText isActive={isActive}>
                          {GENDER_TYPES[item]}
                        </RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
              </Section>
              <Section>
                <FieldText>{"연령대"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {["10대", "20대", "30대", "40대 이상", "비공개"].map(
                    function (item) {
                      const isActive = age === item;

                      return (
                        <RadioWrapper
                          onClick={function () {
                            setAge(item);
                          }}
                        >
                          <Radio isActive={isActive} />
                          <RadioText isActive={isActive}>{item}</RadioText>
                        </RadioWrapper>
                      );
                    }
                  )}
                </div>
              </Section>
              {/* <Section>
                <FieldText>{"자녀"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {["없음", "있음"].map(function (item) {
                    const isActive = child.exist === item;

                    return (
                      <RadioWrapper
                        onClick={function () {
                          setChild({
                            exist: item,
                            age: [],
                          });
                        }}
                      >
                        <Radio isActive={isActive} />
                        <RadioText isActive={isActive}>{item}</RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
                {child?.exist === "있음" && (
                  <div>
                    <p style={{color: '#999999', fontSize: 12}}>*중복 선택 가능</p>
                    <div style={{marginTop: 10}}/>
                    <ChildItemWrapper>
                      {[
                        "1~5세",
                        "6~9세",
                        "10~15세",
                        "16세~19세",
                        "20세 이상",
                      ].map(function (item) {
                        const isActive = child.age.includes(item);

                        return (
                          <ChildItem
                            isActive={isActive}
                            onClick={function () {
                              ageClick(item);
                            }}
                          >
                            {item}
                          </ChildItem>
                        );
                      })}
                    </ChildItemWrapper>
                  </div>
                )}
              </Section>
              <Section>
                <FieldText>{"반려동물"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    gap: "20px 100px",
                  }}
                >
                  {["없음", "있음"].map(function (item) {
                    const isActive = pet.exist === item;

                    return (
                      <RadioWrapper
                        onClick={function () {
                          setPet({
                            exist: item,
                            type: [],
                          });
                        }}
                      >
                        <Radio isActive={isActive} />
                        <RadioText isActive={isActive}>{item}</RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
                {pet?.exist === "있음" && (
                  <div>
                    <p style={{color: '#999999', fontSize: 12}}>*중복 선택 가능</p>
                    <div style={{marginTop: 10}}/>
                    <ChildItemWrapper>
                      {[
                        "강아지",
                        "고양이",
                        "기타",
                      ].map(function (item) {
                        const isActive = pet.type.includes(item);

                        return (
                          <ChildItem
                            isActive={isActive}
                            onClick={function () {
                                petClick(item)
                            }}
                          >
                            {item}
                          </ChildItem>
                        );
                      })}
                    </ChildItemWrapper>
                  </div>
                )}
              </Section> */}
              <Section>
                <FieldText>{"진행 가능한 광고 유형"}</FieldText>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column nowrap",
                    gap: "20px 100px",
                    marginBottom: 10,
                  }}
                >
                  {["제품 협찬", "앱/서비스 리뷰", "방문 리뷰"].map(function (
                    item
                  ) {
                    const isActive = adType.includes(item);

                    return (
                      <RadioWrapper
                        onClick={function () {
                          adTypeClick(item);
                        }}
                      >
                        <Checkbox isActive={isActive}>
                          <img style={{ zIndex: 1 }} src={check} />
                        </Checkbox>
                        <RadioText isActive={isActive}>{item}</RadioText>
                      </RadioWrapper>
                    );
                  })}
                </div>
                {adType.includes("방문 리뷰") && (
                  <>
                    <FieldText>
                      {"활동 가능 지역"}{" "}
                      <span style={{ color: "#999999", fontSize: 12 }}>
                        *중복 선택 가능
                      </span>
                    </FieldText>
                    <div>
                      <ChildItemWrapper>
                        {[
                          "서울/수도권",
                          "인천",
                          "부산",
                          "대전",
                          "대구",
                          "울산",
                          "세종",
                          "강원도",
                          "충청북도",
                          "충청남도",
                          "전라북도",
                          "전라남도",
                          "경상북도",
                          "경상남도",
                          "제주도",
                        ].map(function (item) {
                          const isActive = region.includes(item);
                          return (
                            <RegionItem
                              isActive={isActive}
                              onClick={function () {
                                regionClick(item);
                              }}
                            >
                              {item}
                            </RegionItem>
                          );
                        })}
                      </ChildItemWrapper>
                    </div>
                  </>
                )}
              </Section>
            </SpecificWrapper>
            <div style={{ marginTop: 60 }} />
            <Button
              disabled={!submitValid}
              onClick={handleSubmit}
              style={{ minHeight: 59 }}
            >
              {isLoading ? <LoadingDiv /> : "다음"}
            </Button>
            <div style={{ marginTop: 40 }} />
          </>
        )}
      </ModalContainer>
    </Container>
  );
}

export default Category;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px) {
    height: auto;
    z-index: 10;
  }
`;

const ModalContainer = styled.div`
  width: 416px;
  height: 663px;
  padding-bottom: 60px;
  background: #ffffff;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 590px) {
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 733px;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 40px;
  }
`;

const HeaderText = styled.h1`
  width: 320px;

  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
`;

const Blue = styled.span`
  color: #4480f7;
`;

const CategoryWrapper = styled.section`
  width: 320px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  justify-content: center;
`;

const CategoryItem = styled.div`
  min-width: 70px;
  min-height: 70px;

  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
        `}
`;

const Button = styled.button`
  width: 320px;

  margin-top: 20px;
  padding: 20px 0px;

  font-size: 16px;
  color: white;

  background: #4480f7;
  border-radius: 5px;

  &:disabled {
    background: #e6e6e6;
  }
`;

const Section = styled.section`
  width: 320px;

  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
`;

const FieldText = styled.p`
  width: 100%;

  font-weight: 700;
  font-size: 14px;
`;

const SpecificWrapper = styled.section`
  width: 320px;
  height: max-content;

  display: flex;
  flex-flow: column nowrap;
  gap: 60px;
`;

const RadioWrapper = styled.div`
  min-width: 60px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const Radio = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 1px solid #e6e6e6;
  background: transparent;

  ${({ isActive }) =>
    isActive &&
    css`
      background: radial-gradient(
        circle at center,
        #4480f7 35%,
        transparent 40%
      );
    `}
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;

  border: 1px solid #e6e6e6;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #4480f7;
    `}
`;

const RadioText = styled.p`
  font-size: 16px;
  font-weight: 400;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #4480f7;
    `}
`;

const ChildItemWrapper = styled.section`
  width: 320px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-row-gap: 10px;
  grid-column-gap: 12px;
  justify-content: flex-start;
`;

const ChildItem = styled.div`
  min-width: 90px;
  min-height: 90px;

  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
          color: #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
          color: #aaaaaa;
        `}
`;

const RegionItem = styled.div`
  min-width: 90px;
  min-height: 55px;

  border-radius: 5px;

  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(68, 128, 247, 0.1);
          border: 1px solid #4480f7;
          color: #4480f7;
        `
      : css`
          border: 1px solid #f0f0f0;
          color: #aaaaaa;
        `}
`;

const Header = styled.header`
  width: 100%;
  height: 60px;

  padding: 20px 20px;
`;

const TopMargin = styled.div`
  margin-top: 60px;

  @media (max-width: 590px) {
    margin-top: 40px;
  }
`;
