import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import backArrowIcon from "../../../assets/icons/back-arrow-icon.png";

const Step1 = ({campaignData, setStep}) => {
  const [agree, setAgree] = useState({
    guide: false,
    information: false,
    cancel: false,
    secondary_utilization: false,
    inform_payment: false,
  });

  const askSecondaryUtilization = useMemo(() => {
    if(!campaignData) return false

    return campaignData.participation_setting.secondary_utilization
  }, [campaignData])

  const askInformPayment = useMemo(() => {
    if(!campaignData) return false

    return campaignData.participation_setting.inform_payment
  }, [campaignData])

  const isDisabled: boolean = useMemo(() => {
    if(!campaignData) return true

    const secondaryUtilizationPass = ((campaignData.participation_setting.secondary_utilization && agree.secondary_utilization) || !campaignData.participation_setting.secondary_utilization)
    const informPaymentPass = ((campaignData.participation_setting.inform_payment && agree.inform_payment) || !campaignData.participation_setting.inform_payment)

    return !(agree.guide && agree.information && agree.cancel && secondaryUtilizationPass && informPaymentPass)
  }, [campaignData, agree])

  const handleAgreeClick = useCallback(function (field: "guide" | "information" | "cancel" | "secondary_utilization" | "inform_payment") {
    setAgree((current) => {
      const copy = { ...current };

      copy[field] = !copy[field];

      return copy;
    });
  }, [])

  const handleNextClick = useCallback(() => {
    setStep(2);
  }, [setStep])

    return (
      <>
        <Background />
        {campaignData && (
          <>
            <ReportDiv>
              <FlexLarge>
                <Card>
                  <header style={{ width: "100%", padding: "24px 24px" }}>
                    <img
                      src={backArrowIcon}
                      style={{ cursor: "pointer" }}
                      onClick={function () {
                        setStep(0);
                      }}
                    />
                  </header>
                  <div style={{ marginTop: 28 }} />
                  <Padded>
                    <ColumnBox style={{ minHeight: "100vh", gap: 36 }}>
                      <h1 style={{ color: "#212121", fontSize: "28px" }}>
                        신청하기 전,
                        <br />
                        <span style={{ color: "#4480F7" }}>이것만은</span>
                        &nbsp;확인하세요
                      </h1>
                      <ColumnBox
                        style={{
                          gap: 8,
                          padding: 16,
                          background:
                            "linear-gradient(0deg, rgba(147, 144, 148, 0.05), rgba(147, 144, 148, 0.05)), #FFFFFF",
                          borderRadius: 8,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            width: "100%",
                            gap: 12,
                          }}
                        >
                          <AgreeItem
                            isChecked={agree.guide}
                            onItemClick={() => {
                              handleAgreeClick('guide')
                            }}
                            content={
                              <>
                                <span
                                  style={{
                                    color: "#4480F7",
                                    textDecoration: "underline",
                                  }}
                                  onClick={function (
                                    e: React.MouseEvent<HTMLSpanElement>
                                  ) {
                                    e.stopPropagation();
                                    window.open(campaignData?.url);
                                  }}
                                >가이드라인</span>을 확인했습니다
                              </>
                            }
                          />
                          <AgreeItem
                            isChecked={agree.information}
                            onItemClick={() => {
                              handleAgreeClick('information')
                            }}
                            content={"상세 정보(주소, 번호 등)를 명확히 기재해주세요"}
                          />
                          <AgreeItem
                            isChecked={agree.cancel}
                            onItemClick={() => {
                              handleAgreeClick('cancel')
                            }}
                            content={"최종 선정 이후 취소는 불가합니다"}
                          />
                          {askSecondaryUtilization &&
                            <AgreeItem
                              isChecked={agree.secondary_utilization}
                              onItemClick={() => {
                                handleAgreeClick('secondary_utilization')
                              }}
                              content={"게시글 포스팅 사진이 자사몰, 해당 기업의 SNS 등으로 2차 활용 될 수 있습니다"}
                            />
                          }
                          {askInformPayment &&
                            <AgreeItem
                              isChecked={agree.inform_payment}
                              onItemClick={() => {
                                handleAgreeClick('inform_payment')
                              }}
                              content={"광고비는 포스팅 3개월 후 해당 월 10일(휴일인 경우 휴일 이후)에 지급됩니다"}
                            />
                          }
                        </div>
                      </ColumnBox>
                      <button
                        style={{
                          width: "100%",
                          height: "max-content",
                          padding: "12px 16px",
                          textAlign: "center",
                          color: "white",
                          fontSize: 14,
                          fontWeight: 700,
                          background: isDisabled
                            ? "#E6E6E6"
                            : "#4480F7",
                          borderRadius: 4,
                        }}
                        onClick={handleNextClick}
                        disabled={isDisabled}
                      >
                        네, 확인했어요
                      </button>
                    </ColumnBox>
                  </Padded>
                </Card>
              </FlexLarge>
            </ReportDiv>
          </>
        )}
      </>
    );
}

export default Step1

const AgreeItem = ({isChecked, onItemClick, content}: {
  isChecked: boolean,
  onItemClick: () => void,
  content: any
}) => {

  return <RowBox
    style={{
      alignItems: "center",
      gap: 8,
      width: "100%",
      justifyContent: "flex-start",
      cursor: "pointer",
    }}
    onClick={onItemClick}
  >
    <div
      style={{
        minWidth: 24,
        minHeight: 24,
        width: 24,
        height: 24,
        border: `${
          isChecked
            ? "1px solid #4480F7"
            : "1px solid #E6E6E6"
        }`,
        borderRadius: 4,
        textAlign: "center",
      }}
    >
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5.19048L4.22581 9L11 1"
          stroke={isChecked ? "#4480F7" : "#E6E6E6"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
    <p
      style={{
        fontSize: 14,
        fontWeight: isChecked ? 700 : 400,
      }}
    >
      {content}
    </p>
  </RowBox>
}

const Background = styled.div`
  width: 100%;
  height: 100%;

  background: #f4f4f4;

  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 590px) {
    background: white;
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  background: #f4f4f4;
  position: relative;

  display: flex;
  justify-content: center;
`;

const FlexLarge = styled.div`
  height: 100%;

  padding: 0 0;
`;

const Card = styled.div`
  width: 100vw;
  max-width: 560px;

  display: flex;
  flex-direction: column;
  align-items: start;

  border-radius: 4px;
  background-color: white;
`;

const Padded = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 24px;
`;

const ColumnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: end;
`;