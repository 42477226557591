import React from "react";
import styled, { keyframes } from "styled-components";

//assets
import creator1 from "assets/images/Home/creator1.png";
import creator2 from "assets/images/Home/creator2.svg";
import creator3 from "assets/images/Home/creator3.svg";
import creator4 from "assets/images/Home/creator4.svg";

function CreatorSlide() {
  return (
    <>
      <SlideWrapper>
        <SlideLeftItem>
          <SlideImage src={creator1} />
          <SlideRightImage src={creator1} />
        </SlideLeftItem>
        <SlideRightItem>
          <SlideImage src={creator2} />
          <SlideLeftImage src={creator2} />
        </SlideRightItem>
        <SlideLeftItem>
          <SlideImage src={creator3} />
          <SlideRightImage src={creator3} />
        </SlideLeftItem>
        <SlideRightItem>
          <SlideImage src={creator4} />
          <SlideLeftImage src={creator4} />
        </SlideRightItem>
      </SlideWrapper>
    </>
  );
}

export default CreatorSlide;

const SlideWrapper = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  flex-flow: column nowrap;
  gap: 12px;

  overflow: hidden;
`;

const SlideImage = styled.img`
  height: 80px;
`;

const SlideLeftImage = styled.img`
  height: 80px;

  position: absolute;
  right: calc(100% + 12px);
`;

const SlideRightImage = styled.img`
  height: 80px;

  position: absolute;
  left: calc(100% + 12px);
`;

const slideLeft = keyframes`
0%{
  transform: translateX(0);
}
100%{
  transform: translateX(-100%);
}
`;

const slideRight = keyframes`
0%{
  transform: translateX(0);
}
100%{
  transform: translateX(100%);
}
`;

const SlideLeftItem = styled.div`
  width: max-content;
  display: flex;

  animation: ${slideLeft};
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
`;

const SlideRightItem = styled.div`
  width: max-content;
  display: flex;

  animation: ${slideRight};
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
`;
