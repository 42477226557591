//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as amplitude from "@amplitude/analytics-browser";

//GA
import ReactGA from "react-ga4";

//Marquee
import Marquee from "react-fast-marquee";

//Components
import Loader from "components/Loader";
import Connecting from "pages/Report/states/Connecting";
import RequireLinkAgain from "pages/Report/states/RequireLinkAgain";
import ReportError from "pages/Error/ReportError";

//Design System
import { Row, Column, T2, B1 } from "styles/DesignSystem";

//Assets
import speakerIcon from "assets/icons/report-rocket.svg";

import axios from "axios";
import { AMPLITUDE_EVENT } from "constants/amplitude";

const ExpiredChecker = function (now: Date, time: string) {
  const campaignExpiredTime = new Date(time); // '2022/12/25 23:00:00' 형식, ios NaN 이슈로 인한 통일
  return now > campaignExpiredTime;
};

function Campaign() {
  const userData = JSON.parse(window.sessionStorage.getItem("userData")!);
  const { instagramUserData, youtubeUserData, accessToken } = userData ?? {};
  const user = instagramUserData || youtubeUserData;
  const username = user?.user_name || user?.channel_title;

  const navigate = useNavigate();
  const [campaignState, setCampaignState] = useState("loading");
  const [errorCode, setErrorCode] = useState();
  // const [userGrade, setUserGrade] = useState(null)
  const [campaigns, setCampaigns] = useState([]);

  const checkExpire = function (end_date: string) {
    // time === expire Date object
    const nowMilisecond = new Date().getTime();
    const endMilisesond = new Date(end_date);
    endMilisesond.setHours(23);
    endMilisesond.setMinutes(59);
    endMilisesond.setSeconds(59);

    return nowMilisecond >= endMilisesond.getTime();
  };

  const onCampaignClick = async function (
    campaignId: string,
    title: string,
    url: string
  ) {
    // campaign click
    ReactGA.event({
      category: "CampaignList",
      action: title,
    });
    await amplitude.track(AMPLITUDE_EVENT.CLICK_CAMPAIGN_DETAIL, {
      campaignId: campaignId,
      title: title,
    }).promise;
    window.open(url);
  };

  useEffect(() => {
    const getCampaigns = async function () {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/api/v1/campaign`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setCampaignState("dataFetchSuccess");
        setCampaigns(response.data.response);
      } catch (error) {
        setCampaignState("dataFetchError");
      }
    };

    if (instagramUserData && !instagramUserData?.require_login_again) {
      // 인스타그램 연동하였고, 재연동 필요 없음
      getCampaigns();
      return;
    }

    if (youtubeUserData) {
      // 유튜브 연동하였음
      getCampaigns();
      return;
    }

    if (instagramUserData?.require_login_again) {
      // 인스타그램 재연동이 필요함

      setCampaignState("requireLinkAgain");
    }

    if (userData) {
      return navigate("/app/register");
    }

    navigate("/login?redirect_uri=report", { replace: true });
  }, []);

  useEffect(() => {
    // ga
    ReactGA.set({ userId: username });
    ReactGA.event({
      category: "meercat",
      action: "CampaignPage",
    });
    amplitude.track(AMPLITUDE_EVENT.VIEW_CAMPAIGN);
  }, []);

  return (
    <CampaignDiv>
      {/* {userType === "youtube" && <YoutubeReportNotReady />} */}
      {/* {userType === "instagram" && */}
      {
        {
          //로딩중
          loading: <Loader />,

          //재연동 필요
          requireLinkAgain: <RequireLinkAgain />,

          //SNS 연결 중
          connecting: <Connecting service="campaign" />,

          //통신 에러
          dataFetchError: <ReportError errorCode={errorCode} />,

          //캠페인 리스트
          dataFetchSuccess: (
            <>
              <MarqueeContainer>
                <Marquee
                  style={{
                    background: "#4480F7",
                    color: "#FFFFFF",
                    height: "50px",
                    width: "100%",
                  }}
                  gradient={false}
                  speed={50}
                >
                  <Row left={300}>
                    <SpeakerIcon src={speakerIcon} />
                    <T2>캠페인 선정시 개별 연락을 통해 안내해드립니다</T2>
                  </Row>
                  <Row left={300}>
                    <SpeakerIcon src={speakerIcon} />
                    <T2>캠페인 선정시 개별 연락을 통해 안내해드립니다</T2>
                  </Row>
                  <Row left={300}>
                    <SpeakerIcon src={speakerIcon} />
                    <T2>캠페인 선정시 개별 연락을 통해 안내해드립니다</T2>
                  </Row>
                  <Row left={300}>
                    <SpeakerIcon src={speakerIcon} />
                    <T2>캠페인 선정시 개별 연락을 통해 안내해드립니다</T2>
                  </Row>
                </Marquee>
              </MarqueeContainer>

              <CampaignContainer>
                <NoticeContainer>
                  <Row>
                    <SpeakerIcon src={speakerIcon} />
                    <T2 color="#FFFFFF">
                      캠페인 선정시 개별 연락을 통해 안내해드립니다
                    </T2>
                  </Row>
                </NoticeContainer>
                {campaigns
                  ?.map(function (campaign, index, array) {
                    const campaignId = campaign?._id;
                    const title = campaign.title;
                    const imgUrl = campaign.img_url;
                    const url = campaign.typeform_url ?? campaign.url;
                    const end_date = campaign.end_date;
                    const endDate = new Date(end_date);
                    console.log(campaign);

                    if (title === "test") return;

                    return (
                      <CampaignColumn
                        first={index === 0}
                        key={"campaign" + index}
                        onClick={function () {
                          if (!checkExpire(end_date)) {
                            onCampaignClick(campaignId, title, url);
                          } else {
                            // expired
                          }
                        }}
                      >
                        {checkExpire(end_date) && (
                          <CampaignImageSellected>
                            종료된 캠페인입니다
                          </CampaignImageSellected>
                        )}
                        <CampaignImage src={imgUrl} />
                        <CustomT2>{title}</CustomT2>
                        <B1 static color="#777777" top={6}>
                          {"신청 가능 기간:"}&nbsp;{endDate.getFullYear()}
                          {"년"}&nbsp;{endDate.getMonth() + 1}
                          {"월"}&nbsp;{endDate.getDate()}
                          {"일"}&nbsp;{"23:59까지"}
                        </B1>
                      </CampaignColumn>
                    );
                  })
                  ?.reverse()}
              </CampaignContainer>
            </>
          ),
        }[campaignState]
      }
    </CampaignDiv>
  );
}

export default Campaign;

const CampaignDiv = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #f4f4f4;
  position: relative;
  //display: flex;
  //justify-content: center;

  @media (max-width: 590px) {
    min-height: calc(100vh - 50px);
  }
`;

const MarqueeContainer = styled.div`
  @media (max-width: 590px) {
    display: none;
  }
`;

const SpeakerIcon = styled.img<{ src?: any }>`
  width: 16px;
  margin-right: 8px;
`;

const CampaignContainer = styled.div`
  width: 100%;
  padding: 40px 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: 590px) {
    margin-top: 50px;
    padding: 30px 20px 120px 20px;
    justify-content: center;
    gap: 0;
  }
`;

const NoticeContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #4480f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 591px) {
    display: none;
  }
`;

const CampaignColumn = styled(Column)<{ first?: any }>`
  width: 320px;
  cursor: pointer;
  position: relative;

  @media (max-width: 590px) {
    width: 100%;
    margin-top: ${(props) => (props.first ? "20px" : "40px")};
  }
`;

const CampaignImage = styled.img`
  width: 320px;
  height: 160px;
  border-radius: 5px;

  @media (max-width: 590px) {
    width: 100%;
    height: auto;
  }
`;

export const CampaignImageSellected = styled.div`
  width: 320px;
  height: 160px;
  border-radius: 5px;
  background: #000000bb;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  @media (max-width: 590px) {
    width: 100%;
    height: calc((100vw - 40px) / 2);
  }
`;

export const CustomT2 = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 13px;
`;
