import { api } from "apis/Report"

const refreshUserData = async function(token){
    try{
        const response = await api.getUserData(token)
        const latestUserData = {
          accessToken: token,
          ...response.data.data
        }

        window.sessionStorage.setItem('userData', JSON.stringify(latestUserData))

        return response.data.data
    }catch(error){
        throw error
    }
}

export default refreshUserData