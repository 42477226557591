//React
import styled from 'styled-components'

//Marquee
import Marquee from "react-fast-marquee";

//Design System
import {
  T2,
} from 'styles/DesignSystem';

//Components
function Community() {

  return (
    <>
      <Marquee style={{
        background: '#4480F7',
        color: '#FFFFFF',
        height: '40px',
        width: '100%',
      }}
        gradient={false}
        speed={50}
      >
        <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
        <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
        <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
        <T2 left={300}>🚨&nbsp;&nbsp;해당 페이지는 샘플 페이지입니다&nbsp;&nbsp;🚨</T2>
      </Marquee>
      <Iframe src={`https://cafe.meercat.io/community`} />
    </>
  )

}

export default Community;

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100% - 40px);
  border: none;
`