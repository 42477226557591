import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { DateTime } from "luxon";

const GENDER = {
  남자: "Male",
  여자: "Female",
};

function useAmplitude() {
  async function updateUserProperties() {
    try {
      const identifyEvent = new amplitude.Identify();
      const userData = window.sessionStorage.getItem("userData");

      if (!userData) {
        throw new Error("userData 없음");
      }

      const parsedUserData = JSON.parse(userData);
      const kakaoUserData = parsedUserData?.kakaoUserData;
      const instagramUserData = parsedUserData?.instagramUserData;
      const youtubeUserData = parsedUserData?.youtubeUserData;

      if (kakaoUserData) {
        amplitude.setUserId(kakaoUserData?.userId);
        identifyEvent.set("is_login", true);
        identifyEvent.set("username", kakaoUserData?.nickname);
      }

      if (kakaoUserData?.gender) {
        identifyEvent.set("gender", GENDER?.[kakaoUserData?.gender]);
      } else if (instagramUserData?.gender) {
        identifyEvent.set("gender", GENDER?.[instagramUserData?.gender]);
      } else if (youtubeUserData?.gender) {
        identifyEvent.set("gender", GENDER?.[youtubeUserData?.gender]);
      }

      if (instagramUserData) {
        identifyEvent.set("instagram_id", instagramUserData.user_name);
      }

      if (instagramUserData && !instagramUserData?.require_login_again) {
        identifyEvent.set("instagram_connected", true);
      } else {
        identifyEvent.set("instagram_connected", false);
      }

      if (instagramUserData?.total_follower) {
        identifyEvent.set(
          "instagram_follower",
          instagramUserData?.total_follower
        );
      }

      if (youtubeUserData) {
        identifyEvent.set("youtube_id", youtubeUserData?.channel_title);
      }

      if (youtubeUserData && !!youtubeUserData?.access_token) {
        identifyEvent.set("youtube_connected", true);
      } else {
        identifyEvent.set("youtube_connected", false);
      }

      if (youtubeUserData?.subscriber_count) {
        identifyEvent.set(
          "youtube_subscriber",
          youtubeUserData?.subscriber_count
        );
      }

      return amplitude.identify(identifyEvent).promise;
    } catch (error) {
      throw error;
    }
  }

  return { updateUserProperties };
}

export default useAmplitude;
