// React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

//GA
import ReactGA from "react-ga4";

//Components
import { loginByKauthCode } from "apis/Login";
import Loader from "components/Loader";
import LoginError from "pages/Error/LoginError";
import { AMPLITUDE_EVENT } from "constants/amplitude";
import useAmplitude from "hook/useAmplitude";

function Login() {
  const navigate = useNavigate();
  const code = new URL(window.location.href).searchParams.get("code");
  const redirect_uri = new URL(window.location.href).searchParams.get(
    "redirect_uri"
  );
  if (redirect_uri) {
    window.sessionStorage.setItem("redirect_uri", redirect_uri);
  }
  const session_redirect_uri = window.sessionStorage.getItem("redirect_uri");
  const [errorCode, setErrorCode] = useState();
  const { updateUserProperties } = useAmplitude();

  useEffect(() => {
    const getUserData = async function () {
      try {
        const response = await loginByKauthCode(code);
        window.sessionStorage.setItem(
          "userData",
          JSON.stringify(response.data.data)
        );

        await updateUserProperties();
        await amplitude.track(AMPLITUDE_EVENT.SUCCESS_LOGIN).promise;

        if (
          (session_redirect_uri &&
            session_redirect_uri?.match("app/campaign/apply")) ||
          session_redirect_uri?.match("app/campaign/upload")
        ) {
          window.sessionStorage.removeItem("redirect_uri");
          window.location.href = session_redirect_uri;

          return;
        }

        if (
          !response.data.data?.instagramUserData &&
          !response.data.data?.youtubeUserData
        ) {
          navigate(`/app/register`, { replace: true });
          return;
        }

        const { instagramUserData, youtubeUserData } = response.data.data;
        const username =
          instagramUserData?.user_name || youtubeUserData?.channel_title;

        ReactGA.set({ userId: username });
        ReactGA.event({
          category: "meercat",
          action: "Login",
        });

        setTimeout(function () {
          if (redirect_uri) {
            window.location.replace(
              `${process.env.REACT_APP_FRONTEND_HOST}/app/${redirect_uri}`
            );
          } else if (session_redirect_uri) {
            window.sessionStorage.removeItem("redirect_uri");
            window.location.replace(
              `${process.env.REACT_APP_FRONTEND_HOST}/app/${session_redirect_uri}`
            );
          } else {
            window.location.replace(
              `${process.env.REACT_APP_FRONTEND_HOST}/app/report`
            );
          }
        }, 1000);
      } catch (error) {
        if (error === 401) {
          // kakaoCallbackFail 401
          setErrorCode(error);
        } else if (error === 403) {
          // notKakaoAccount 403
          navigate("/app/register", { replace: true });
        } else {
          setErrorCode(error);
        }
      }
    };

    if (code) {
      getUserData();
    } else {
      window.location.replace(
        `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/login&response_type=code`
      );
    }
  }, []);

  if (!errorCode) {
    return <Loader />;
  } else {
    return <LoginError errorCode={errorCode} />;
  }
}

export default Login;
