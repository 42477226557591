//React
import styled from 'styled-components';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
  B1,
  B2
} from 'styles/DesignSystem';

//Assets
import upIcon from 'assets/Application/Analysis/icons/difference-up-red.svg';

const PTHMStoTime = (input) => {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0, minutes = 0, seconds = 0, time;

  if (reptms.test(input)) {
    var matches = reptms.exec(input);
    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
    time = `${hours === 0 ? '' : hours + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  return time;
}

const handleVideoOnclick = function (videoId) {
  window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
}

function YoutubeAlgorithmPick({ data }) {

  //유튜브 알고리즘 PICK
  let algorithmMain = {};
  let algorithmList = [];
  if (data?.length) {
    algorithmMain = data[0];
    for (let ii = 1; ii < data.length; ii++) {
      algorithmList.push(data[ii]);
      if (algorithmList.length === 4) break;
    }
  }

  return (
    <AlgorithmPickDiv>

      <T1 bold>유튜브 알고리즘 PICK</T1>
      <B1 top={8} color='#AAAAAA'>업로드 3개월이 지난 영상 중 최근 조회수 급상승 영상</B1>

      {
        data?.length
          ? (<>
            <AlgorithmMainImageWrapper onClick={() => handleVideoOnclick(algorithmMain.videoId)}>
              <AlgorithmMainImage src={algorithmMain.thumbnails} />
              <AlgorithmMainDuration>{PTHMStoTime(algorithmMain.duration)}</AlgorithmMainDuration>
            </AlgorithmMainImageWrapper>

            <AlgorithmTitleWrapper style={{ display: '-webkit-box' }} onClick={() => handleVideoOnclick(algorithmMain.videoId)}>
              {algorithmMain.title}
            </AlgorithmTitleWrapper>
            <B1 static bold top={6}>조회수&nbsp;{parseInt(algorithmMain.totalViews).toLocaleString()}회</B1>
            <Row top={5}>
              <B2 static color='#AAAAAA'>최근 한달 동안</B2>
              <DifferenceMarker src={upIcon} />
              <B2 static bold color='#F35451'>{algorithmMain.views.toLocaleString()}회 상승</B2>
            </Row>

            {algorithmList.map((item, index) => (
              <Column key={index}>
                <HorizontalLine />

                <Row style={{ alignItems: 'flex-start' }}>
                  <Column>
                    <AlgorithmTitleWrapper style={{ display: '-webkit-box' }} onClick={() => handleVideoOnclick(item.videoId)}>
                      {item.title}
                    </AlgorithmTitleWrapper>
                    <B1 static bold top={6}>조회수&nbsp;{parseInt(item.totalViews).toLocaleString()}회</B1>
                    <Row static top={5}>
                      <B2 static color='#AAAAAA'>최근 한달 동안</B2>
                      <DifferenceMarker src={upIcon} />
                      <B2 static bold color='#F35451'>{item.views.toLocaleString()}회 상승</B2>
                    </Row>
                  </Column>

                  <FlexBox />
                  <AlgorithmListImage src={item.thumbnails} onClick={() => handleVideoOnclick(item.videoId)} />
                </Row>
              </Column>
            ))}
          </>)
          : (<NoData>데이터 없음</NoData>)
      }

    </AlgorithmPickDiv>
  );

}

export default YoutubeAlgorithmPick;

const AlgorithmPickDiv = styled.div`
  width: 464px;
  min-height: 439px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 150px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1860px){
    width: 49%;
    margin-top: 2%;
    margin-bottom: 0;
  }

  @media (max-width: 590px){
    width: 100%;
    margin-top: 0;
    padding: 20px 15px 20px 15px;
  }
`

const AlgorithmMainImageWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 36px;
`

const AlgorithmMainImage = styled.img`
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`

const AlgorithmMainDuration = styled.div`
  padding: 2px 5px;
  position: absolute;
  bottom: 17px;
  right: 15px;
  background: #00000099;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`

export const AlgorithmTitleWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  max-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 23px;
  cursor: pointer;

  @media (max-width: 590px){
    -webkit-line-clamp: 2;
    max-height: 44px;
  }
`;

const DifferenceMarker = styled.img`
  width: 8px;
  margin-left: 10px;
  margin-right: 4px;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
  margin-top: 21px;
`

const AlgorithmListImage = styled.img`
  margin-top: 18px;
  margin-left: 30px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  transform: translate(50, 50);
  object-fit: cover;
  cursor: pointer;
`

const NoData = styled(T2)`
  width: 100%;
  height: 100%;
  color: #AAAAAA;
  display: flex;
  justify-content: center;
  align-items: center;
`