//React
import styled from 'styled-components';

//Css
import {
  Image,
  SizedBox,
  Blue,
  MainTitle,
  Subscription,
  ComponentDiv,
  ComponentContainer,
} from 'pages/Landing/styles';

//Assets
import report from 'assets/images/landing/section01/report.png';
import logo from 'assets/images/landing/section01/logo-new.png';

function Section01({ whetherDesktop }) {

  return (
    <ComponentDiv whetherBackground={true}>

      <ComponentContainer center={true}>

        <SizedBox height={whetherDesktop ? '180px' : '120px'} />

        <MainTitle flexDirection='column' center={true}>
          내 채널, 인스타그램을 성장시키고 싶은
          <Blue>인플루언서라면?</Blue>
        </MainTitle>

        <Subscription top={whetherDesktop ? '20px' : '15px'} center={true}>
          Instagram, Meta, Youtube 본사와 공식 파트너십을 맺은<br />
          분석 리포트를 받아보세요!
        </Subscription>

        <SizedBox height={whetherDesktop ? '60px' : '30px'} />

        <LogoImage src={logo}/>

        <SizedBox height={whetherDesktop ? '80px' : '0px'} />

        <DesktopImage src={report}/>

      </ComponentContainer>

      <MobileImage src={report} />

    </ComponentDiv>
  );

}

export default Section01;

const LogoImage = styled(Image)`
  width: 300px;
`;

const DesktopImage = styled(Image)`
  max-width: 1050px;

  @media (max-width: 590px){
    width: 100%;
    opacity: 0;
    margin-top: 100px;
  }
`;

const MobileImage = styled(Image)`
  position: absolute;
  top: 380px;
  width: 100%;
  
  @media (min-width: 591px){
    display: none;
  }
`;