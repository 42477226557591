//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as amplitude from "@amplitude/analytics-browser";

//GA
import ReactGA from "react-ga4";

//Components
import { getInstagramReports } from "apis/Report";
import Connecting from "pages/Report/states/Connecting";
import RequireLinkAgain from "pages/Report/states/RequireLinkAgain";
import ReportError from "pages/Error/ReportError";

import YoutubeSkeleton from "pages/Report/Youtube/states/Skeleton";
import Skeleton from "pages/Report/components/Skeleton";
import Calendar from "pages/Report/elements/Calendar";
import Profile from "pages/Report/elements/Profile";
import FollowerData from "pages/Report/elements/FollowerData";
import FeedData from "pages/Report/elements/FeedData";
import MeercatRanking from "pages/Report/elements/MeercatRanking";
import RecentFeed from "pages/Report/elements/RecentFeed";
import FollowerGender from "pages/Report/elements/FollowerGender";
import FollowerAge from "pages/Report/elements/FollowerAge";
import FollowerCountry from "pages/Report/elements/FollowerCountry";
import YoutubeReportNotReady from "./states/YoutubeReportNotReady";
import Youtube from "./Youtube";

//Api
import { youtubeApi } from "apis/Report";

//hook
import useToast from "hook/useToast";
import { AMPLITUDE_EVENT } from "constants/amplitude";

function Report() {
  const sessionUserData = JSON.parse(window.sessionStorage.getItem("userData"));
  const { instagramUserData, youtubeUserData } = sessionUserData ?? {};
  const { kakaoUserData, accessToken } = sessionUserData ?? {};
  const userId = kakaoUserData?.userId;
  const user = instagramUserData || youtubeUserData;
  const username = user?.user_name || user?.channel_name;
  const require_login_again = user?.require_login_again;

  const { handleToast, component: Toast } = useToast();

  useEffect(() => {
    ReactGA.set({ userId: username });
    ReactGA.event({
      category: "meercat",
      action: "ReportPage",
    });
    amplitude.track(AMPLITUDE_EVENT.VIEW_REPORT);
  }, []);

  const navigate = useNavigate();
  const [userType, setUserType] = useState("none");
  const [instagramReportState, setInstagramReportState] = useState("skeleton");
  const [errorCode, setErrorCode] = useState();
  const [instagramData, setInstagramData] = useState([]);
  const [youtubeData, setYoutubeData] = useState(null);

  const [sellectedDateIndex, setSellectedDateIndex] = useState();
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const changeToInstagram = function () {
    if (instagramData.length) {
      setUserType("instagram");
    } else if (instagramUserData && !instagramUserData?.require_login_again) {
      handleToast({
        type: "NEGATIVE",
        desc: "아직 데이터를 수집하고 있습니다",
      });
    } else {
      // 재연동, 연동 필요함

      handleToast({
        type: "NEGATIVE",
        desc: "인스타그램 계정 연동이 필요합니다",
      });

      setTimeout(function () {
        if (Boolean(instagramUserData?.require_login_again)) {
          const kid = userId;

          if (!kid) return;

          navigate(`/app/register/reauth?kid=${kid}&type=instagram`);
        } else {
          navigate("/intent");
        }
      }, 1000);
    }
  };

  const changeToYoutube = function () {
    if (
      youtubeData.youtubeData.length ||
      youtubeData.revenueData.length ||
      youtubeData.subscriberData.length
    ) {
      setUserType("youtube");
    } else if (youtubeUserData) {
      handleToast({
        type: "NEGATIVE",
        desc: "아직 데이터를 수집하고 있습니다",
      });
    } else {
      handleToast({
        type: "NEGATIVE",
        desc: "유튜브 채널 연동이 필요합니다",
      });

      setTimeout(function () {
        navigate("/youtube-intent");
      }, 1000);
    }
  };

  useEffect(() => {
    if (instagramUserData || youtubeUserData) {
      (async () => {
        try {
          const [instagramReports, youtubeReports] = await Promise.all([
            getInstagramReports(sessionUserData?.accessToken),
            youtubeApi.getYoutubeReports(youtubeUserData?._id, accessToken),
          ]);

          if (instagramReports.data.response.length) {
            setInstagramData(instagramReports.data.response);
            setSellectedDateIndex(instagramReports.data.response.length - 1);

            if (instagramReports.data.response?.length) {
              setInstagramReportState("dataFetchSuccess");
            } else {
              if (require_login_again) {
                setInstagramReportState("requireLinkAgain");
              } else {
                setInstagramReportState("connecting");
              }
            }
          }

          if (youtubeReports) {
            setYoutubeData(youtubeReports);
          }

          if (instagramReports.data.response.length) {
            setUserType("instagram");
            return;
          }

          if (youtubeReports) {
            setUserType("youtube");
            return;
          }

          if (sessionUserData) {
            return navigate("/app/register");
          }

          navigate("/login?redirect_uri=report", { replace: true });
        } catch (error) {
          console.log(error);
        }
      })();
    } else {
      if (sessionUserData) {
        return navigate("/app/register");
      }

      navigate("/login?redirect_uri=report", { replace: true });
    }
  }, []);

  return (
    <ReportDiv>
      {Toast}
      <ReportContainer>
        {userType === "none" && <YoutubeSkeleton />}
        {userType === "youtube" && (
          <Youtube
            userData={youtubeUserData}
            youtubeData={youtubeData}
            change={changeToInstagram}
          />
        )}
        {userType === "instagram" &&
          {
            //스켈레톤 ui
            skeleton: <Skeleton />,

            //재연동 필요
            requireLinkAgain: <RequireLinkAgain />,

            //SNS 연결 중
            connecting: <Connecting service="report" />,

            //통신 에러
            dataFetchError: <ReportError errorCode={errorCode} />,

            //인스타 리포트
            dataFetchSuccess: (
              <ReportWrapper>
                <FlexLarge>
                  <Section>
                    <Calendar
                      data={instagramData}
                      setSellectedDateIndex={setSellectedDateIndex}
                      year={year}
                      month={month}
                      day={day}
                      setYear={setYear}
                      setMonth={setMonth}
                      setDay={setDay}
                    />
                    <div
                      style={{
                        marginBottom: 15,
                        minWidth: "132px",
                        height: "30px",
                        display: "flex",
                        position: "relative",
                        alignItems: "center",
                        borderRadius: "20px",
                        border: "1px solid white",
                      }}
                    >
                      <div
                        style={{
                          minWidth: "65px",
                          minHeight: 30,
                          position: "absolute",
                          left: 0,
                          background: "#4480F7",
                          borderRadius: "20px",
                        }}
                      />
                      <p
                        style={{
                          flex: 1,
                          height: "100%",
                          color: "white",
                          fontWeight: 700,
                          fontSize: 12,
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {"인스타"}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          height: "100%",
                          color: "black",
                          fontWeight: 400,
                          fontSize: 12,
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={changeToYoutube}
                      >
                        {"유튜브"}
                      </p>
                    </div>
                  </Section>
                  <Profile
                    userData={sessionUserData?.instagramUserData}
                    todayData={instagramData?.[sellectedDateIndex]}
                    yesterdayData={
                      sellectedDateIndex &&
                      instagramData?.[sellectedDateIndex - 1]
                    }
                    year={year}
                    month={month}
                    day={day}
                  />
                  <FlexSmallMain>
                    <FollowerData
                      todayData={instagramData?.[sellectedDateIndex]}
                      yesterdayData={
                        sellectedDateIndex &&
                        instagramData?.[sellectedDateIndex - 1]
                      }
                    />
                    <RecentFeed
                      userData={sessionUserData?.instagramUserData}
                      data={instagramData?.[sellectedDateIndex]}
                    />
                  </FlexSmallMain>
                  <FlexSmallMain>
                    <MeercatRanking
                      userData={sessionUserData?.instagramUserData}
                      data={instagramData?.[sellectedDateIndex]}
                    />
                    <FeedData
                      todayData={instagramData?.[sellectedDateIndex]}
                      yesterdayData={
                        sellectedDateIndex &&
                        instagramData?.[sellectedDateIndex - 1]
                      }
                    />
                  </FlexSmallMain>
                </FlexLarge>

                <VerticalLine />

                <FlexMedium>
                  <FlexSmallSub>
                    <FollowerGender
                      data={
                        instagramData?.[sellectedDateIndex]?.gender_age_raw_data
                      }
                    />
                    <FollowerAge
                      data={
                        instagramData?.[sellectedDateIndex]?.gender_age_raw_data
                      }
                    />
                  </FlexSmallSub>
                  <FollowerCountry
                    todayData={
                      instagramData?.[sellectedDateIndex]?.country_raw_data
                    }
                    yesterdayData={
                      sellectedDateIndex &&
                      instagramData?.[sellectedDateIndex - 1]?.country_raw_data
                    }
                  />
                </FlexMedium>
              </ReportWrapper>
            ),
          }[instagramReportState]}
      </ReportContainer>
    </ReportDiv>
  );
}

export default Report;

const Section = styled.section`
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;

  @media (max-width: 590px) {
    jsutify-content: space-between;
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  background: #f4f4f4;
  position: relative;
`;

const ReportContainer = styled.div`
  background: #f4f4f4;
  width: 100%;
  min-height: calc(100vh - 80px);

  @media (max-width: 590px) {
    min-height: calc(100vh - 60px);
  }
`;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;

  @media (max-width: 590px) {
    padding: 0 20px 100px 20px;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #e6e6e6;
  margin: 0 30px;

  @media (max-width: 1860px) {
    display: none;
  }
`;

const FlexLarge = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2%;

  @media (max-width: 590px) {
    gap: 0;
    margin-top: 50px;
  }
`;

const FlexMedium = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 150px;

  @media (max-width: 1860px) {
    width: 100%;
    max-width: 1066px;
    margin-top: 0;
    flex-direction: row;
    gap: 2%;
  }

  @media (max-width: 590px) {
    margin-bottom: 30px;
    flex-direction: column;
    gap: 0;
    width: 100%;
    min-width: 320px;
  }
`;

const FlexSmallMain = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px) {
    width: 100%;
    max-width: none;
  }
`;

const FlexSmallSub = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px) {
    width: 100%;
    max-width: none;
  }
`;
