const AMPLITUDE_EVENT = {
  CLICK_START: "click_start",
  COMPLETE_SIGNUP: "complete_signup",
  SUCCESS_LOGIN: "success_login",
  COMPLETE_CONNECT: "complete_connect",
  CLICK_CONNECT: "click_connect",
  CLICK_CAMPAIGN_DETAIL: "click_campaign_detail",
  VIEW_REPORT: "view_report",
  VIEW_CAMPAIGN: "view_campaign",
  VIEW_COMMUNITY: "view_community",
};

Object.freeze([AMPLITUDE_EVENT]);

export { AMPLITUDE_EVENT };
