//React
import { useState, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components'

//Css
import {
  Column,
  FlexBox,
  Image,
  H5,
  T2,
  MainBlue,
  ActionButton,
  NextButton
} from 'styles/DesignSystem';

//Components
import { LinkAccountSellect } from 'apis/Register';

function AccountSelect() {

  const kid = new URL(window.location.href).searchParams.get('kid');
  const type = new URL(window.location.href).searchParams.get('type');

  const [userData, setUserData] = useState();
  const [accountIndex, setAccountIndex] = useState();
  const [whetherRequestSent, setWhetherRequestSent] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const sessionUserData = JSON.parse(window.sessionStorage.getItem('accountData'));

  useLayoutEffect(() => {
    if (location?.state) {
      setUserData(location?.state);
      window.sessionStorage.setItem('accountData', JSON.stringify(location?.state));
    } else {
      setUserData(sessionUserData);
    }
  }, []);


  return (
    <Container>
      <ModalContainer>
        <ContentsWrapper>

          <H5>분석 리포트를 받을</H5>
          <H5 top={5}><MainBlue>인스타그램을</MainBlue> 선택해 주세요</H5>

          {userData && (<Column>
            {
              userData.businessAccounts.map((item, index) => (
                <AccountBox top={15} whetherSellected={index === accountIndex}
                  key={index}
                  onClick={() => {
                    setAccountIndex(index);
                  }}
                >
                  <Image src={item.instagram_business_account.profile_picture_url} left={20} width={30} height={30} borderRadius='50%' />
                  <T2 bold left={15}>@{item.instagram_business_account.username}</T2>
                </AccountBox>
              ))}
          </Column>)}

          <StyledT2 onClick={function () { navigate(`/app/register/reauth?kid=${kid}&type=${type}`); }}>다른 페이스북 계정으로 전환하기</StyledT2>

          <FlexBox />

          <NextButton active={accountIndex !== undefined}
            onClick={function () {
              if (accountIndex !== undefined && !whetherRequestSent) {
                LinkAccountSellect(kid, type, navigate, userData.businessAccounts[accountIndex].id, userData.businessAccounts[accountIndex].instagram_business_account.id);
                setWhetherRequestSent(true);
              }
            }}>
            <T2>다음</T2>
          </NextButton>

        </ContentsWrapper>
      </ModalContainer>
    </Container>
  );

}

export default AccountSelect;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px){
    height: auto;
    margin-top: 60px;
  }
`

const ModalContainer = styled.div`
  width: 416px;
  height: 663px;
  padding: 60px 0;
  background: #FFFFFF;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px){
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 733px;
    border-radius: 0px;
    padding: 40px 0;
  }
`

const ContentsWrapper = styled.div`
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AccountBox = styled(ActionButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${(props) => props.whetherSellected ? '#ECF2FE' : '#FFFFFF'};
  border: ${(props) => props.whetherSellected ? '1px solid #4480F7' : '1px solid #F6F6F6'};
`

const StyledT2 = styled(T2)`
  margin-top: 20px;
  color: #AAAAAA;
  cursor: pointer;
  text-decoration: underline;

  &:hover{
    color: #555555;
  }
`