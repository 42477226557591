// React
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Components
import Loader from 'components/Loader';
import { LinkKauth } from 'apis/Register';

function Kauth() {  

    const code = new URL(window.location.href).searchParams.get('code');
    const type = new URL(window.location.href).searchParams.get('state');

    const navigate = useNavigate();

    useEffect(() => {
        LinkKauth(code, type, navigate);
    }, []);

    return (
        <Loader/>
    );

}

export default Kauth;