//React
import { useState } from 'react';
import styled from 'styled-components';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps'
import { scaleLinear } from 'd3-scale';
import ReactTooltip from 'react-tooltip';

//Map
import topologyMap from 'pages/Report/elements/FollowerCountry/topology';

//Components
import DifferenceChecker from 'pages/Report/components/DifferenceChecker';
import CountryCode2to3 from 'pages/Report/elements/FollowerCountry/countryCode2to3';
import CountryCode3to2 from 'pages/Report/elements/FollowerCountry/countryCode3to2';
import CountryKoreanFlag from 'pages/Report/elements/FollowerCountry/countryKoreanFlag';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  B1,
  B2,
} from 'styles/DesignSystem';

const colorScale = scaleLinear().domain([0, 0.2]).range(['#F4F4F4', '#4480F7']);
const colorScaleHover = scaleLinear().domain([0, 0.1]).range(['#F4F4F4', '#4480F7']);

function InstagramFollowerCountry({ todayData, yesterdayData }) {

  const [tooltipContent, setTooltipContent] = useState('');

  let geoCountryData = [];
  let countryIncrease = [];
  let countryDecrease = [];

  if (todayData) {

    //countryRawData 국가별 인원수 기준으로 내림차순 정렬
    const countryRawData = Object.entries(todayData);
    countryRawData.sort(function (a, b) {
      return b[1] - a[1];
    });

    //geoData로 쓰일 데이터 형식 변환
    let countrySum = 0;
    for (let element of countryRawData) {
      countrySum += element[1];
    }
    for (let element of countryRawData) {
      geoCountryData.push(
        {
          country_code: CountryCode2to3[element[0]],
          ratio: (element[1] / countrySum)
        }
      );
    }

    //팔로워 국가 데이터 전날 데이터와 비교
    if (yesterdayData) {
      for (let element of countryRawData) {
        const countryComparison = element[1] - (yesterdayData?.[element[0]] ?? 0);
        if (countryComparison > 0) countryIncrease.push([element[0], countryComparison]);
        if (countryComparison < 0) countryDecrease.push([element[0], countryComparison]);
      }

      //전날 데이터 증가 국가 내림차순 정렬
      countryIncrease.sort(function (a, b) {
        return b[1] - a[1];
      });
      //전날 데이터 감소 국가 오름차순 정렬
      countryDecrease.sort(function (a, b) {
        return a[1] - b[1];
      });
    }
  }

  return (
    <FollowerCountryDiv>

      <T1 bold>팔로워 국가</T1>

      <GlobalMapDiv data-tip="">
        <ComposableMap
          width={400}
          height={200}
          projection="geoMercator"
          projectionConfig={{
            rotate: [0, 0, 0],
            center: [-10, 20],
            scale: 60,
          }}
        >
          <ZoomableGroup
            translateExtent={[[-50,-100],[482,241]]}
          >
            {geoCountryData.length > 0 && (
              <Geographies geography={topologyMap} >
                {({ geographies }) =>

                  geographies.map((geo) => {

                    const geoData = geoCountryData.find((element) => element.country_code === geo.id);

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: `${geoData ? colorScale(geoData['ratio']) : '#F4F4F4'}`,
                            outline: 'none',
                            cursor: 'default',
                          },
                          hover: {
                            fill: `${geoData ? colorScaleHover(geoData['ratio']) : '#DDDDDD'}`,
                            outline: 'none',
                            cursor: 'default',
                          },
                          pressed: {
                            fill: `${geoData ? colorScaleHover(geoData['ratio']) : '#DDDDDD'}`,
                            outline: 'none',
                            cursor: 'default',
                          }
                        }}
                        onMouseEnter={() => {
                          if (geoData) {
                            setTooltipContent(`${CountryKoreanFlag?.[CountryCode3to2[geo.id]]?.flag ?? ''} ${geo.properties.name}: ${(geoData['ratio'] * 100).toFixed(2)}%`);
                          } else {
                            setTooltipContent(`${CountryKoreanFlag?.[CountryCode3to2[geo.id]]?.flag ?? ''} ${geo.properties.name}`);
                          }
                        }}
                        onMouseLeave={() => {
                          setTooltipContent('');
                        }}
                      />
                    );

                  })

                }
              </Geographies>
            )}
          </ZoomableGroup>
        </ComposableMap>

        <ReactTooltip>{tooltipContent && <div style={{ margin: '4px 0' }}>{tooltipContent}</div>}</ReactTooltip>
      </GlobalMapDiv>

      <CountryDifferenceColumn>
        <CountryDifferenceRow>
          <CountryDifferenceBox>
            {
              countryIncrease[0]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryIncrease[0][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryIncrease[0][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryIncrease[0][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>

          <CountryDifferenceBox>
            {
              countryDecrease[0]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryDecrease[0][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryDecrease[0][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryDecrease[0][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>
        </CountryDifferenceRow>

        <CountryDifferenceRow>
          <CountryDifferenceBox>
            {
              countryIncrease[1]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryIncrease[1][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryIncrease[1][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryIncrease[1][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>

          <CountryDifferenceBox>
            {
              countryDecrease[1]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryDecrease[1][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryDecrease[1][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryDecrease[1][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>
        </CountryDifferenceRow>

        <CountryDifferenceRow>
          <CountryDifferenceBox>
            {
              countryIncrease[2]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryIncrease[2][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryIncrease[2][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryIncrease[2][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>

          <CountryDifferenceBox>
            {
              countryDecrease[2]
                ? (<>
                  <CountryFlag>{CountryKoreanFlag?.[countryDecrease[2][0]]?.flag ?? '🏳️'}</CountryFlag>
                  <CountryDifferenceText bold left={8}>{CountryKoreanFlag?.[countryDecrease[2][0]]?.name ?? '알 수 없음'}</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={countryDecrease[2][1]} />
                </>)
                : (<>
                  <CountryDifferenceText color="#999999">정보 없음</CountryDifferenceText>
                  <FlexBox />
                  <DifferenceChecker data={null} />
                </>)
            }
          </CountryDifferenceBox>
        </CountryDifferenceRow>
      </CountryDifferenceColumn>

      <CountryInflowOutflow inflow>
        {
          countryIncrease[0]
            ? (<>
              전날 대비 🔥&nbsp;
              <CountryDifferenceText bold color="#F35451">{CountryKoreanFlag?.[countryIncrease[0][0]]?.name ?? '알 수 없는 국가'}에서 제일 많이 유입</CountryDifferenceText>
              되고 있어요
            </>)
            : (<>
              오늘은 팔로워가 유입된 국가가 존재하지 않습니다
            </>)
        }
      </CountryInflowOutflow>

      <CountryInflowOutflow>
        {
          countryDecrease[0]
            ? (<>
              전날 대비 ⛈&nbsp;
              <CountryDifferenceText bold color="#4480F7">{CountryKoreanFlag?.[countryDecrease[0][0]]?.name ?? '알 수 없는 국가'}에서 제일 많이 이탈</CountryDifferenceText>
              되고 있어요
            </>)
            : (<>
              오늘은 팔로워가 이탈된 국가가 존재하지 않습니다
            </>)
        }
      </CountryInflowOutflow>

      <FollowerCountryNotice>* 팔로워 국가 데이터는 실시간 데이터와 차이가 있을 수 있습니다</FollowerCountryNotice>

    </FollowerCountryDiv>
  );

}

export default InstagramFollowerCountry;

const FollowerCountryDiv = styled.div`
  width: 464px;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1860px){
    margin-top: 2%;
    width: 49%;
    max-width: 523px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    margin-top: 20px;
    padding: 20px 15px 30px;
    border-radius: 5px;
  }
`

const GlobalMapDiv = styled.div`
  margin: 20px 0;
  cursor: grab;

  @media (max-width: 590px){
    margin: 10px 0;
  }
`

const CountryDifferenceColumn = styled(Column)`
  gap: 10px;

  @media (max-width: 590px){
    gap: 8px;
  }
`

const CountryDifferenceRow = styled(Row)`
  gap: 14px;

  @media (max-width: 590px){
    gap: 8px;
  }
`

const CountryDifferenceBox = styled(Row)`
  width: 50%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  padding: 0 10px;
`

const CountryInflowOutflow = styled(Row)`
  width: 100%;
  height: 34px;
  margin-top: ${(props) => props.inflow ? '15px' : '10px'};
  background: ${(props) => props.inflow ? '#FFEDED' : '#ECF2FE'};
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #555555;
  justify-content: center;

  @media (max-width: 590px){
    margin-top: 8px;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
  }
`

const CountryFlag = styled.p`
  font-size: 20px;

  @media (max-width: 590px){
    font-size: 17px;
  }
`

const CountryDifferenceText = styled(B1)`
  @media (max-width: 590px){
    font-size: 11px;
    line-height: 14px;
  }
`

const FollowerCountryNotice = styled(B2)`
  margin-top: 10px;
  color: #AAAAAA;

  @media (max-width: 590px){
    margin-top: 7px;
  }
`