//React
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//Assets
import meercatRequireLink from 'assets/images/meercat-link-fail.png';
import logoInstagram from 'assets/icons/logo-instagram.svg';

//DesignSystem
import {
  Row,
  Column,
  H6,
  T2,
  NextButton
} from 'styles/DesignSystem';

function YoutubeReportNotReady() {

  const navigate = useNavigate();

  return (
    <Container>

      <Row>
        <MeercatImage src={meercatRequireLink} />

        <Column>
          <H6 bold color='#111111'>유튜브 유저는 알림톡 리포트만 지원되요.</H6>
          <T2 color='#777777' top={8}>빠른 시일 내에 이용하실 수 있도록 할게요!</T2>
        </Column>
      </Row>

    </Container>
  );

}

export default YoutubeReportNotReady;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media (max-width: 590px){
    height: calc(100vh - 60px);
    padding-top: 0px;
    justify-content: center;
  }
`

const MeercatImage = styled.img`
  width: 60px;
  margin-right: 20px;
`

const LinkButton = styled(NextButton)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MediaIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`