//React
import styled from 'styled-components';

//Components
import {
    Row,
} from 'styles/DesignSystem';

//Assets
import upIcon from 'assets/icons/report-arrow-up.svg';
import downIcon from 'assets/icons/report-arrow-down.svg';


function DifferenceChecker({ data, left }) {

    const DifferenceMarkerChecker = ({ data }) => {
        if (!data ) {
            return null;
        } else if (data > 0) {
            return <DifferenceMarker src={upIcon} />;
        } else {
            return <DifferenceMarker src={downIcon} />;
        }
    }

    return (
        <DifferenceContainer data={data} left={left}>
            <DifferenceMarkerChecker data={data} />
            <DifferenceText data={data}>{
                (data===undefined || data===null)
                    ?'—'
                :data>0
                    ?data.toLocaleString()
                :data<0
                    ?(-data).toLocaleString()
                :data===0
                    &&'동일'
            }</DifferenceText>
        </DifferenceContainer>
    );

}

export default DifferenceChecker;

const DifferenceContainer = styled(Row)`
  padding: ${(props) => 
    (props.data===undefined || props.data===null)
        ?'1px 12px'
        :'1px 8px'
  };
  border-radius: 9px;
  margin-left: ${(props) => `${props.left}px`};
  background: ${(props) => 
    (props.data===undefined || props.data===null)
        ?'#F6F6F6'
    :props.data>0
        ?'#FFEDED'
    :props.data<0
        ?'#ECF2FE'
    :props.data===0
        &&'#EAF7F2'
  };
`

const DifferenceMarker = styled.img`
  width: 5.2px;
  margin-right: 3px;
`

const DifferenceText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => 
    (props.data===undefined || props.data===null)
        ?'#AAAAAA'
    :props.data>0
        ?'#F35451'
    :props.data<0
        ?'#4480F7'
    :props.data===0
        &&'#2AAE81'
  };
`