import React from "react";
import styled from "styled-components";

//components
import CreatorSlide from "../components/Marketing/CreatorSlide";
import CreatorVideo from "../components/Marketing/Video";

//assets
import meercatLogoIcon from "assets/icons/creatorly-logo.png";
import {
  BlueText,
  Container,
  Desciprion1,
  Description2,
  Description3,
  FixedBackground,
  Header,
  Image,
  ImageContainer,
  Section,
  Section2,
  SmallStartButton,
  StartButton,
  Title1,
  Title2,
  Title3,
  Wrapper,
} from "../components/Marketing";
import report from "assets/images/Home/report.png";
import trending from "assets/images/Home/trending.png";
import influencer from "assets/images/Home/influencer.png";
import kakao from "assets/images/Home/kakao.png";
import account from "assets/images/Home/account.png";
import reference from "assets/images/Home/reference.png";
import brand from "assets/images/Home/brand.png";

export interface Marketing2Props {
  onStart: () => void;
}

const Marketing2: React.FC<Marketing2Props> = function ({ onStart }) {
  return (
    <>
      <FixedBackground />
      <Container>
        <Wrapper>
          <Header>
            <img src={meercatLogoIcon} style={{ width: 120 }} />
            <SmallStartButton onClick={onStart}>{"가입하기"}</SmallStartButton>
          </Header>
          <Section style={{ padding: "60px 36px" }} isGradient={true}>
            <ImageContainer>
              <Image src={influencer} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 24 }} />
            <Title1>
              잘나가는 <br /> 인플루언서들의 비밀
            </Title1>
            <div style={{ marginTop: 12 }} />
            <Desciprion1>
              {"Instagram, Facebook, Youtube의"}
              <br />
              {"공식 인증 파트너, 크리에이터리"}
            </Desciprion1>
            <div style={{ marginTop: 24 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section>

          <Section background="#F5F5F5" style={{ padding: "60px 0px" }}>
            <Title3>
              {"29,114명 인플루언서의"}
              <br />
              {"이유 있는 선택"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorSlide />
            </ImageContainer>
          </Section>

          <Section2>
            <BlueText>{"광고/협찬 제안"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>
              {"힙한 브랜드의"}
              <br />
              {"콜라보 제안을 받아보세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={brand} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {"유명 브랜드 중 나와 핏한 브랜드를 선택하고"}
              <br />
              {"개성 넘치는 콘텐츠를 제작하세요"}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"트렌드 리포트"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>
              {"요즘 뜨는 트렌드를"}
              <br />
              {"빠르게 읽어보세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={trending} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {
                "어떤 콘텐츠를 올려야 할지 더 이상 고민하지 마세요. 지금 인기 키워드를 알려드려요."
              }
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"채널 인사이트"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>{"내 계정의 진짜 성장세를 알아보세요"}</Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={report} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {`팔로워 수보다 더 중요한 ‘인게이지먼트’를 확인하고, 콘텐츠별 인사이트를 분석해드려요`}
            </Description2>
          </Section2>

          <Section2>
            <BlueText>{"카카오톡 알림"}</BlueText>
            <div style={{ marginTop: 8 }} />
            <Title2>
              {"매일 밤, 카톡으로"}
              <br />
              {"슈퍼 편하게 확인하세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={kakao} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <Description2>
              {`매일 밤 10시에 카톡으로 알림이 발송됩니다`}
            </Description2>
          </Section2>

          <Section background="#F5F5F5">
            <Title3>
              {"인플루언서들의"}
              <br />
              {"생생한 후기를 들어보세요"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <CreatorVideo />
            </ImageContainer>
          </Section>

          <Section2>
            <Title2 style={{ fontSize: 22, lineHeight: "28px" }}>
              {"카카오톡 회원가입 후"}
              <br />
              {"인스타 채널을 꼭! 연동해주세요"}
            </Title2>
            <div style={{ marginTop: 12 }} />
            <Description3>
              {"Instagram 채널 연동이 완료되어야"} <br />
              {"알림을 받을 수 있습니다"}
            </Description3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={account} style={{ objectFit: "cover" }} />
            </ImageContainer>
            <div style={{ marginTop: 12 }} />
            <StartButton onClick={onStart}>가입하기</StartButton>
          </Section2>

          <Section background="#F5F5F5">
            <Title3>
              {"크리에이터를 1인 기업으로"}
              <br />
              {"크리에이터리"}
            </Title3>
            <div style={{ marginTop: 12 }} />
            <ImageContainer>
              <Image src={reference} style={{ objectFit: "cover" }} />
            </ImageContainer>
          </Section>
        </Wrapper>
      </Container>
    </>
  );
};

export default Marketing2;
