const regex = {
  email: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
  phone: /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/,
  writePhone: /^[0-9]{0,11}$/,
  instagramId:
    /^([A-Za-z0-9_.](?:(?:[A-Za-z0-9_.]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_.]))?)$/,
  number: /^[0-9]/,
  koreanName: /^[가-힣]{2,10}$/,
  writeKorean: /^[ㄱ-ㅎ가-힣]{0,10}$/,
};

Object.freeze(regex);

export default regex;
