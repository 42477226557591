import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";

//component
import { Input } from "styles/DesignSystem";
import Step1 from "./step-1";

//api
import {
  Campaign,
  ParticipationSettings,
  submitApplication,
  getCampaignDataById,
} from "../api";
import regex from "constants/regex";

//assets
import meercat from "assets/images/meercat-link-complete.png";
import meercatError from "assets/images/meercat-error.png";
import meercatLogoIcon from "assets/icons/meercat-logo-icon.png";
import backArrowIcon from "assets/icons/back-arrow-icon.png";

export interface ApplyProps {}

declare global {
  interface Window {
    ChannelIO: any;
  }
}

export const Apply: React.FC<ApplyProps> = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(window.sessionStorage.getItem("userData"));
  const accessToken = userData?.accessToken;
  const campaignId = new URL(window.location.href).searchParams.get(
    "campaignId"
  );

  if (!userData) navigate(`/login?redirect_uri=${window.location.href}`);

  const [step, setStep] = useState(0);
  const [campaignData, setCampaignData] = useState<Campaign>();
  const [applicationData, setApplicationData] = useState<{
    instagram_id?: string;
    instagram_follower?: string;
    youtube_channel_id?: string;
    youtube_subscriber?: string;
    user_name?: string;
    is_guide?: boolean;
    phone?: string;
    address?: string;
    sub_address?: string;
    visit_schedule?: string;
    etc?: string;
  }>({
    instagram_id: "",
    instagram_follower: "",
    youtube_channel_id: "",
    youtube_subscriber: "",
    user_name: "",
    is_guide: true,
    phone: "",
    address: "",
    sub_address: "",
    visit_schedule: "",
    etc: "",
  });
  const [daumVisible, setDaumVisible] = useState(false);
  const [complete, setComplete] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [whetherParticipated, setWhetherParticipated] = useState(false);
  const [whetherNoUserAggregation, setWhetherNoUserAggregation] =
    useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 값 변경
  const changeApplicationData = function (
    key: keyof (ParticipationSettings & { sub_address: string }),
    value: any
  ) {
    setApplicationData(function (currentApplicationData) {
      const newApplicationData = { ...currentApplicationData };

      if (key === "is_guide") {
        newApplicationData[key] = value;
      } else {
        newApplicationData[key] = value;
      }

      return newApplicationData;
    });
  };

  const whetherFieldRequired = function (key: keyof ParticipationSettings) {
    const participationSettings = campaignData?.participation_setting;
    return !!participationSettings?.[key];
  };

  const onSubmitClick = function () {
    setIsPopup(true);
  };

  const closePopup = function () {
    setIsPopup(false);
  };

  const handleSubmit = async function () {
    if (!isSubmitting) {
      try {
        setIsSubmitting(true);
        const data = {
          instagram_id: applicationData.instagram_id,
          instagram_follower: applicationData.instagram_follower,
          youtube_channel_id: applicationData.youtube_channel_id,
          youtube_subscriber: applicationData.youtube_subscriber,
          user_name: applicationData.user_name,
          is_guide: applicationData.is_guide,
          phone: applicationData.phone,
          address: applicationData.address + " " + applicationData.sub_address,
          zip_code: zipCode,
          visit_schedule: applicationData.visit_schedule,
          participation_etc: applicationData.etc,
        };
        await submitApplication(campaignId, accessToken, data);

        setComplete(true);
        window.scrollTo({ top: 0 });
      } catch (error) {
        if (error?.response?.data?.error?.message === "유저 조회 실패") {
          setWhetherNoUserAggregation(true);
          window.scrollTo({ top: 0 });
          return;
        } else if (error.response.status === 403) {
          setWhetherParticipated(true);
          window.scrollTo({ top: 0 });
          return;
        }
      } finally {
        setIsSubmitting(false);
        setIsPopup(false);
      }
    }
  };

  const isSubmitValid = useMemo(
    function () {
      if (campaignData) {
        let isValid = true;

        const requiredKeys = Object.entries(campaignData?.participation_setting)
          ?.map(function (setting) {
            const key = setting[0];
            const value = setting[1];

            if (key === "is_guide") return null;
            if (value) return key;

            return null;
          })
          .filter((item) => item);

        // 데이터 존재
        requiredKeys.forEach(function (key) {
          if (!applicationData[key]) {
            isValid = false;
          }
        });

        if (requiredKeys.includes("address")) {
          if (!applicationData.sub_address) {
            isValid = false;
          }
        }

        //instagram id
        if (
          requiredKeys.includes("instagram_id") &&
          !regex.instagramId.test(applicationData?.instagram_id)
        ) {
          isValid = false;
        }
        //instagram follower
        if (
          requiredKeys.includes("instagram_follower") &&
          !regex.number.test(applicationData?.instagram_follower)
        ) {
          isValid = false;
        }

        //subscriber
        if (
          requiredKeys.includes("subscriber") &&
          !regex.number.test(applicationData.youtube_subscriber)
        ) {
          isValid = false;
        }

        //name
        if (
          requiredKeys.includes("user_name") &&
          !regex.koreanName.test(applicationData.user_name)
        ) {
          isValid = false;
        }

        //phone
        if (
          requiredKeys.includes("phone") &&
          !regex.phone.test(applicationData.phone)
        ) {
          isValid = false;
        }

        return isValid;
      }

      return false;
    },
    [applicationData, campaignData]
  );

  useLayoutEffect(function () {
    (async function () {
      const [campaignResponse] = await Promise.allSettled([
        getCampaignDataById(campaignId, accessToken),
        // getUserCampaignData(campaignId, userId, accessToken),
      ]);

      if (campaignResponse.status === "fulfilled") {
        setCampaignData(campaignResponse.value.response);
      }
    })();
    (function () {
      if (userData) {
        setApplicationData({
          instagram_id: userData?.instagramUserData?.user_name ?? "",
          instagram_follower: userData?.instagramUserData?.total_follower ?? "",
          youtube_channel_id: userData?.youtubeUserData?.channel_title ?? "",
          youtube_subscriber: userData?.youtubeUserData?.subscriber_count ?? "",
          user_name: "",
          is_guide: false,
          phone: "",
          address: "",
          sub_address: "",
          visit_schedule: "",
          etc: "",
        });
      }
    })();
  }, []);

  useEffect(
    function () {
      if (!userData) navigate(`/login?redirect_uri=${window.location.href}`);
    },
    [userData]
  );

  if (!campaignData) {
    return (
      <>
        <Background />
      </>
    );
  }

  if (whetherParticipated) {
    return (
      <>
        <Background />
        <ReportDiv>
          <FlexLarge>
            <Card>
              <Padded>
                <ColumnBox style={{ minHeight: "100vh" }}>
                  <div
                    style={{
                      marginTop: "24px",
                      fontWeight: 700,
                      fontSize: 18,
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center",
                      width: "100%",
                      gap: 12,
                    }}
                  >
                    <img src={meercat} style={{ width: 180 }} />
                    <p style={{ fontSize: 16 }}>이미 신청을 완료했어요!</p>
                    <button
                      style={{
                        width: 200,
                        background: "#4480F7",
                        padding: "12px 8px",
                        color: "white",
                        fontWeight: 700,
                        borderRadius: 8,
                      }}
                      onClick={function () {
                        navigate("/app/campaign");
                      }}
                    >
                      돌아가기
                    </button>
                  </div>
                </ColumnBox>
              </Padded>
            </Card>
          </FlexLarge>
        </ReportDiv>
      </>
    );
  }

  if (whetherNoUserAggregation) {
    return (
      <>
        <Background />
        <ReportDiv>
          <FlexLarge>
            <Card>
              <Padded>
                <ColumnBox style={{ minHeight: "100vh" }}>
                  <div
                    style={{
                      marginTop: "160px",
                      fontWeight: 700,
                      fontSize: 18,
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      gap: 16,
                    }}
                  >
                    <img src={meercatError} style={{ width: 180 }} />
                    <p
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: 600,
                        marginTop: 8,
                      }}
                    >
                      캠페인 신청에 실패했습니다.
                      <br />
                      아래 버튼을 눌러 캠페인 담당자에게 문의해 주세요.
                    </p>
                    <button
                      style={{
                        width: 200,
                        background: "#4480F7",
                        padding: "12px 8px",
                        color: "white",
                        fontWeight: 700,
                        borderRadius: 8,
                      }}
                      onClick={function () {
                        window?.ChannelIO?.("showMessenger");
                      }}
                    >
                      문의하기
                    </button>
                  </div>
                </ColumnBox>
              </Padded>
            </Card>
          </FlexLarge>
        </ReportDiv>
      </>
    );
  }

  if (step === 0) {
    return (
      <>
        <Background />
        {campaignData && (
          <>
            <ReportDiv>
              <FlexLarge>
                <Card>
                  <header style={{ width: "100%", padding: "24px 24px" }}>
                    <img src={meercatLogoIcon} />
                  </header>
                  <div style={{ marginTop: 28 }} />
                  <Padded>
                    <ColumnBox style={{ minHeight: "100vh" }}>
                      <ColumnBox style={{ gap: 8 }}>
                        <h1 style={{ color: "#212121", fontSize: "28px" }}>
                          캠페인 신청하기
                        </h1>
                        <p style={{ color: "#616161", fontSize: 14 }}>
                          아래 캠페인 참여를 희망하는
                          <br />
                          인플루언서를 위한 신청 페이지입니다.
                        </p>
                      </ColumnBox>
                      <div style={{ marginTop: 24 }} />
                      <img
                        placeholder="image"
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        width="100%"
                        src={campaignData?.img_url}
                        onClick={function () {
                          window.open(campaignData.url);
                        }}
                      />
                      <div style={{ marginTop: 16 }} />
                      <ColumnBox
                        style={{
                          gap: 8,
                          padding: 16,
                          background:
                            "linear-gradient(0deg, rgba(147, 144, 148, 0.05), rgba(147, 144, 148, 0.05)), #FFFFFF",
                          borderRadius: 8,
                        }}
                      >
                        <p
                          style={{
                            color: "#616161",
                            fontWeight: 700,
                            fontSize: 14,
                          }}
                        >
                          {campaignData.product}
                          &nbsp;
                          {"("}
                          {campaignData.company}
                          {")"}
                        </p>
                        <p
                          style={{
                            color: "#212121",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {campaignData.title}
                        </p>
                        <p
                          style={{
                            color: "#616161",
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        >
                          {new Date(campaignData.end_date).getFullYear()}/
                          {String(
                            new Date(campaignData.end_date).getMonth() + 1
                          ).padStart(2, "0")}
                          /
                          {String(
                            new Date(campaignData.end_date).getDate()
                          ).padStart(2, "0")}
                          &nbsp;마감
                        </p>
                      </ColumnBox>
                      <div style={{ marginTop: 36 }} />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            width: "100%",
                            background: "#4480F7",
                            padding: "12px 8px",
                            color: "white",
                            fontWeight: 700,
                            borderRadius: 4,
                          }}
                          onClick={function () {
                            setStep(1);
                          }}
                        >
                          신청하기
                        </button>
                      </div>
                    </ColumnBox>
                  </Padded>
                </Card>
              </FlexLarge>
            </ReportDiv>
          </>
        )}
      </>
    );
  }

  if (step === 1) {
    return <Step1 campaignData={campaignData} setStep={setStep} />
  }

  return (
    <>
      <Popup isOpen={isPopup} onClose={closePopup} onSubmit={handleSubmit} />
      <Background />
      {campaignData && (
        <ReportDiv>
          <FlexLarge>
            <Card>
              {complete ? (
                <Padded>
                  <ColumnBox style={{ minHeight: "100vh" }}>
                    <div
                      style={{
                        marginTop: "24px",
                        fontWeight: 700,
                        fontSize: 18,
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                        width: "100%",
                        gap: 12,
                      }}
                    >
                      <img src={meercat} style={{ width: 180 }} />
                      <p style={{ fontSize: 16 }}>
                        캠페인 신청이 완료되었습니다!
                      </p>
                      <button
                        style={{
                          width: 200,
                          background: "#4480F7",
                          padding: "12px 8px",
                          color: "white",
                          fontWeight: 700,
                          borderRadius: 8,
                        }}
                        onClick={function () {
                          navigate("/app/campaign");
                        }}
                      >
                        돌아가기
                      </button>
                    </div>
                  </ColumnBox>
                </Padded>
              ) : (
                <>
                  <header style={{ width: "100%", padding: "24px 24px" }}>
                    <img
                      src={backArrowIcon}
                      style={{ cursor: "pointer" }}
                      onClick={function () {
                        setStep(1);
                      }}
                    />
                  </header>
                  <Padded>
                    <ColumnBox style={{ minHeight: "100vh" }}>
                      <>
                        <ColumnBox style={{ gap: 16, marginTop: 8 }}>
                          <InputComponent
                            label={"Instagram ID"}
                            placeholder="인스타그램 아이디를 입력해 주세요"
                            value={applicationData?.instagram_id}
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              if (
                                regex.instagramId.test(e.target.value) ||
                                !e.target.value
                              ) {
                                changeApplicationData(
                                  "instagram_id",
                                  e.target.value
                                );
                              }
                            }}
                            readOnly={!!userData?.instagramUserData?.user_name}
                            required={whetherFieldRequired("instagram_id")}
                          />
                          <InputComponent
                            label={"Instagram 팔로워 수"}
                            type="tel"
                            placeholder="숫자만 입력해 주세요"
                            value={applicationData?.instagram_follower}
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              if (
                                regex.number.test(e.target.value) ||
                                !e.target.value
                              ) {
                                changeApplicationData(
                                  "instagram_follower",
                                  e.target.value
                                );
                              }
                            }}
                            readOnly={
                              !!userData?.instagramUserData?.total_follower
                            }
                            required={whetherFieldRequired(
                              "instagram_follower"
                            )}
                          />
                          <InputComponent
                            label={"Youtube 채널 ID"}
                            value={applicationData?.youtube_channel_id}
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              changeApplicationData(
                                "youtube_channel_id",
                                e.target.value
                              );
                            }}
                            readOnly={
                              !!userData?.youtubeUserData?.channel_title
                            }
                            required={whetherFieldRequired(
                              "youtube_channel_id"
                            )}
                          />
                          <InputComponent
                            label={"Youtube 구독자 수"}
                            type="tel"
                            value={applicationData?.youtube_subscriber}
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              if (
                                regex.number.test(e.target.value) ||
                                !e.target.value
                              ) {
                                changeApplicationData(
                                  "youtube_subscriber",
                                  e.target.value
                                );
                              }
                            }}
                            readOnly={
                              !!userData?.youtubeUserData?.subscriber_count
                            }
                            required={whetherFieldRequired(
                              "youtube_subscriber"
                            )}
                          />
                          <InputComponent
                            label={"실명"}
                            value={applicationData?.user_name}
                            placeholder="예) 김지민"
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              changeApplicationData(
                                "user_name",
                                e.target.value
                              );
                            }}
                            readOnly={false}
                            required={whetherFieldRequired("user_name")}
                          />
                          <InputComponent
                            label={"휴대폰 번호"}
                            type="tel"
                            value={applicationData?.phone}
                            placeholder="'-'를 제외한 숫자"
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              if (
                                regex.writePhone.test(e.target.value) ||
                                !e.target.value
                              ) {
                                changeApplicationData("phone", e.target.value);
                              }
                            }}
                            readOnly={!!userData?.instagramUserData?.phone}
                            required={whetherFieldRequired("phone")}
                          />
                          <InputComponent
                            type={"date"}
                            label={"방문 예정일"}
                            value={applicationData?.visit_schedule}
                            onChange={function (
                              e: ChangeEvent<HTMLInputElement>
                            ) {
                              changeApplicationData(
                                "visit_schedule",
                                e.target.value
                              );
                            }}
                            readOnly={
                              !!userData?.instagramUserData?.visit_schedule
                            }
                            required={whetherFieldRequired("visit_schedule")}
                          />
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexFlow: "column",
                              gap: "4px",
                            }}
                          >
                            {!daumVisible ? (
                              <InputComponent
                                label={"배송지 정보"}
                                value={applicationData?.address}
                                readOnly={
                                  !!userData?.instagramUserData?.address
                                }
                                onClick={function () {
                                  setDaumVisible(true);
                                }}
                                onFocus={function () {
                                  setDaumVisible(true);
                                }}
                                required={whetherFieldRequired("address")}
                              />
                            ) : (
                              <DaumPostcode
                                onComplete={function (data: any) {
                                  const address = data.address;
                                  const zipCode = data.zonecode;

                                  changeApplicationData("address", address);
                                  setZipCode(zipCode);
                                  setDaumVisible(false);
                                }}
                              />
                            )}
                            {applicationData.address && (
                              <InputComponent
                                placeholder={"상세 주소를 입력해 주세요"}
                                value={applicationData?.sub_address}
                                onChange={function (
                                  e: ChangeEvent<HTMLInputElement>
                                ) {
                                  changeApplicationData(
                                    "sub_address",
                                    e.target.value
                                  );
                                }}
                                required={true}
                              />
                            )}
                          </div>
                          {whetherFieldRequired("etc") && (
                            <ColumnBox style={{ gap: 4 }}>
                              <Label>
                                {campaignData?.participation_setting.etc}
                              </Label>
                              <textarea
                                value={applicationData?.etc}
                                onChange={function (
                                  e: ChangeEvent<HTMLTextAreaElement>
                                ) {
                                  changeApplicationData("etc", e.target.value);
                                }}
                                readOnly={!!userData?.instagramUserData?.etc}
                                spellCheck={false}
                                placeholder={`${campaignData?.participation_setting.etc}`}
                                style={{
                                  width: "100%",
                                  minHeight: "120px",
                                  border: "1px solid #C9C5CA",
                                  borderRadius: 4,
                                  color: "#212121",
                                  fontSize: 14,
                                  padding: "10px 8px",
                                  height: "max-content",
                                  fontWeight:
                                    !!userData?.instagramUserData?.etc ||
                                    !!applicationData?.etc
                                      ? 600
                                      : 400,
                                  outline: "none",
                                  resize: "none",
                                  whiteSpace: "pre-line",
                                }}
                              />
                            </ColumnBox>
                          )}
                          <button
                            style={{
                              width: "100%",
                              height: "max-content",
                              padding: "12px 16px",
                              textAlign: "center",
                              color: "white",
                              fontSize: 14,
                              fontWeight: 700,
                              background: isSubmitValid ? "#4480F7" : "#E6E6E6",
                              borderRadius: 4,
                            }}
                            onClick={onSubmitClick}
                            disabled={!isSubmitValid}
                          >
                            작성 완료
                          </button>
                        </ColumnBox>
                      </>
                    </ColumnBox>
                  </Padded>
                </>
              )}
            </Card>
          </FlexLarge>
        </ReportDiv>
      )}
    </>
  );
};

const InputComponent = function ({
  label,
  type = "text",
  value,
  onChange,
  onClick,
  onFocus,
  readOnly,
  required,
  placeholder,
}: {
  label?: string;
  type?: string;
  value?: string | number;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e?: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  placeholder?: string;
}) {
  return (
    <>
      {required && (
        <ColumnBox style={{ gap: 4 }}>
          <Label>
            {label}
            {readOnly && (
              <span style={{ color: "#C9C5CA", fontSize: 12, marginLeft: 4 }}>
                (변경 불가)
              </span>
            )}
          </Label>
          <Input
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            readOnly={readOnly}
            spellCheck={false}
            style={{
              width: "100%",
              border: "1px solid #C9C5CA",
              borderRadius: 4,
              color: "#212121",
              fontSize: 14,
              padding: "10px 8px",
              height: "max-content",
              fontWeight: readOnly || !!value ? 600 : 400,
            }}
          />
        </ColumnBox>
      )}
    </>
  );
};

const Popup = function ({ isOpen, onSubmit, onClose }) {
  return (
    <>
      {isOpen && (
        <>
          <PopupBackground />
          <PopupWrapper>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div onClick={onClose} style={{ cursor: "pointer" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 18L18 2M2 2L18 18"
                    stroke="#333333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div style={{ marginTop: 8 }} />
            <ColumnBox style={{ gap: 12 }}>
              <h1 style={{ fontSize: 18 }}>
                최종 선정 이후에는 <br />
                취소가 불가능합니다
              </h1>
              <p style={{ color: "#212121", fontSize: 14 }}>
                선정 이후 연락 두절, 업로드 불이행 등의 이슈 발생 시 민형사상
                책임을 질 수 있으니 각별히 주의해주세요
              </p>
            </ColumnBox>
            <div style={{ marginTop: 24 }} />
            <button
              style={{
                width: "100%",
                height: "max-content",
                padding: "12px 16px",
                textAlign: "center",
                color: "white",
                fontSize: 14,
                fontWeight: 700,
                background: "#C62828",
                borderRadius: 4,
              }}
              onClick={onSubmit}
            >
              네, 확인했어요
            </button>
          </PopupWrapper>
        </>
      )}
    </>
  );
};

const PopupBackground = styled.div`
  width: 100%;
  height: 100%;

  background: rgb(0, 0, 0, 0.5);

  position: fixed;
  left: 0;
  top: 0;

  z-index: 10;
`;

const PopupWrapper = styled.div`
  width: 80%;
  max-width: 360px;
  height: max-content;

  padding: 28px 24px;

  background: white;
  border-radius: 8px;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;

  background: #f4f4f4;

  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 590px) {
    background: white;
  }
`;

const ReportDiv = styled.div`
  width: 100%;
  background: #f4f4f4;
  position: relative;

  display: flex;
  justify-content: center;
`;

const FlexLarge = styled.div`
  height: 100%;

  padding: 0px 0px;
`;

const Card = styled.div`
  width: 100vw;
  max-width: 560px;

  display: flex;
  flex-direction: column;
  align-items: start;

  border-radius: 4px;
  background-color: white;
`;

const Padded = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 24px;
`;

const ColumnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: end;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: bold;
  color: #616161;

  white-space: pre;
`;
