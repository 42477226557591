//React
import React, { useState, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components'

//GA
import ReactGA from "react-ga4";

//Css
import {
  Row,
  Image,
  H5,
  T2,
  MainBlue,
  NextButton
} from 'styles/DesignSystem';

//Assets
import meercat from 'assets/images/meercat-link-complete.png';
import logoInstagram from 'assets/icons/logo-instagram.svg';
import logoYoutube from 'assets/icons/youtube-color.png';
import categoryIcon from 'assets/icons/category-button-icon.svg'

function LinkComplete() {
  const [userData, setUserData] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const handleCategoryClick = function(){
    const sns = location?.state.sns === 'youtube' ? 'YOUTUBE' : 'INSTAGRAM'

    window.sessionStorage.setItem('category_sns_type', sns)
    navigate(`/app/category`)
  }

  useLayoutEffect(() => {
    setUserData(location?.state);
    ReactGA.set({ userId: location?.state?.data?.username });
    ReactGA.event({
      category: 'meercat',
      action: 'ReLink',
    });
  }, []);

  return (
    <Container>
      <ModalContainer>

        <H5><MainBlue>연동이 완료됐어요!</MainBlue></H5>
        <T2 top={10} color='#555555'>2일 뒤부터 리포트를 받아볼 수 있어요</T2>

        <Image src={meercat} width={120} height={144} top={40} />

        <AccountInformationBox top={20}>
          <T2 color='#555555'>SNS</T2>
          {location?.state.sns === 'youtube' ? <Row>
            <Image src={logoYoutube} width={16} height={16} />
            <T2 bold color='#555555' left={5}>유튜브</T2>
          </Row> :<Row>
            <Image src={logoInstagram} width={16} height={16} />
            <T2 bold color='#555555' left={5}>인스타그램</T2>
          </Row>}
        </AccountInformationBox>

        <AccountInformationBox top={10}>
          <T2 color='#555555'>계정</T2>
          {userData?.data?.username && (
            <T2 bold color='#555555'>@{userData?.data?.username}</T2>
          )}
        </AccountInformationBox>

        <NextButton 
          active 
          top={20}
          height={60}
          onClick={function () {

            window.sessionStorage.removeItem('userData');
            window.sessionStorage.removeItem('accountData');
            window.sessionStorage.removeItem('instagramData');
            window.sessionStorage.removeItem('youtubeData');

            navigate('/login');

          }}
        >
          <T2>닫기</T2>
        </NextButton>
        <CategoryButton click={handleCategoryClick}/>

      </ModalContainer>
    </Container>
  );
}

export default LinkComplete;

const CategoryButton = function({click}){
  return (
    <CategoryButtonWrarpper onClick={click}>
      <img src={categoryIcon} />
      <div style={{display: 'flex', flexFlow: 'column nowrap', gap: 5}}>
        <h1 style={{fontSize: 14, fontWeight: 700}}>협찬 받고 싶은 인플루언서이신가요?</h1>
        <p style={{fontSize: 12, fontWeight: 400}}>이 버튼을 클릭 후에 카테고리 등 상세정보를 작성해 주시면, 협찬을 제안해드려요!</p>
      </div>
    </CategoryButtonWrarpper>
  )
}

const CategoryButtonWrarpper = styled.div`
  width: 320px;

  padding: 16px 20px;
  margin-top: 20px;
  
  border: 1px solid #4480F7;
  border-radius: 5px;

  display: flex;
  align-items: center;
  gap: 20px;

  cursor: pointer;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  flex: 1;

  @media (max-width: 590px){
    height: auto;
    z-index: 10;
  }
`

const ModalContainer = styled.div`
  width: 416px;
  height: 663px;
  padding: 60px 0 40px 0;
  background: #FFFFFF;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px){
    width: 100%;
    // height: calc(100vh - 60px);
    min-height: 733px;
    border-radius: 0px;
    padding: 40px 0;
  }
`

const AccountInformationBox = styled.div`
  width: 320px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  padding: 0 20px;
  margin-top: ${(props) => `${props.top}px`};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

`