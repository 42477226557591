//React
import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';

//react-date-range
import { Calendar } from 'react-date-range'; 
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ko from 'date-fns/locale/ko';	     // 날짜 포맷 라이브러리 (한국어 기능 임포트)

//Design System
import {
  Row,
  FlexBox,
  Image,
  H6,
  B1,
  Column
} from 'styles/DesignSystem';

//Assets
import calendarIcon from 'assets/Application/Analysis/icons/calendar.svg';

const Under10append0 = function(num) {
  if(num<10){
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

const SameDateChecker = function(date1, date2) {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

function YoutubeCalendar({data, selectedDateIndex, setSelectedDateIndex, year, month, day, setYear, setMonth, setDay}) {

  const [whetherCalendarOpened, setWhetherCalendarOpened] = useState(false);
  const [sellectedDate, setSellectedDate] = useState();

  const dateList = [];
  const disabledDates = [];
  
  for (let i = 0; i < data.length; i++) {
    const createdAt = new Date(data[i].created_at.replaceAll('-','/').replaceAll('T',' ').replaceAll('Z','').substr(0, 19));
    dateList.push(new Date(`${createdAt.getFullYear()}/${createdAt.getMonth()+1}/${createdAt.getDate()}`));
  }

  const diffDate = Math.abs((dateList[0].getTime() - dateList[dateList.length - 1].getTime()) / (1000 * 60 * 60 * 24));
  
  // 데이터 없는 날짜 구하는 로직
  let standardDate = dateList[0];
  for (let ii = 0; ii <= diffDate; ii++) {
    let whetherExist = false;
    for (let eachDate of dateList) {
      if(SameDateChecker(eachDate, standardDate)){
        whetherExist = true;
      }
    }
    
    if(!whetherExist){
      disabledDates.push(standardDate);
    }
    standardDate = new Date(standardDate.getTime() + (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    const today = dateList[dateList.length-1];
    setSellectedDate(today);

    const analysisPoint = new Date(today);

    analysisPoint.setDate(analysisPoint.getDate() - 3);
    setYear(analysisPoint.getFullYear());
    setMonth(Under10append0(analysisPoint.getMonth()+1));
    setDay(Under10append0(analysisPoint.getDate()));
  }, []);

  const onChangeDate = useCallback((date) => {
    setSellectedDate(date);
    setWhetherCalendarOpened(false);

    for (let i = 0; i < dateList.length; i++) {
      if(SameDateChecker(date, dateList[i])){
        setSelectedDateIndex(i);
        const analysisPoint = dateList[i];
        analysisPoint.setDate(analysisPoint.getDate() - 3);
        setYear(analysisPoint.getFullYear());
        setMonth(Under10append0(analysisPoint.getMonth()+1));
        setDay(Under10append0(analysisPoint.getDate()));
        return null;
      }
    }

    alert('해당 날짜에는 데이터가 존재하지 않습니다.');

  }, [sellectedDate]);

  return (
    <CalendarDiv>
      <Column style={{ width: '100%' }}>
        <Row>
          <Row style={{ cursor: 'pointer', minWidth: 145 }} onClick={() => { setWhetherCalendarOpened(!whetherCalendarOpened); }}>
            <H6 bold>{`${dateList[selectedDateIndex].getFullYear()}.${Under10append0(dateList[selectedDateIndex].getMonth() + 1)}.${Under10append0(dateList[selectedDateIndex].getDate())}`}</H6>
            <Image src={calendarIcon} left={10} width={16} />
          </Row>
          <FlexBox />
          {/* <Notice>분석 리포트는 오후 2시에 업데이트 됩니다.</Notice> */}
        </Row>

        <NoticeMobile static>분석 리포트는 오후 2시에 업데이트 됩니다.</NoticeMobile>
      </Column>


      { whetherCalendarOpened &&
        (<CalendarContainer>
          <Calendar
            locale={ko}
            months={1}
            minDate={dateList[0]}
            maxDate={dateList[dateList.length-1]}
            disabledDates={disabledDates}
            date={sellectedDate}	// selectedDate
            onChange={onChangeDate}
            dateDisplayFormat={'yyyy.mm.dd'}
            style={{}}
          />
        </CalendarContainer>)
      }

    </CalendarDiv>
  );

}

export default YoutubeCalendar;

const CalendarDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 80px;
  background: transparent;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 12px;

  @media (max-width: 590px){
    height: auto;
    margin-top: 40px;
  }
`

const Notice = styled(B1)`
  color: #999999;

  @media (max-width: 590px){
    display: none;
  }
`

const NoticeMobile = styled(B1)`
  display: none;

  @media (max-width: 590px){
    display: block;
    color: #999999;
    margin-top: 6px;

    font-size: 11px;
  }
`

const CalendarContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 280px;

  top: 76px;
  left: 22px;
  border: 1.5px solid #4480F7;
  border-radius: 5px;
  background: #FFFFFF;
  overflow: hidden;

  @media (max-width: 590px){
    height: 280px;
    top: 90px;
    left: 20px;
  }

  @media (max-width: 374px){
    width: 320px;
  }
`