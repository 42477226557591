//React
import styled from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  B1,
} from 'styles/DesignSystem';

ChartJS.register(ArcElement, Tooltip);

function InstagramFollowerGender({ data }) {

  let genderSum = [0, 0, 0];
  if(data?.male?.length){
    for (let element of data.male) {
      genderSum[0] += element.count
    }
  }
  if(data?.female?.length){
    for (let element of data.female) {
      genderSum[1] += element.count
    }
  }
  if(data?.unknown?.length){
    for (let element of data.unknown) {
      genderSum[2] += element.count
    }
  }

  let totalSum = 0;
  for (let element of genderSum) {
    totalSum += element
  }

  const doughnutData = {
    labels: ['남성', '여성', '비공개'],
    datasets: [
      {
        data: genderSum,
        backgroundColor: ['#4F5CD9', '#F35451', '#CCCCCC'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptionsDefault = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return ' ' + context.label + ': ' + context.parsed.toLocaleString() + '명';
          },
        }
      }
    }
  };

  return (
    <FollowerGenderDiv>
      <T1 bold>팔로워 성별</T1>

      <FollowerGenderDataWrapper>
        <DoughnutDiv>
          <Doughnut data={doughnutData} options={doughnutOptionsDefault} />
        </DoughnutDiv>

        <FlexBox />

        <DataDiv>

          <Row>
            <GenderColor color='#4F5CD9' />
            <StaticB1 color='#777777'>남성</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#4F5CD9'>{totalSum ? (genderSum[0]/totalSum*100).toFixed(1) : 0}%</StaticB1>
          </Row>


          <HorizontalLine />

          <Row>
            <GenderColor color='#F35451' />
            <StaticB1 color='#777777'>여성</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#F35451'>{totalSum ? (genderSum[1]/totalSum*100).toFixed(1) : 0}%</StaticB1>
          </Row>


          <HorizontalLine />

          <Row>
            <GenderColor color='#CCCCCC' />
            <StaticB1 color='#777777'>비공개</StaticB1>
            <FlexBox />
            <StaticB1 bold color='#AAAAAA'>{totalSum ? (100 - (genderSum[0]/totalSum*100) - (genderSum[1]/totalSum*100)).toFixed(1) : 0}%</StaticB1>
          </Row>

        </DataDiv>


      </FollowerGenderDataWrapper>

    </FollowerGenderDiv>
  );

}

export default InstagramFollowerGender;

const FollowerGenderDiv = styled.div`
  width: 464px;
  height: 268px;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1860px){
    margin-top: 4%;
    width: 100%;
    max-width: 523px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 214px;
    margin-top: 20px;
    padding: 20px 15px 0 15px;
    border-radius: 5px;
  }
`

const FollowerGenderDataWrapper = styled(Row)`
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 40px 0 40px;

  @media (max-width: 1860px){
    padding: 0 10% 0 10%;
  }

  @media (max-width: 590px){
    padding: 0 5%;
  }
`

const DoughnutDiv = styled.div`
  width: 40%;

  @media (max-width: 590px){
    width: 40%;
    min-width: 108px;
    max-width: 120px;
  }
`

const DataDiv = styled(Column)`
  width: 40%;
  gap: 15px;
  justify-content: center;

  @media (max-width: 590px){
    width: 45%;
  }
`

const GenderColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${props => props.color};
`

const StaticB1 = styled(B1)`
  @media (max-width: 590px){
    font-size: 14px;
    line-height: 18px;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
`