//React
import styled from 'styled-components';

function InstagramSkeleton() {

  return (
    <ReportWrapper>

      <FlexLeftLarge>

        <CalendarDiv>
        </CalendarDiv>

        <ProfileDiv>
        </ProfileDiv>

        <FlexLeftSmall>
          <FollowerDataDiv>
          </FollowerDataDiv>

          <RecentFeedDiv>
          </RecentFeedDiv>
        </FlexLeftSmall>

        <FlexLeftSmall>
          <TodayRankingDiv>
          </TodayRankingDiv>

          <FeedDataDiv>
          </FeedDataDiv>
        </FlexLeftSmall>

      </FlexLeftLarge>

      <VerticalLine />

      <FlexMedium>
        <FlexSmall>
          <FollowerGenderDiv />
          <FollowerAgeDiv />
        </FlexSmall>
        <FollowerCountryDiv />
      </FlexMedium>

    </ReportWrapper>
  );

}

export default InstagramSkeleton;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;

  @media (max-width: 590px){
    padding: 0 20px 100px 20px;
  }
`

const FlexLeftLarge = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;

  @media (max-width: 590px){
    gap: 0;
    margin-top: 110px;
  }
`

const FlexLeftSmall = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`

const VerticalLine = styled.div`
  width: 1px;
  min-height: calc(100vh - 80px);
  background: #E6E6E6;
  margin: 0 30px;

  @media (max-width: 1860px){
    display: none;
  }
`

const FlexMedium = styled.div`
  margin-top: 80px;
  width: 464px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 80px;

  @media (max-width: 1860px){
    width: 100%;
    max-width: 1066px;
    margin-top: 0;
    flex-direction: row;
    gap: 2%;
  }

  @media (max-width: 590px){
    margin-bottom: 30px;
    flex-direction: column;
    gap: 0;
    width: 100%;
    min-width: 320px;
  }
`

const FlexSmall = styled.div`
  width: 49%;
  max-width: 523px;
  display: flex;
  flex-direction: column;

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
  }
`

const CalendarDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 80px;
  background: transparent;

  @media (max-width: 590px){
    height: 30px;
  }
`

const ProfileDiv = styled.div`
  width: 100%;
  max-width: 1066px;
  height: 117px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    min-width: 320px;
    height: 180px;
    border-radius: 5px;
  }
`

const FollowerDataDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  height: 64px;
  background: #FFFFFF;
  border-radius: 10px;
  
  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 60px;
    margin-top: 20px;
    border-radius: 5px;
  }
`

const RecentFeedDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  height: 896.5px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 745px;
    margin-top: 8px;
    border-radius: 5px;
  }
`

const TodayRankingDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  height: 490px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 390px;
    margin-top: 20px;
    border-radius: 5px;
  }
`

const FeedDataDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  height: 467px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 472px;
    margin-top: 20px;
    border-radius: 5px;
  }
`

const FollowerGenderDiv = styled.div`
  width: 464px;
  height: 268px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 1860px){
    margin-top: 4%;
    width: 100%;
    max-width: 523px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 214px;
    margin-top: 20px;
    border-radius: 5px;
  }
`

const FollowerAgeDiv = styled.div`
  width: 464px;
  height: 320px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 20px;

  @media (max-width: 1860px){
    margin-top: 4%;
    width: 100%;
    max-width: 523px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 320px;
    margin-top: 20px;
    border-radius: 5px;
  }
`

const FollowerCountryDiv = styled.div`
  width: 464px;
  height: 648px;
  background: #FFFFFF;
  border-radius: 10px;

  @media (max-width: 1860px){
    margin-top: 2%;
    width: 49%;
    max-width: 523px;
    height: 619px;
  }

  @media (max-width: 590px){
    width: 100%;
    max-width: none;
    height: 510px;
    margin-top: 20px;
    border-radius: 5px;
  }
`