//React
import { useState } from "react";
import styled from "styled-components";

//CountUp
import CountUp from "react-countup";

//Design System
import { Row, Column, FlexBox, T1, T2, B1, E1 } from "styles/DesignSystem";

//Assets
import profileDefault from "assets/icons/application-header-profile.svg";
import arrowDown from "assets/icons/button-arrow-down.svg";
import profileBlue from "assets/icons/report-meercat-profile-blue.png";
import profileYellow from "assets/icons/report-meercat-profile-yellow.png";
import profilePink from "assets/icons/report-meercat-profile-pink.png";
import profilePurple from "assets/icons/report-meercat-profile-purple.png";

const ReflectionRateConvertor = function (countries) {
  if (countries) {
    let countryRawData = Object.entries(countries);
    let countryTotal = 0;
    for (let ii = 0; ii < countryRawData.length; ii++) {
      countryTotal += countryRawData[ii][1];
    }
    return ((1 + (countries?.KR ?? 0) / countryTotal / 2) * 100).toFixed(1);
  } else {
    return "—";
  }
};

const RankingScoreCalculator = function (rankingData) {
  if (rankingData.audience_country) {
    let countryRawData = Object.entries(rankingData.audience_country);
    let countryTotal = 0;
    for (let ii = 0; ii < countryRawData.length; ii++) {
      countryTotal += countryRawData[ii][1];
    }
    return Math.round(
      (rankingData.reach_week + rankingData.reach) *
        (1 + (rankingData.audience_country?.KR ?? 0) / countryTotal / 2)
    );
  } else {
    return Math.round(rankingData.reach_week + rankingData.reach);
  }
};

function InstagramTodayRanking({ userData, data }) {
  const handleImgError = (error) => {
    error.target.src = profileDefault;
  };

  const [whetherFirstAccordionOpened, setWhetherFirstAccordionOpened] =
    useState(false);
  const [whetherSecondAccordionOpened, setWhetherSecondAccordionOpened] =
    useState(false);
  const [whetherThirdAccordionOpened, setWhetherThirdAccordionOpened] =
    useState(true);
  const [whetherFourthAccordionOpened, setWhetherFourthAccordionOpened] =
    useState(false);
  const [whetherFifthAccordionOpened, setWhetherFifthAccordionOpened] =
    useState(false);

  let firstRanking;
  let secondRanking;
  let thirdRanking;
  let fourthRanking;
  let fifthRanking;

  if (data?.comparison_ranking?.length) {
    if (data.ig_user_id === data.comparison_ranking?.[0]?.ig_user_id) {
      firstRanking = data.today_ranking;
      secondRanking = data.today_ranking + 1;
      thirdRanking = data.today_ranking + 2;
      fourthRanking = data.today_ranking + 3;
      fifthRanking = data.today_ranking + 4;
    } else if (data.ig_user_id === data.comparison_ranking?.[1]?.ig_user_id) {
      firstRanking = data.today_ranking - 1;
      secondRanking = data.today_ranking;
      thirdRanking = data.today_ranking + 1;
      fourthRanking = data.today_ranking + 2;
      fifthRanking = data.today_ranking + 3;
    } else if (data.ig_user_id === data.comparison_ranking?.[2]?.ig_user_id) {
      firstRanking = data.today_ranking - 2;
      secondRanking = data.today_ranking - 1;
      thirdRanking = data.today_ranking;
      fourthRanking = data.today_ranking + 1;
      fifthRanking = data.today_ranking + 2;
    } else if (data.ig_user_id === data.comparison_ranking?.[3]?.ig_user_id) {
      firstRanking = data.today_ranking - 3;
      secondRanking = data.today_ranking - 2;
      thirdRanking = data.today_ranking - 1;
      fourthRanking = data.today_ranking;
      fifthRanking = data.today_ranking + 1;
    } else if (data.ig_user_id === data.comparison_ranking?.[4]?.ig_user_id) {
      firstRanking = data.today_ranking - 4;
      secondRanking = data.today_ranking - 3;
      thirdRanking = data.today_ranking - 2;
      fourthRanking = data.today_ranking - 1;
      fifthRanking = data.today_ranking;
    }
  }

  return (
    <RankingDiv>
      <T1 bold>크리에이터리 랭킹</T1>

      {data?.comparison_ranking?.length ? (
        <RankingWrapper>
          <Column>
            <RankingRow
              my={data.today_ranking === firstRanking}
              active={whetherFirstAccordionOpened}
              onClick={function () {
                setWhetherFirstAccordionOpened(!whetherFirstAccordionOpened);
              }}
            >
              <RankingText my={data.today_ranking === firstRanking}>
                {firstRanking}위
              </RankingText>
              <ProfileImage
                src={
                  data.today_ranking === firstRanking
                    ? userData.profile_picture
                    : profileBlue
                }
                onError={handleImgError}
              />
              <FollwerBox>팔로워</FollwerBox>
              <FollwerText>
                {data.comparison_ranking[0]
                  ? data.comparison_ranking[0].followers_count.toLocaleString() +
                    "명"
                  : "정보 없음"}
              </FollwerText>
              <FlexBox />
              <ScoreText>
                <CountUp
                  duration={0.7}
                  end={
                    data.comparison_ranking[0]
                      ? RankingScoreCalculator(data.comparison_ranking[0])
                      : 0
                  }
                  separator=","
                />
              </ScoreText>
              <ScoreUnit>점</ScoreUnit>
              <RankingDetailIcon
                src={arrowDown}
                active={whetherFirstAccordionOpened}
              />
            </RankingRow>
            <HorizontalLine
              active={whetherFirstAccordionOpened}
              my={data.today_ranking === firstRanking}
            />
            <RankingComponentRow
              active={whetherFirstAccordionOpened}
              my={data.today_ranking === firstRanking}
            >
              {data.comparison_ranking[0] ? (
                <>
                  <ComponentUpperRow>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">주간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[0].reach_week.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">일간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[0].reach.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                  </ComponentUpperRow>
                  <ComponentUnderRow>
                    <ComponentMobile>
                      <B1 color="#AAAAAA">팔로워</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[0]
                          ? data.comparison_ranking[0].followers_count.toLocaleString() +
                            "명"
                          : "정보 없음"}
                      </B1>
                    </ComponentMobile>
                    <ComponentStatic>
                      <B1 color="#AAAAAA">가산점</B1>
                      <B1 bold left={5}>
                        {ReflectionRateConvertor(
                          data.comparison_ranking[0].audience_country
                        )}
                        %
                      </B1>
                    </ComponentStatic>
                  </ComponentUnderRow>
                </>
              ) : (
                <NoDataText>데이터 없음</NoDataText>
              )}
            </RankingComponentRow>
          </Column>

          <Column>
            <RankingRow
              my={data.today_ranking === secondRanking}
              active={whetherSecondAccordionOpened}
              onClick={function () {
                setWhetherSecondAccordionOpened(!whetherSecondAccordionOpened);
              }}
            >
              <RankingText my={data.today_ranking === secondRanking}>
                {secondRanking}위
              </RankingText>
              <ProfileImage
                src={
                  data.today_ranking === secondRanking
                    ? userData.profile_picture
                    : profileYellow
                }
                onError={handleImgError}
              />
              <FollwerBox>팔로워</FollwerBox>
              <FollwerText>
                {data.comparison_ranking[1]
                  ? data.comparison_ranking[1].followers_count.toLocaleString() +
                    "명"
                  : "정보 없음"}
              </FollwerText>
              <FlexBox />
              <ScoreText>
                <CountUp
                  duration={0.7}
                  end={
                    data.comparison_ranking[1]
                      ? RankingScoreCalculator(data.comparison_ranking[1])
                      : 0
                  }
                  separator=","
                />
              </ScoreText>
              <ScoreUnit>점</ScoreUnit>
              <RankingDetailIcon
                src={arrowDown}
                active={whetherSecondAccordionOpened}
              />
            </RankingRow>
            <HorizontalLine
              active={whetherSecondAccordionOpened}
              my={data.today_ranking === secondRanking}
            />
            <RankingComponentRow
              active={whetherSecondAccordionOpened}
              my={data.today_ranking === secondRanking}
            >
              {data.comparison_ranking[1] ? (
                <>
                  <ComponentUpperRow>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">주간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[1].reach_week.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">일간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[1].reach.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                  </ComponentUpperRow>
                  <ComponentUnderRow>
                    <ComponentMobile>
                      <B1 color="#AAAAAA">팔로워</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[1]
                          ? data.comparison_ranking[1].followers_count.toLocaleString() +
                            "명"
                          : "정보 없음"}
                      </B1>
                    </ComponentMobile>
                    <ComponentStatic>
                      <B1 color="#AAAAAA">가산점</B1>
                      <B1 bold left={5}>
                        {ReflectionRateConvertor(
                          data.comparison_ranking[1].audience_country
                        )}
                        %
                      </B1>
                    </ComponentStatic>
                  </ComponentUnderRow>
                </>
              ) : (
                <NoDataText>데이터 없음</NoDataText>
              )}
            </RankingComponentRow>
          </Column>

          <Column>
            <RankingRow
              my={data.today_ranking === thirdRanking}
              active={whetherThirdAccordionOpened}
              onClick={() =>
                setWhetherThirdAccordionOpened(!whetherThirdAccordionOpened)
              }
            >
              <RankingText my={data.today_ranking === thirdRanking}>
                {thirdRanking}위
              </RankingText>
              <ProfileImage
                src={
                  data.today_ranking === firstRanking
                    ? profileBlue
                    : data.today_ranking === secondRanking
                    ? profileYellow
                    : data.today_ranking === fourthRanking
                    ? profilePink
                    : data.today_ranking === fifthRanking
                    ? profilePurple
                    : userData.profile_picture
                }
                onError={handleImgError}
              />
              <FollwerBox>팔로워</FollwerBox>
              <FollwerText>
                {data.comparison_ranking[2]
                  ? data.comparison_ranking[2].followers_count.toLocaleString() +
                    "명"
                  : "정보 없음"}
              </FollwerText>
              <FlexBox />
              <ScoreText>
                <CountUp
                  duration={0.7}
                  end={
                    data.comparison_ranking[2]
                      ? RankingScoreCalculator(data.comparison_ranking[2])
                      : 0
                  }
                  separator=","
                />
              </ScoreText>
              <ScoreUnit>점</ScoreUnit>
              <RankingDetailIcon
                src={arrowDown}
                active={whetherThirdAccordionOpened}
              />
            </RankingRow>
            <HorizontalLine
              active={whetherThirdAccordionOpened}
              my={data.today_ranking === thirdRanking}
            />
            <RankingComponentRow
              active={whetherThirdAccordionOpened}
              my={data.today_ranking === thirdRanking}
            >
              {data.comparison_ranking[2] ? (
                <>
                  <ComponentUpperRow>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">주간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[2].reach_week.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">일간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[2].reach.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                  </ComponentUpperRow>
                  <ComponentUnderRow>
                    <ComponentMobile>
                      <B1 color="#AAAAAA">팔로워</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[2]
                          ? data.comparison_ranking[2].followers_count.toLocaleString() +
                            "명"
                          : "정보 없음"}
                      </B1>
                    </ComponentMobile>
                    <ComponentStatic>
                      <B1 color="#AAAAAA">가산점</B1>
                      <B1 bold left={5}>
                        {ReflectionRateConvertor(
                          data.comparison_ranking[2].audience_country
                        )}
                        %
                      </B1>
                    </ComponentStatic>
                  </ComponentUnderRow>
                </>
              ) : (
                <NoDataText>데이터 없음</NoDataText>
              )}
            </RankingComponentRow>
          </Column>

          <Column>
            <RankingRow
              my={data.today_ranking === fourthRanking}
              active={whetherFourthAccordionOpened}
              onClick={() =>
                setWhetherFourthAccordionOpened(!whetherFourthAccordionOpened)
              }
            >
              <RankingText my={data.today_ranking === fourthRanking}>
                {fourthRanking}위
              </RankingText>
              <ProfileImage
                src={
                  data.today_ranking === fourthRanking
                    ? userData.profile_picture
                    : profilePink
                }
                onError={handleImgError}
              />
              <FollwerBox>팔로워</FollwerBox>
              <FollwerText>
                {data.comparison_ranking[3]
                  ? data.comparison_ranking[3].followers_count.toLocaleString() +
                    "명"
                  : "정보 없음"}
              </FollwerText>
              <FlexBox />
              <ScoreText>
                <CountUp
                  duration={0.7}
                  end={
                    data.comparison_ranking[3]
                      ? RankingScoreCalculator(data.comparison_ranking[3])
                      : 0
                  }
                  separator=","
                />
              </ScoreText>
              <ScoreUnit>점</ScoreUnit>
              <RankingDetailIcon
                src={arrowDown}
                active={whetherFourthAccordionOpened}
              />
            </RankingRow>
            <HorizontalLine
              active={whetherFourthAccordionOpened}
              my={data.today_ranking === fourthRanking}
            />
            <RankingComponentRow
              active={whetherFourthAccordionOpened}
              my={data.today_ranking === fourthRanking}
            >
              {data.comparison_ranking[3] ? (
                <>
                  <ComponentUpperRow>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">주간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[3].reach_week.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">일간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[3].reach.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                  </ComponentUpperRow>
                  <ComponentUnderRow>
                    <ComponentMobile>
                      <B1 color="#AAAAAA">팔로워</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[3]
                          ? data.comparison_ranking[3].followers_count.toLocaleString() +
                            "명"
                          : "정보 없음"}
                      </B1>
                    </ComponentMobile>
                    <ComponentStatic>
                      <B1 color="#AAAAAA">가산점</B1>
                      <B1 bold left={5}>
                        {ReflectionRateConvertor(
                          data.comparison_ranking[3].audience_country
                        )}
                        %
                      </B1>
                    </ComponentStatic>
                  </ComponentUnderRow>
                </>
              ) : (
                <NoDataText>데이터 없음</NoDataText>
              )}
            </RankingComponentRow>
          </Column>

          <Column>
            <RankingRow
              my={data.today_ranking === fifthRanking}
              active={whetherFifthAccordionOpened}
              onClick={() =>
                setWhetherFifthAccordionOpened(!whetherFifthAccordionOpened)
              }
            >
              <RankingText my={data.today_ranking === fifthRanking}>
                {fifthRanking}위
              </RankingText>
              <ProfileImage
                src={
                  data.today_ranking === fifthRanking
                    ? userData.profile_picture
                    : profilePurple
                }
                onError={handleImgError}
              />
              <FollwerBox>팔로워</FollwerBox>
              <FollwerText>
                {data.comparison_ranking[4]
                  ? data.comparison_ranking[4].followers_count.toLocaleString() +
                    "명"
                  : "정보 없음"}
              </FollwerText>
              <FlexBox />
              <ScoreText>
                <CountUp
                  duration={0.7}
                  end={
                    data.comparison_ranking[4]
                      ? RankingScoreCalculator(data.comparison_ranking[4])
                      : 0
                  }
                  separator=","
                />
              </ScoreText>
              <ScoreUnit>점</ScoreUnit>
              <RankingDetailIcon
                src={arrowDown}
                active={whetherFifthAccordionOpened}
              />
            </RankingRow>
            <HorizontalLine
              active={whetherFifthAccordionOpened}
              my={data.today_ranking === fifthRanking}
            />
            <RankingComponentRow
              active={whetherFifthAccordionOpened}
              my={data.today_ranking === fifthRanking}
            >
              {data.comparison_ranking[4] ? (
                <>
                  <ComponentUpperRow>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">주간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[4].reach_week.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                    <ComponentFlex>
                      <B1 color="#AAAAAA">일간 도달수</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[4].reach.toLocaleString()}
                      </B1>
                    </ComponentFlex>
                  </ComponentUpperRow>
                  <ComponentUnderRow>
                    <ComponentMobile>
                      <B1 color="#AAAAAA">팔로워</B1>
                      <B1 bold left={5}>
                        {data.comparison_ranking[4]
                          ? data.comparison_ranking[4].followers_count.toLocaleString() +
                            "명"
                          : "정보 없음"}
                      </B1>
                    </ComponentMobile>
                    <ComponentStatic>
                      <B1 color="#AAAAAA">가산점</B1>
                      <B1 bold left={5}>
                        {ReflectionRateConvertor(
                          data.comparison_ranking[4].audience_country
                        )}
                        %
                      </B1>
                    </ComponentStatic>
                  </ComponentUnderRow>
                </>
              ) : (
                <NoDataText>데이터 없음</NoDataText>
              )}
            </RankingComponentRow>
          </Column>
        </RankingWrapper>
      ) : (
        <Row
          style={{ width: "100%", height: "416px", justifyContent: "center" }}
        >
          <T2 color="#AAAAAA">데이터 없음</T2>
        </Row>
      )}
    </RankingDiv>
  );
}

export default InstagramTodayRanking;

const RankingDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  background: #ffffff;
  border-radius: 10px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px) {
    width: 100%;
    min-width: 320px;
    max-width: none;
    margin-top: 20px;
    padding: 20px 15px 20px;
    border-radius: 5px;
  }
`;

const RankingWrapper = styled(Column)`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  gap: 15px;

  @media (max-width: 590px) {
    gap: 10px;
  }
`;

const RankingRow = styled(Row)`
  width: 100%;
  background: #ffffff;
  border: ${(props) => (props.my ? "1px solid #4480F7" : "1px solid #F6F6F6")};
  border-radius: 5px;
  border-bottom: ${(props) => props.active && "0px solid transparent"};
  border-bottom-left-radius: ${(props) => props.active && "0"};
  border-bottom-right-radius: ${(props) => props.active && "0"};
  padding: 16px 10px 16px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 590px) {
    padding: 13px 16px 13px 0;
  }
`;

const RankingComponentRow = styled(Row)`
  width: 100%;
  height: ${(props) => (props.active ? "58px" : "0")};
  opacity: ${(props) => (props.active ? "1" : "0")};
  background: #fcfcfc;
  border: ${(props) => (props.my ? "1px solid #4480F7" : "1px solid #F6F6F6")};
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  padding: 0 30px;
  justify-content: center;

  @media (max-width: 590px) {
    height: ${(props) => (props.active ? "80px" : "0")};
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 10%;
  }
`;

const HorizontalLine = styled.div`
  opacity: ${(props) => (props.active ? "1" : "0")};
  width: 100%;
  height: 1px;
  background: #f6f6f6;
  border-right: ${(props) =>
    props.my ? "1px solid #4480F7" : "1px solid #F6F6F6"};
  border-left: ${(props) =>
    props.my ? "1px solid #4480F7" : "1px solid #F6F6F6"};
  transition: all 0.2s ease-in-out;
`;

const RankingText = styled(T2)`
  width: 90px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.my ? "#4480F7" : "#333333")};

  @media (max-width: 590px) {
    width: 70px;
  }
`;

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(50, 50);
  object-fit: cover;
  margin-right: 10px;
`;

const FollwerBox = styled(E1)`
  padding: 1.5px 7px;
  color: #999999;
  text-align: center;
  border-radius: 2px;
  background: #f2f2f2;
  margin-right: 5px;

  @media (max-width: 590px) {
    display: none;
  }
`;

const FollwerText = styled(B1)`
  color: #555555;

  @media (max-width: 590px) {
    display: none;
  }
`;

const ScoreText = styled(T2)`
  font-weight: 700;

  @media (max-width: 590px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ScoreUnit = styled(B1)`
  margin-left: 3px;

  @media (max-width: 590px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const RankingDetailIcon = styled.img`
  margin-left: 20px;
  transform: ${(props) => props.active && "rotate(-180deg)"};
  transition: all 0.2s;

  @media (max-width: 590px) {
    margin-left: 15px;
  }
`;

const ComponentFlex = styled(Row)`
  flex: 1;
`;

const ComponentMobile = styled(Row)`
  flex: 1;

  @media (min-width: 590px) {
    display: none;
  }
`;

const ComponentStatic = styled(Row)`
  width: 95px;

  @media (max-width: 590px) {
    width: auto;
    flex: 1;
  }
`;

const ComponentUpperRow = styled(Row)`
  flex: 2;

  @media (max-width: 590px) {
    flex: none;
    width: 100%;
  }
`;

const ComponentUnderRow = styled(Row)`
  width: 95px;

  @media (max-width: 590px) {
    flex: none;
    width: 100%;
    margin-top: 10px;
  }
`;

const NoDataText = styled(T2)`
  color: #aaaaaa;

  @media (max-width: 590px) {
    margin-right: 10%;
  }
`;
