//React
import React from 'react';
import styled from 'styled-components';

//Css
import { 
  NextButton
} from 'styles/DesignSystem';

//Assets
import meercat404 from 'assets/images/meercat-404.png';

function Error() {

  return (
    <PageErrorDiv>
      <Meercat404Image src={meercat404} />
      <Text size={'25px'} weight={800} color={'#4480F7'} lineHeight={'50px'} style={{ textAlign: 'center' }}>
        페이지를 찾을 수 없습니다
        <Text size={'14px'} weight={400} lineHeight={'25px'} opacity={0.3}>원하시는 결과를 찾을 수 없습니다 <br /> 올바른 URL을 입력하였는지 확인하세요</Text>
      </Text>
      <NextButton active top={20} onClick={function () { window.history.back(); }}>
        이전으로 돌아가기
      </NextButton>
    </PageErrorDiv>
  );

}

export default Error;

const PageErrorDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const Meercat404Image = styled.img`
  width: 100%;
  max-width: 320px;
`

export const Text = styled.div`
  font-size: ${props => props.size ?? '18px'};
  font-weight: ${props => props.weight ?? '18px'};
  color: ${props => props.color ?? 'black'};
  line-height: ${props => props.lineHeight ?? '18px'};
  opacity: ${props => props.opacity ?? '1'};
  user-select: none;
`