//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Components
import DifferenceChecker from 'pages/Report/components/DifferenceChecker';

//Design System
import {
  Row,
  FlexBox,
  T1,
} from 'styles/DesignSystem';

function InstagramFollowerData({ todayData, yesterdayData }) {

  return (
    <FollowerDataDiv>

      <Row>
        <T1 bold>현재 팔로워 수</T1>
        <FlexBox />
        <T1 bold>
          <CountUp end={todayData.total_follower} separator=',' duration={0.7} />명
        </T1>
        <DifferenceChecker
          data={yesterdayData ? (todayData.total_follower - yesterdayData.total_follower) : null}
          left={8}
        />
      </Row>

    </FollowerDataDiv>
  );

}

export default InstagramFollowerData;

const FollowerDataDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px 15px;
  }
`