//React
import styled from 'styled-components';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

//Design System
import {
  Row,
  FlexBox,
  T1,
  T2,
  B1,
  B2,
} from 'styles/DesignSystem';

const PTHMStoTime = (input) => {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0, minutes = 0, seconds = 0, time;

  if (reptms.test(input)) {
    var matches = reptms.exec(input);
    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
    time = `${hours === 0 ? '' : hours + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  return time;
}

const handleVideoOnClick = function (videoId) {
  window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
}

function YoutubePopularVideo({ data }) {

  return (
    <PopularVideoDiv>

      <StyledT1Title>조회수 최다 동영상</StyledT1Title>

      {
        data.length
          ? (<PopularVideoContainer>
            <Swiper
              spaceBetween={0}
              slidesPerView={'auto'}
              style={{ cursor: 'grab', paddingRight: '20px' }}
            >
              {data.map((item, index) => (
                <SwiperSlide
                  key={index}
                  style={{ width: window.innerWidth > 590 ? '320px' : '245px' }}
                >
                  <PopularVideoCard>
                    <PopularVideoImage src={item.thumbnails} onClick={() => handleVideoOnClick(item.videoId)} />
                    <PopularVideoDuration>{PTHMStoTime(item.duration)}</PopularVideoDuration>
                    <PopularVideoTitleWrapper style={{ display: '-webkit-box' }} onClick={() => handleVideoOnClick(item.videoId)}>{item.title}</PopularVideoTitleWrapper>
                    <B2 top={5} left={10} color='#999999'>{(item.publishedAt.substr(0, 10)).replaceAll('-', '.')}</B2>
                    <HorizontalLine />

                    <Row style={{ margin: '0 10px 12px 10px' }}>
                      <B1 color='#999999'>조회수</B1>
                      <FlexBox />
                      <B1 bold>{parseInt(item?.totalViews ?? item.views).toLocaleString()}회</B1>
                    </Row>

                    <Row style={{ margin: '0 10px 12px 10px' }}>
                      <B1 color='#999999'>추정 수익</B1>
                      <FlexBox />
                      <B1 bold>{parseInt(item.estimatedRevenue).toLocaleString()}원</B1>
                    </Row>

                    <Row style={{ margin: '0 10px 12px 10px' }}>
                      <B1 color='#999999'>유입 구독자</B1>
                      <FlexBox />
                      <B1 bold>{(item?.subscribersGained - item?.subscribersLost).toLocaleString()}명</B1>
                    </Row>
                  </PopularVideoCard>
                </SwiperSlide>
              ))}
            </Swiper>

          </PopularVideoContainer>)
          : (<T2 color='#AAAAAA' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>데이터 없음</T2>)
      }

    </PopularVideoDiv>
  );

}

export default YoutubePopularVideo;

const PopularVideoDiv = styled.div`
  width: 464px;
  height: 439px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1860px){
    width: 100%;
    margin-top: 4%;
  }

  @media (max-width: 590px){
    min-width: 320px;
    height: 371px;
    margin-top: 20px;
  }
`

const PopularVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  //background: pink;
`

const PopularVideoCard = styled.div`
  position: relative;
  width: 300px;
  height: 345px;
  margin-left: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 5px;

  @media (max-width: 590px){
    width: 230px;
    height: 287px;
    margin-left: 15px;
  }
`

const PopularVideoImage = styled.img`
  width: 300px;
  margin-left: -1px;
  height: 156px;
  border-radius: 5px 5px 0px 0px;
  transform: translate(50, 50);
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 590px){
    width: 230px;
    height: 120px;
  }
`

const PopularVideoDuration = styled.div`
  padding: 2px 5px;
  border-radius: 3px;
  position: absolute;
  top: 129px;
  right: 8px;
  background: #00000099;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  @media (max-width: 590px){
    top: 90px;
    right: 10px;
  }
`

export const PopularVideoTitleWrapper = styled.div`
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  @media (max-width: 590px){
    max-height: 18px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #EEEEEE;
  margin: 16px 0 17px;

  @media (max-width: 590px){
    margin: 13px 0 13px;
  }
`

const StyledT1Title = styled(T1)`
  font-weight: 700;
  margin-left: 20px;

  @media (max-width: 590px){
    margin-left: 15px;
  }
`