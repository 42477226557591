//React
import styled from 'styled-components';

//Design System
import {
  Row,
  FlexBox,
  T1,
  T2,
  B2,
} from 'styles/DesignSystem';

// 기준이 될 팔로워 급감을 가져옴
const _getDrop = (followerCount) => {
  let drop;

  // 유튜브 기준은 두자리 수 밑임 10000 -> 100단위로 끊어서 나옴, 1000 미만은 자체체적으로 10으로 통일하기로 함
  if (followerCount >= 1000000) {
    drop = 10000;
  } else if (followerCount >= 10000) {
    drop = 1000;
  } else if (followerCount >= 1000) {
    drop = 100;
  } else {
    drop = 10;
  }

  followerCount -= followerCount % drop; // 현재 급간으로 변환시켜주는 로직

  return { followerCount, drop };
};

// 1년치 팔로워 데이터를 가지고 drop 기준과 급간별 최초 달성일을 구하는 로직
const _getSubsTimelineData = (dropData, subsArr) => {
  let { followerCount, drop } = dropData;
  let timeLineArr = [];

  for (let ii = 0; ii < subsArr.length; ii++) {
    if (subsArr[ii].subscribers_count >= followerCount) {
      if (subsArr[ii].data_created_at === timeLineArr[timeLineArr.length - 1]?.createdAt) { // 하루에 여러개의 급간을 뛰어넘었을 경우 처리
        followerCount -= drop;
        ii = 0;
      } else {
        timeLineArr.push({ followerCount, createdAt: subsArr[ii].data_created_at }); // 급간 기준 최초 달성일을 저장
        followerCount -= drop;
        ii = 0;
      }
    }
    if (timeLineArr.length === 5 || subsArr[0].subscribers_count >= followerCount) { // 배열 길이가 5거나 1년 전 데이터가 기준 급간을 넘은 경우 종료됨
      break;
    }
  }

  return timeLineArr;
};

function YoutubeSubscriberTimeline({ subscriberData, todayIndex }) {

  //구독자 타임라인 데이터, 구독자가 5명 이상인 경우에만 보여줌
  let dropData = {};
  let timeLineArr = [];
  let topSubs = 0;
  let subsDateInterval = [];
  let subsDateSum = [];
  let subsDateTotalSum = 0;
  const filteredSubscriber = subscriberData.slice();

  if (subscriberData.length) {
    for (let i = 0; i < todayIndex; i++) {
      filteredSubscriber.pop();
    }

    if (filteredSubscriber[filteredSubscriber.length - 1].subscribers_count >= 10) {
      for (let subscriber of filteredSubscriber) {
        if (subscriber.subscribers_count >= topSubs) {
          topSubs = subscriber.subscribers_count; // 1년 동안 최고 구독자 수치를 구함
        }
      }
      dropData = _getDrop(topSubs); // 기준이 될 팔로워 급감을 가져옴
      timeLineArr = _getSubsTimelineData(dropData, filteredSubscriber); // 1년치 팔로워 데이터를 가지고 drop 기준과 급간별 최초 달성일을 구하는 로직
      for (let ii = 0; ii < timeLineArr.length - 1; ii++) { // 타임라인 데이터들의 간격을 구하는 로직
        subsDateInterval.push((new Date(timeLineArr[ii].createdAt).getTime() - new Date(timeLineArr[ii + 1].createdAt).getTime()) / (1000 * 60 * 60 * 24));
        subsDateTotalSum += subsDateInterval[ii];
        subsDateSum.push(subsDateTotalSum);
      }
      for (let ii = 0; ii < subsDateSum.length - 1; ii++) { // 타임라인 데이터들의 최소 간격을 10%로 맞추는 로직
        if ((subsDateSum[ii + 1] - subsDateSum[ii]) / subsDateTotalSum < 0.1) {
          subsDateSum[ii + 1]++;
          subsDateTotalSum++;
          ii = 0;
        }
      }
    }
  }

  return (
    <SubscriberTimelineDiv>

      <Row>
        <T1 bold>구독자 증가 타임라인</T1>
      </Row>

      {
        filteredSubscriber[filteredSubscriber.length - 1].subscribers_count > 50
          ? (<>
            <Row style={{ width: '100%' }}>
              <FlexBox />
              <B2 top={7} color='#AAAAAA'>구독자 증가 소요시간</B2>
            </Row>

            <SubscriberTimelineWrapper>

              <VerticalLine />

              <FlexBox />
              {
                timeLineArr[0]
                && <YoutubeTimelineContainer top='0'>
                  <YoutubeTimelineDate>{timeLineArr[0].createdAt.slice(5, 10).replace('-', '.')}</YoutubeTimelineDate>
                  <YoutubeTimelinePoint />
                  <YoutubeTimelineSubscriber>{timeLineArr[0].followerCount.toLocaleString()}</YoutubeTimelineSubscriber>
                </YoutubeTimelineContainer>
              }
              {
                timeLineArr.length === 1
                && <YoutubeRequiredDateContainer top={135}>
                  <YoutubeRequiredDateDashedLine />
                  <YoutubeRequiredDate>365일 이상 소요</YoutubeRequiredDate>
                </YoutubeRequiredDateContainer>
              }
              {
                subsDateInterval[0]
                && <YoutubeRequiredDateContainer top={(subsDateSum[0]) / subsDateTotalSum * 270 / 2}>
                  <YoutubeRequiredDateDashedLine />
                  <YoutubeRequiredDate>{subsDateInterval[0]}일 소요</YoutubeRequiredDate>
                </YoutubeRequiredDateContainer>
              }
              {
                timeLineArr[1]
                && <YoutubeTimelineContainer top={subsDateSum[0] / subsDateTotalSum * 270}>
                  <YoutubeTimelineDate>{timeLineArr[1].createdAt.slice(5, 10).replace('-', '.')}</YoutubeTimelineDate>
                  <YoutubeTimelinePoint />
                  <YoutubeTimelineSubscriber>{timeLineArr[1].followerCount.toLocaleString()}</YoutubeTimelineSubscriber>
                </YoutubeTimelineContainer>
              }
              {
                subsDateInterval[1]
                && <YoutubeRequiredDateContainer top={(subsDateSum[0] + subsDateSum[1]) / subsDateTotalSum * 270 / 2}>
                  <YoutubeRequiredDateDashedLine />
                  <YoutubeRequiredDate>{subsDateInterval[1]}일 소요</YoutubeRequiredDate>
                </YoutubeRequiredDateContainer>
              }
              {
                timeLineArr[2]
                && <YoutubeTimelineContainer top={subsDateSum[1] / subsDateTotalSum * 270}>
                  <YoutubeTimelineDate>{timeLineArr[2].createdAt.slice(5, 10).replace('-', '.')}</YoutubeTimelineDate>
                  <YoutubeTimelinePoint />
                  <YoutubeTimelineSubscriber>{timeLineArr[2].followerCount.toLocaleString()}</YoutubeTimelineSubscriber>
                </YoutubeTimelineContainer>
              }
              {
                subsDateInterval[2]
                && <YoutubeRequiredDateContainer top={(subsDateSum[1] + subsDateSum[2]) / subsDateTotalSum * 270 / 2}>
                  <YoutubeRequiredDateDashedLine />
                  <YoutubeRequiredDate>{subsDateInterval[2]}일 소요</YoutubeRequiredDate>
                </YoutubeRequiredDateContainer>
              }
              {
                timeLineArr[3]
                && <YoutubeTimelineContainer top={subsDateSum[2] / subsDateTotalSum * 270}>
                  <YoutubeTimelineDate>{timeLineArr[3].createdAt.slice(5, 10).replace('-', '.')}</YoutubeTimelineDate>
                  <YoutubeTimelinePoint />
                  <YoutubeTimelineSubscriber>{timeLineArr[3].followerCount.toLocaleString()}</YoutubeTimelineSubscriber>
                </YoutubeTimelineContainer>
              }
              {
                subsDateInterval[3]
                && <YoutubeRequiredDateContainer top={(subsDateSum[2] + subsDateSum[3]) / subsDateTotalSum * 270 / 2}>
                  <YoutubeRequiredDateDashedLine />
                  <YoutubeRequiredDate>{subsDateInterval[3]}일 소요</YoutubeRequiredDate>
                </YoutubeRequiredDateContainer>
              }
              {
                timeLineArr[4]
                && <YoutubeTimelineContainer top={subsDateSum[3] / subsDateTotalSum * 270}>
                  <YoutubeTimelineDate>{timeLineArr[4].createdAt.slice(5, 10).replace('-', '.')}</YoutubeTimelineDate>
                  <YoutubeTimelinePoint />
                  <YoutubeTimelineSubscriber>{timeLineArr[4].followerCount.toLocaleString()}</YoutubeTimelineSubscriber>
                </YoutubeTimelineContainer>
              }
            </SubscriberTimelineWrapper>
          </>)
          : (<T2 color='#AAAAAA' style={{ width: '100%', height:'100%', display:'flex', justifyContent: 'center', alignItems:'center' }}>데이터 없음</T2>)
      }

    </SubscriberTimelineDiv >
  );

}

export default YoutubeSubscriberTimeline;

const SubscriberTimelineDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 390px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    height: 390px;
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }
`

const SubscriberTimelineWrapper = styled.div`
  width: 100%;
  height: 270px;
  margin-top: 20px;
  position: relative;

  @media (max-width: 590px){
  }
`

const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  background: #E6E6E6;
  margin-left: 40px;

  @media (max-width: 590px){
  }
`

const YoutubeTimelineContainer = styled.div`
  top: ${(props) => props.top - 8 + 'px'};
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const YoutubeTimelineDate = styled(B2)`
  width: 32px;
  color: #999999;
`;

const YoutubeTimelinePoint = styled.div`
  margin-left: 6px;
  margin-right: 8px;
  width: 5px;
  height: 5px;
  background: #618AFF;
  border-radius: 50%;
`;

const YoutubeTimelineSubscriber = styled.div`
  padding: 0px 9px 0px 9px;
  background: #ECF2FE;
  border-radius: 10.5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #618AFF;
`;

const YoutubeRequiredDateContainer = styled.div`
  top: ${(props) => props.top - 10 + 'px'};
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 40px;
`;

const YoutubeRequiredDateDashedLine = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  opacity: 0.1;
  border: 1px dashed #000000;
`;

const YoutubeRequiredDate = styled.div`
  margin-left: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
`;