//React
import { useState } from 'react';
import styled from 'styled-components';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

//Components
import CustomRadio from 'pages/Report/Youtube/ChannelReport/elements/CustomRadio';

//Design System
import {
  Row,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

function YoutubeAudienceAge({ data, count }) {

  const radioValueList = ['남성', '여성']
  const [radioValue, setRadioValue] = useState(radioValueList[0]);

  let maleRatio = [0, 0, 0, 0, 0];
  let maleCount = [0, 0, 0, 0, 0];
  let femaleRatio = [0, 0, 0, 0, 0];
  let femaleCount = [0, 0, 0, 0, 0];
  if (data) {
    let genderRawDataKey = Object.keys(data)
    for (let genderAge of genderRawDataKey) {
      switch (genderAge) {
        case 'age13-17/male':
          maleRatio[0] = data[genderAge];
          break;
        case 'age18-24/male':
          maleRatio[1] = data[genderAge];
          break;
        case 'age25-34/male':
          maleRatio[2] = data[genderAge];
          break;
        case 'age35-44/male':
          maleRatio[3] = data[genderAge];
          break;
        case 'age45-54/male':
          maleRatio[4] += data[genderAge];
          break;
        case 'age55-64/male':
          maleRatio[4] += data[genderAge];
          break;
        case 'age65-/male':
          maleRatio[4] += data[genderAge];
          break;

        case 'age13-17/female':
          femaleRatio[0] = data[genderAge];
          break;
        case 'age18-24/female':
          femaleRatio[1] = data[genderAge];
          break;
        case 'age25-34/female':
          femaleRatio[2] = data[genderAge];
          break;
        case 'age35-44/female':
          femaleRatio[3] = data[genderAge];
          break;
        case 'age45-54/female':
          femaleRatio[4] += data[genderAge];
          break;
        case 'age55-64/female':
          femaleRatio[4] += data[genderAge];
          break;
        case 'age65-/female':
          femaleRatio[4] += data[genderAge];
          break;
        default: break;
      }
    }

    const maleRatioSum = maleRatio.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
    const femaleRatioSum = femaleRatio.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);

    for (let i = 0; i < maleRatio.length; i++) {
      maleCount[i] = parseInt(maleRatio[i] / 100 * count);
      maleRatio[i] = parseFloat((maleRatio[i] / (maleRatioSum === 0 ? 1 : maleRatioSum) * 100).toFixed(1));
    }
    for (let i = 0; i < femaleRatio.length; i++) {
      femaleCount[i] = parseInt(femaleRatio[i] / 100 * count);
      femaleRatio[i] = parseFloat((femaleRatio[i] / (femaleRatioSum === 0 ? 1 : femaleRatioSum) * 100).toFixed(1));
    }

  }

  const barDataMale = {
    labels: ['13 - 17세', '18 - 24세', '25 - 34세', '35 - 44세', '+ 45세'],
    datasets: [
      {
        data: maleRatio,
        backgroundColor: '#4F5CD9',
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
        barThickness: `${window.innerWidth > 590 ? 20 : 16}`,
      },
    ],
  };

  const barDataFemale = {
    labels: ['13 - 17세', '18 - 24세', '25 - 34세', '35 - 44세', '+ 45세'],
    datasets: [
      {
        data: femaleRatio,
        backgroundColor: '#F35451',
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
        barThickness: `${window.innerWidth > 590 ? 20 : 16}`,
      },
    ],
  };

  const barOptions = {
    hoverBackgroundColor: `${radioValue === '남성' ? '#4F5CD9' : '#F35451'}`,
    layout: {
      padding: {
        right: `${window.innerWidth > 590 ? 0 : 30}`,
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        titleAlign: 'center',
        bodyAlign: 'center',
        callbacks: {
          title: function (context) {
            if (context[0].dataIndex === 4) {
              return `45세 이상 ${radioValue}`;
            } else {
              return context[0].label + ` ${radioValue}`;
            }
          },
          label: function (context) {
            if (radioValue === '남성') {
              return maleCount[context.dataIndex].toLocaleString() + '명 (' + context.parsed.y.toFixed(1) + '%)';
            } else {
              return femaleCount[context.dataIndex].toLocaleString() + '명 (' + context.parsed.y.toFixed(1) + '%)';
            }
          },
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#AAAAAA',
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        }
      },
      y: {
        ticks: {
          stepSize: 10,
          color: '#AAAAAA',
          callback: function (value) {
            return value + '% ';
          }
        },
        grid: {
          color: '#E7E7E7',
          drawTicks: false,
          drawBorder: false,
        }
      }
    }
  };

  return (
    <AudienceAgeDiv>

      <Row>
        <Title>시청자 연령대</Title>
        <FlexBox />
        <CustomRadio radioValueList={radioValueList} radioValue={radioValue} setRadioValue={setRadioValue} />
      </Row>

      <FlexBox />

      {
        data
          ? (<BarDiv>
            <BarContainer>
              <Bar data={radioValue === '남성' ? barDataMale : barDataFemale} options={barOptions} />
            </BarContainer>
          </BarDiv>)
          : (<T2 color='#AAAAAA' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>데이터 없음</T2>)
      }

    </AudienceAgeDiv>
  );

}

export default YoutubeAudienceAge;

const AudienceAgeDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  height: 320px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    height: 320px;
    margin-top: 20px;
    padding: 0;
  }
`

const Title = styled(T1)`
  font-weight: 700;

  @media (max-width: 590px){
    margin-top: 20px;
    margin-left: 15px;
  }
`

const BarDiv = styled.div`
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 590px){
    overflow: scroll;
    padding-left: 20px;
    padding-bottom: 30px;
  }
`

const BarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 590px){
    min-width: 424px;
    max-width: 460px;
  }
`