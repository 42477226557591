// React
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Components
import Loader from 'components/Loader';
import { LinkFauth } from 'apis/Register';

function Fauth() {  

    const code = new URL(window.location.href).searchParams.get('code');
    const state = new URL(window.location.href).searchParams.get('state');

    const navigate = useNavigate();

    useEffect(() => {
        LinkFauth(code, state, navigate);
    }, []);

    return (
        <Loader/>
    );

}

export default Fauth;