//React
import styled from 'styled-components';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

//CountUp
import CountUp from 'react-countup';

//Elements
import DifferenceChecker from 'pages/Report/Youtube/ChannelReport/elements/DifferenceChecker';

//Design System
import {
  Row,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);

const Under10append0 = function (input) {
  const num = parseInt(input); 

  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

function YoutubeSubscriberData({ subscriberData, todayIndex }) {

  let todayDate = '';
  let todaySubscriber = 0;
  let yesterdaySubscriber = 0;
  let maximumSubscriber = 0;
  let minimumSubscriber = 0;

  const monthLabel = [];
  const subscriberCountData = [];
  const filteredSubscriber = subscriberData.slice();

  if (subscriberData.length) {

    for (let i = 0; i < todayIndex; i++) {
      filteredSubscriber.pop();
    }
    const todayData = filteredSubscriber[filteredSubscriber.length - 1];

    for (let subscriber of filteredSubscriber) {
      if (new Date(subscriber.data_created_at).getDate() === 1 && subscriber.data_created_at!==todayData.data_created_at) {
        monthLabel.push(subscriber.data_created_at.split('-')[1] < 10 ? subscriber.data_created_at.split('-')[1].replace('0', '') + '월' : subscriber.data_created_at.split('-')[1] + '월');
        subscriberCountData.push(subscriber.subscribers_count);
      }
    }

    monthLabel.push('오늘');
    subscriberCountData.push(todayData.subscribers_count);
    todayDate = todayData.data_created_at.slice(5, 10).replace('-', '.');
    todaySubscriber = todayData.subscribers_count;
    yesterdaySubscriber = filteredSubscriber[filteredSubscriber.length - 2]?.subscribers_count ?? 0;
    maximumSubscriber = Math.max.apply(Math, subscriberCountData);
    minimumSubscriber = Math.min.apply(Math, subscriberCountData);

  }

  const data = {
    labels: monthLabel,
    datasets: [
      {
        type: 'line',
        borderColor: '#0050FF',
        pointBackgroundColor: '#0050FF',
        pointHoverBackgroundColor: '#0050FF',
        borderWidth: 1,
        pointRadius: 2.5,
        pointHoverRadius: 2.5,
        data: subscriberCountData,
      },
      {
        type: 'bar',
        backgroundColor: '#F2F2F2',
        data: subscriberCountData,
        barThickness: window.innerWidth > 590 ? 9 : 7,
      }
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: '#0050FF',
        titleColor: '#FFFFFFCC',
        titleAlign: 'center',
        titleFont: {
          size: 11,
          weight: 400
        },
        titleMarginBottom: 4,
        bodyAlign: 'center',
        bodyFont: {
          size: 12,
          weight: 700
        },
        padding: {
          top: 5,
          right: 8,
          bottom: 5,
          left: 8,
        },
        callbacks: {
          title: function (context) {

            if (context[0].dataIndex === monthLabel.length-1) {
              return todayDate;
            } else {
              return Under10append0(context[0].label.replace('월', '')) + '.01';
            }
            
          },
          label: function (context) {
            return context.parsed.y.toLocaleString();
          },
        }
      }
    },
    layout: {
      padding: {
        top: 20,
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#AAAAAA',
          padding: 11,
          font: {
            size: window.innerWidth > 590 ? 12 : window.innerWidth > 385 ? 10 : 9,
          }
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          borderColor: '#F6F6F6'
        }
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        max: maximumSubscriber + (maximumSubscriber - minimumSubscriber) * 0.1,
        min: ((minimumSubscriber - (maximumSubscriber - minimumSubscriber) * 0.1) > 0 ? (minimumSubscriber - (maximumSubscriber - minimumSubscriber) * 0.1) : 0),
      }
    }
  };

  return (
    <SubscriberDataDiv>

      <TitleRow>
        <StyledT1Title>구독자 데이터</StyledT1Title>
        <FlexBoxOnDesktop />

        <TitleRowMobile>
          <T2 color='#AAAAAA'>현재 구독자 수</T2>
          <FlexBoxOnMobile />
          <T2 bold left={10}>
            <CountUp end={todaySubscriber} separator=',' duration={0.7} />명
          </T2>
          <DifferenceChecker
            data={todaySubscriber-yesterdaySubscriber}
            left={5}
          />
        </TitleRowMobile>
      </TitleRow>

      <BarGraphWrapper>
        <Chart type='bar' data={data} options={options} height={190} />
      </BarGraphWrapper>

    </SubscriberDataDiv>
  );

}

export default YoutubeSubscriberData;

const SubscriberDataDiv = styled.div`
  margin-top: 2%;
  width: 49%;
  height: 390px;
  background: #FFFFFF;
  border-radius: 5px;

  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    height: auto;
    margin-top: 20px;
    padding: 20px 0;
  }
`

const TitleRow = styled(Row)`
  width: 100%;

  @media (max-width: 590px){
    flex-direction: column;
    align-items: flex-start;
  }
`
const TitleRowMobile = styled(Row)`
  @media (max-width: 590px){
    width: 100%;
    padding: 10px 15px 0 15px;
  }
`

const FlexBoxOnDesktop = styled(FlexBox)`
  @media (max-width: 590px){
    display: none;
  }
`

const FlexBoxOnMobile = styled(FlexBox)`
  @media (min-width: 591px){
    display: none;
  }
`

const BarGraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  padding: 0 20px;

  @media (max-width: 590px){
    margin-top: 20px;
    padding: 0;
    height: auto;
  }
`

const StyledT1Title = styled(T1)`
  font-weight: 700;

  @media (max-width: 590px){
    margin-left: 15px;
  }
`