//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Design System
import {
  Row,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

const Under10append0 = function (num) {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

function YoutubeRevenueData({ revenueData, todayIndex }) {
  const today = new Date();

  let todayRevenueData = {
    lastMonth: Under10append0(today.getMonth()===0 ? 12 : today.getMonth()),
    lastMonthTotal: 0,
    thisMonth: Under10append0(today.getMonth()+1),
    thisMonthTotal: 0,
  };
  let filteredRevenue = revenueData.slice();
  let MonthlyRevenueData = [0,0,0,0,0,0,0,0,0,0,0,0];

  if (revenueData.length) {

    for (let i = 0; i < todayIndex; i++) {
      filteredRevenue.pop();
    }
    const todayData = filteredRevenue[filteredRevenue.length - 1];

    for (let dailyRevenue of filteredRevenue) {
      MonthlyRevenueData[new Date(dailyRevenue.data_created_at).getMonth()] += dailyRevenue.estimated_revenue;
    }

    todayRevenueData.lastMonth = Under10append0(new Date(todayData.data_created_at).getMonth()===0 ? 12 : new Date(todayData.data_created_at).getMonth() );
    todayRevenueData.lastMonthTotal = MonthlyRevenueData[Number(todayRevenueData.lastMonth)-1];
    todayRevenueData.thisMonth = Under10append0(new Date(todayData.data_created_at).getMonth() + 1);
    todayRevenueData.thisMonthTotal = MonthlyRevenueData[Number(todayRevenueData.thisMonth)-1];

  }

  return (
    <RevenueDataDiv>

      <T1 bold>지난달 유튜브 수익 비교</T1>

      <Row top={30}>
        <T2>{todayRevenueData.lastMonth}월 전체 수익</T2>
        <FlexBox />
        <T2 bold><CountUp end={todayRevenueData.lastMonthTotal} separator=',' duration={0.7} />원</T2>
      </Row>
      <HorizontalLine margin='20px 0' />
      <Row>
        <T2>{todayRevenueData.thisMonth}월 누적 수익</T2>
        <FlexBox />
        <T2 bold><CountUp end={todayRevenueData.thisMonthTotal} separator=',' duration={0.7} />원</T2>
      </Row>

    </RevenueDataDiv>
  );

}

export default YoutubeRevenueData;

const RevenueDataDiv = styled.div`
  width: 100%;
  margin-top: 4%;
  border-radius: 5px;
  background: #FFFFFF;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    min-width: 320px;
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
  margin: ${(props) => props.margin};
`