//React
import styled from 'styled-components';

//CountUp
import CountUp from 'react-countup';

//Components
import DifferenceChecker from 'pages/Report/components/DifferenceChecker';

//Design System
import {
  Row,
  Column,
  FlexBox,
  T1,
  T2,
} from 'styles/DesignSystem';

//Assets
import profileImpressionsIcon from 'assets/icons/report-instagram-profile-impressions.svg';
import profileVisitIcon from 'assets/icons/report-instagram-profile-visit.svg';
import websiteClickIcon from 'assets/icons/report-instagram-website-click.svg';
import feedLikeIcon from 'assets/icons/report-instagram-feed-like.svg';
import feedCommentIcon from 'assets/icons/report-instagram-feed-comment.svg';
import feedImpressionsIcon from 'assets/icons/report-instagram-feed-impressions.svg';

function InstagramFeedData({ todayData, yesterdayData }) {

  return (
    <FeedDataDiv>
      
      <T1 bold>피드 데이터</T1>

      <FeedDataWrapper>
        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={profileImpressionsIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>계정 노출 수</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.profile_impression_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.profile_impression_count - yesterdayData.profile_impression_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={profileVisitIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>프로필 방문</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.profile_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.profile_count - yesterdayData.profile_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={websiteClickIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>웹사이트 클릭</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.click_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.click_count - yesterdayData.click_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={feedLikeIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>피드 좋아요</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.like_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.like_count - yesterdayData.like_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={feedCommentIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>피드 댓글</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.comment_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.comment_count - yesterdayData.comment_count) : null} 
            left={15} 
          />
        </Row>

        <HorizontalLine />

        <Row>
          <FeedDataIconContainer>
            <FeedDataIcon src={feedImpressionsIcon} />
          </FeedDataIconContainer>
          <T2 color='#666666'>피드 도달 수</T2>
          <FlexBox />
          <T1 bold>
            <CountUp end={todayData.reach_count} separator=',' duration={0.7} />회
          </T1>
          <DifferenceChecker 
            data={yesterdayData ? (todayData.reach_count - yesterdayData.reach_count) : null} 
            left={15} 
          />
        </Row>
      </FeedDataWrapper>

    </FeedDataDiv>
  );

}

export default InstagramFeedData;

const FeedDataDiv = styled.div`
  margin-top: 4%;
  width: 100%;
  max-width: 523px;
  height: 467px;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 590px){
    width: 100%;
    min-width: 320px;
    max-width: none;
    height: 472px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px 15px 30px 15px;
  }
`

const FeedDataWrapper = styled(Column)`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  gap: 20px;

  @media (max-width: 590px){
    margin-top: 30px;
  }
`

const FeedDataIconContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FeedDataIcon = styled.img`
  width: 20px;
  height: 20px;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F6F6F6;
`