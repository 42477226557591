//React
import { useState, useEffect } from 'react'
import styled from 'styled-components';

//Assets
import meercatConnecting from 'assets/Application/Analysis/images/meercat-report-connecting.png';

//DesignSystem
import {
  Row,
  Column,
  H6,
  T2,
} from 'styles/DesignSystem';

function Connecting() {

  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(function () {
    const handleResize = function () {
      setInnerHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <Container innerHeight={innerHeight}>

      <Row>
        <MeercatImage src={meercatConnecting} />

        <Column>
          <H6 bold color='#111111'>데이터를 수집하고 있습니다</H6>
          <T2 color='#777777' top={8}>분석 리포트는 오후 2시에 업데이트 됩니다</T2>
        </Column>
      </Row>

    </Container>
  );

}

export default Connecting;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media (max-width: 590px){
    min-height: ${props => `${(props.innerHeight)-130}px`};
    max-height: ${props => `${(props.innerHeight)-130}px`};
    padding-top: 0px;
    justify-content: center;
  }
`

const MeercatImage = styled.img`
  width: 60px;
  margin-right: 20px;
`