//React
import styled from 'styled-components';

//Assets
import meercatConnecting from 'assets/images/meercat-report-connecting.png';

//DesignSystem
import {
  Row,
  Column,
  H6,
  T2,
} from 'styles/DesignSystem';

function Connecting({service}) {

  return (
    <Container>

      <Row>
        <MeercatImage src={meercatConnecting} />

        <Column>
          <H6 bold color='#111111'>SNS 연결 중이에요!</H6>
          {
            {
              'report': <T2 color='#777777' top={8}>2일 뒤부터 분석 리포트를 받을 수 있어요</T2> ,
              'campaign': <T2 color='#777777' top={8}>연결이 완료된 뒤에 확인하실 수 있어요</T2>,
            }[service]
          }
          
        </Column>
      </Row>

    </Container>
  );

}

export default Connecting;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  @media (max-width: 590px){
    height: calc(100vh - 60px);
    padding-top: 0px;
    justify-content: center;
  }
`

const MeercatImage = styled.img`
  width: 60px;
  margin-right: 20px;
`