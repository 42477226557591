//Core
import styled from 'styled-components'

//Assets
import { ReactComponent as CheckSvg } from 'assets/components/System/Toast/check.svg'
import { ReactComponent as CrossSvg } from 'assets/components/System/Toast/cross.svg'

const TOAST_TYPE = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE'
}

function Toast({ toast }) {
  return (
    <ToastContainer>
        {toast && 
            <>
                {toast?.type === TOAST_TYPE.POSITIVE ? <CheckSvg /> : toast?.type === TOAST_TYPE.NEGATIVE ? <CrossSvg /> : <></>}
                <TextDiv size={'14px'} color={'#FFFFFF'} weight={400} style={{padding: toast.type ? '' : '0px 10px'}}>{toast?.desc}</TextDiv>
            </>
        }
    </ToastContainer>
  )
}

export default Toast

const ToastContainer = styled.div`
    width: max-content;

    padding: 10px;

    background: #333333;
    border-radius: 39px;

    position: fixed;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%, 0%);

    display: flex;
    align-items: center;
    gap: 8px;

    z-index: 5;

    animation: settings_toast 3s 1 ease;
    
    @media (max-width: 590px){
        animation: settings_toast_mobile 3s 1 ease;
    }

    @keyframes settings_toast {
        0%{
            transform: translate(-50%, 0%);
        }
        8%{
            transform: translate(-50%, -100px);
        }
        90%{
            transform: translate(-50%, -100px);
            opacity: 1;
        }
        100%{
            transform: translate(-50%, 0%);
            opacity: 0;
        }
    }

    @keyframes settings_toast_mobile {
        0%{
            transform: translate(-50%, 0%);
        }
        8%{
            transform: translate(-50%, -150px);
        }
        90%{
            transform: translate(-50%, -150px);
            opacity: 1;
        }
        100%{
            transform: translate(-50%, 0%);
            opacity: 0;
        }
    }
`

const TextDiv = styled.div`
    font-size: ${props => props.size ? props.size : '16px'};
    color: ${props => props.color ? props.color : '#222222'};
    font-weight: ${props => props.weight ? props.weight : 400};
    line-height: ${props => props.lineHeight ? props.lineHeight : '130%'};
    
    opacity: ${props => props.opacity ? props.opacity : '1'};

    @media (max-width: 590px){
        font-size: ${props => props.sizeMobile ? props.sizeMobile : props.size ? props.size : '16px'};
        color: ${props => props.colorMobile ? props.colorMobile : props.color ? props.color : '#222222'};
        font-weight: ${props => props.weightMobile ? props.weightMobile : props.weight ? props.weight : 400};
        line-height: ${props => props.lineHeightMobile ? props.lineHeightMobile : props.lineHeight ? props.lineHeight : '130%'};
        
        opacity: ${props => props.opacityMobile ? props.opacityMobile : props.opacity ? props.opacity : '1'};
    }
`