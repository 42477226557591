//API
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENT } from "constants/amplitude";

export const LinkKauth = async function (code, type, navigate) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/v1/kakao/signup?code=${code}&type=${type}`
    );

    window?.fbq?.("trackCustom", "CompleteRegistration");
    await amplitude.track(AMPLITUDE_EVENT.COMPLETE_SIGNUP).promise;

    if (response.data.pageState === "facebookLogin") {
      // pageState가 'facebookLogin'인 경우 facebook 로그인으로 replace
      window.location.replace(
        `https://www.facebook.com/v10.0/dialog/oauth?scope=read_insights,pages_read_engagement,instagram_manage_insights,instagram_basic,business_management&response_type=code&auth_type=rerequest&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/register/fauth&state=${response.data.data.kid}_${response.data.data.type}`
      );
    } else if (response.data.pageState === "googleLogin") {
      // pageState가 'googleLogin'인 경우 google 로그인으로 replace
      const scope =
        "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly";
      window.location.replace(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&response_type=code&access_type=offline&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/register/gauth&state=${response.data.data.kid}_${response.data.data.type}`
      );
    } else if (
      response.data.pageState === "guide" ||
      response.data.pageState === "select" ||
      response.data.pageState === "reauth"
    ) {
      // pageState가 'guide', 'select', 'reauth' 인 경우 각 페이지로 이동
      navigate(
        `/app/register/${response.data.pageState}?kid=${response.data.data.kid}&type=${response.data.data.type}`,
        { state: response?.data?.data, replace: true }
      );
    }
  } catch (e) {
    //연동 에러 케이스는 fail 페이지로 이동
    navigate(`/app/register/fail?type=${e.response.data.type}`);
  }
};

export const LinkFauth = async function (code, state, navigate) {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_HOST
      }/api/v1/facebook/callback?code=${code}&kid=${state.split("_")[0]}&type=${
        state.split("_")[1]
      }`
    );

    window?.fbq?.("trackCustom", "SubmitApplication");
    await amplitude.track(AMPLITUDE_EVENT.COMPLETE_CONNECT, {
      channel: "Instagram",
    }).promise;

    navigate(
      `/app/register/${response.data.pageState}?kid=${response.data.data.kid}&type=${response.data.data.type}`,
      { state: response?.data?.data, replace: true }
    );
  } catch (e) {
    // 연동 에러 케이스는 fail 페이지로 navigate
    navigate(`/app/register/fail?type=${e.response.data.type}`);
  }
};

export const LinkReauth = async function (kid, type, navigate, pageState) {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_HOST
      }/api/v1/facebook/oauth?kid=${kid}&type=${type}&pageState=${
        pageState ?? "reauth"
      }`
    );

    if (response.data.pageState === "facebookLogin") {
      // pageState가 'facebookLogin'인 경우 facebook 로그인으로 replace
      window.location.replace(
        `https://www.facebook.com/v10.0/dialog/oauth?scope=read_insights,pages_read_engagement,instagram_manage_insights,instagram_basic,business_management&response_type=code&auth_type=rerequest&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}/app/register/fauth&state=${response.data.data.kid}_${response.data.data.type}`
      );
    } else if (
      response.data.pageState === "guide" ||
      response.data.pageState === "select" ||
      response.data.pageState === "reauth"
    ) {
      // pageState가 'guide', 'select', 'reauth' 인 경우 각 페이지로 이동
      navigate(
        `/app/register/${response.data.pageState}?kid=${response.data.data.kid}&type=${response.data.data.type}`,
        { state: response?.data?.data, replace: true }
      );
    }
  } catch (e) {
    // 연동 에러 케이스는 fail 페이지로 navigate
    navigate(`/app/register/fail?type=${e.response.data.type}`);
  }
};

export const LinkGauth = async function (code, state, navigate) {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_HOST
      }/api/v1/google/callback?code=${code}&kid=${state.split("_")[0]}&type=${
        state.split("_")[1]
      }`
    );

    window?.fbq?.("trackCustom", "SubmitApplication");
    await amplitude.track(AMPLITUDE_EVENT.COMPLETE_CONNECT, {
      channel: "Youtube",
    }).promise;

    navigate(`/app/register/complete`, {
      state: { ...response.data, sns: "youtube" },
      replace: true,
    });
  } catch (e) {
    // 연동 에러 케이스는 fail 페이지로 navigate
    navigate(`/app/register/fail?type=${e.response.data.type}`);
  }
};

export const LinkAccountSellect = async function (
  kid,
  type,
  navigate,
  pageId,
  igUserId
) {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/v1/facebook/register-user`,
      data: {
        kid: kid,
        pageId: pageId,
        igUserId: igUserId,
        type: type,
      },
    });

    navigate("/app/register/complete", { state: response?.data });
  } catch (e) {
    // 연동 에러 케이스는 fail 페이지로 navigate
    navigate(`/app/register/fail?type=${e.response.data.type}`);
  }
};
